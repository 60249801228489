import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';

import ToggleButton from 'react-bootstrap/ToggleButton';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as fasFaStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as farFaStar } from '@fortawesome/free-regular-svg-icons';

import "./favoritebutton.scss";

export default ({ switchFavoriteStatus, isFavorite }) => {

  const favoriteButton = (
    <ToggleButton
      onClick={switchFavoriteStatus}
      className="buttonStyle"
    >
      {
        isFavorite ? (
          <FontAwesomeIcon icon={fasFaStar} className="star favorited" />
        ) : (
          <FontAwesomeIcon icon={farFaStar} className="star" />
        )
      }
      <label className="buttonLabel">
        <FormattedMessage
          id={isFavorite ? 'FavoriteButton.addedToFavorites' : 'FavoriteButton.addToFavorites'}
        />
      </label>
    </ToggleButton>
  );

  return favoriteButton;

};