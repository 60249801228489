import { string, arrayOf, bool, func, number } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { Avatar, InlineTextButton, UserDisplayName } from '../../components';
import { formatDate } from '../../util/dates';
import { ensureTransaction, ensureUser, ensureListing } from '../../util/data';
import { propTypes } from '../../util/types';
import * as log from '../../util/log';

import css from './MessageFeed.module.css';

//import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

//
const Message = props => {
  const { message, intl, isOwnMessage } = props;
  const todayString = intl.formatMessage({ id: 'ActivityFeed.today' });
  const displayName = isOwnMessage ? '' : message.sender.attributes.profile.displayName;

  return (
    <Grid container spacing={0} id={`msg-${message.id.uuid}`}>
      <Grid
        item
        container
        justifyContent="flex-end"
        pc={isOwnMessage ? 4 : 0}
        mobile={isOwnMessage ? 4 : 0}
      >
        {isOwnMessage ? null : (
          <Box
            pc={2}
            mobile={2}
            sx={{
              alignSelf: 'flex-end',
            }}
          ></Box>
        )}
      </Grid>

      <Grid item pc={8} mobile={8}>
        <Card
          sx={{
            ...(isOwnMessage && {
              backgroundColor: '#CCEEFF',
              borderRadius: '20px 20px 0px 20px',
            }),
            ...(!isOwnMessage && {
              backgroundColor: '#F1F1F1',
              borderRadius: '20px 20px 20px 0px',
            }),
            p: 2,
          }}
        >
          <CardContent sx={{ p: 0 }}>
            <Stack>
              <Typography variant="subtitle1">{displayName}</Typography>
              <Typography
                variant="body1"
                style={{ whiteSpace: 'pre-line' }}
                sx={{ wordWrap: 'break-word' }}
              >
                {message.attributes.content}
              </Typography>
              <Typography variant="caption">
                {formatDate(intl, todayString, message.attributes.createdAt)}
              </Typography>
            </Stack>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

Message.propTypes = {
  message: propTypes.message.isRequired,
  intl: intlShape.isRequired,
};

export const MessageFeedComponent = props => {
  const {
    rootClassName,
    className,
    messages,
    transaction,
    currentUser,
    hasOlderMessages,
    onShowOlderMessages,
    fetchMessagesInProgress,
    intl,
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  const currentTransaction = ensureTransaction(transaction);
  const currentCustomer = ensureUser(currentTransaction.customer);
  const currentProvider = ensureUser(currentTransaction.provider);
  const currentListing = ensureListing(currentTransaction.listing);

  const items = messages.sort((a, b) => a.attributes.createdAt - b.attributes.createdAt);

  const messageComponent = message => {
    const isOwnMessage =
      message.sender &&
      message.sender.id &&
      currentUser &&
      currentUser.id &&
      message.sender.id.uuid === currentUser.id.uuid;

    return (
      <Message key={message.id.uuid} isOwnMessage={isOwnMessage} message={message} intl={intl} />
    );
  };

  return <Stack spacing={1}>{items.map(messageComponent)}</Stack>;
};

MessageFeedComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

MessageFeedComponent.propTypes = {
  rootClassName: string,
  className: string,

  currentUser: propTypes.currentUser,
  transaction: propTypes.transaction,
  messages: arrayOf(propTypes.message),
  hasOlderMessages: bool.isRequired,
  onShowOlderMessages: func.isRequired,
  fetchMessagesInProgress: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const MessageFeed = injectIntl(MessageFeedComponent);

export default MessageFeed;
