import React, { useState, useEffect } from 'react';
import ImageUploading from 'react-images-uploading';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Grid } from '@mui/material';
import css from './MultipleImagesUploader.module.css';
import { toImgixThumbnail } from '../../util/imgix'

export function MultipleImagesUploader(props) {
  const { maxNumber, label, underLabel, images, setImages, savedImages, setSavedImages } = props;

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    setImages(imageList);
  };

  const onSavedImageRemove = index => {
    const newSavedImagesArray = [...savedImages];
    newSavedImagesArray.splice(index, 1);
    setSavedImages(newSavedImagesArray);
  };

  return (
    <div>
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          // write your building UI
          <div className={css.root}>
            <h3 className={css.sectionTitle}>
              {label}
              <AddIcon className={css.addIcon} onClick={onImageUpload} {...dragProps} />
            </h3>
            <p className={css.uploadPhotoUnderlabel}>{underLabel}</p>

            <Grid container item mobile={12}>
              {imageList.map((image, index) => (
                <Grid key={image['data_url']} container item mobile={12} pc={4}>
                  <div className={css.imageItem}>
                    <img src={image['data_url']} alt="" className={css.image} />
                    <div className={css.imageButtonsWrapper}>
                      <EditIcon className={css.editButton} onClick={() => onImageUpdate(index)} />
                      <DeleteIcon
                        className={css.removeButton}
                        onClick={() => onImageRemove(index)}
                      />
                    </div>
                  </div>
                </Grid>
              ))}

              {savedImages.map(image => {
                const index = savedImages.indexOf(image);
                return (
                  <Grid key={image} container item mobile={12} pc={4}>
                    <div className={css.imageItem}>
                      <img loading="lazy" src={toImgixThumbnail(image)} alt="" className={css.image} />
                      <div className={css.imageButtonsWrapper}>
                        <DeleteIcon
                          className={css.removeButton}
                          onClick={() => onSavedImageRemove(index)}
                        />
                      </div>
                    </div>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        )}
      </ImageUploading>
    </div>
  );
}

