import React from 'react';
import { Row, Col } from 'react-bootstrap';
import "./NavigationBar.scss"

const NavigationBar = ({ title }) => (
    <nav className="secondbar">
        <Row className="inner d-flex flex-row">
            <Col lg="auto" md="auto" sm="auto" xs="auto">
                <p className="page-title align-items-center justify-content-start">
                    {title}
                </p>
            </Col>
        </Row>
    </nav>
);

export default NavigationBar;
