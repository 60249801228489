import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';

import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink } from '../../components';

const SignUpButtonComponent = props => {
  return (
    <NamedLink name="SubscriptionsPage">
      <Button variant="primary">
        <FormattedMessage id="KammuiPlusLandingPage.menuSubmit" />
      </Button>
    </NamedLink>
  );
};

export const SignUpButton = compose(withRouter)(SignUpButtonComponent);

export const MenuComponent = props => {
  const { currentUser, privatePage, location } = props;
  const search = location.search;
  return (
    <nav className="nav-kammuiplus-lp-wrapper">
      <Row className="inner d-flex flex-row nav-kammuiplus-menu">
        <Col lg="auto" md="auto" sm="auto" xs="auto">
          <p className="nav navbar-menu-title align-items-center justify-content-start">
            <NamedLink name="KammuiPlusLandingPage" to={{ search: search }}>
              Kammui+
            </NamedLink>
          </p>
        </Col>
        <Col>
          <ul className="nav nav-pc justify-content-end align-items-center">
            <li className="nav-item">
              <NamedLink className="nav-link" name="KammuiPlusLandingPage" to={{ search: search }}>
                <FormattedMessage id="KammuiPlusLandingPage.menuOverview" />
              </NamedLink>
            </li>
            <li className="nav-item">
              <NamedLink
                className="nav-link"
                name="KammuiPlusPropertyAccessPage"
                to={{ search: search }}
              >
                <FormattedMessage id="KammuiPlusLandingPage.menuPropertyAccess" />
              </NamedLink>
            </li>
            <li className="nav-item">
              <NamedLink
                className="nav-link"
                name="KammuiPlusBrandDiscountsPage"
                to={{ search: search }}
              >
                <FormattedMessage id="KammuiPlusLandingPage.menuBrandDiscounts" />
              </NamedLink>
            </li>
          </ul>
        </Col>
        <Col lg="auto" md="auto" sm="auto" xs="auto" className="flex-row-reverse nav-mobile">
          <ul className="nav justify-content-end align-items-center">
            <li className="nav-item">
              <input type="checkbox" className="local-menu-btn" id="local-menu-btn" />
              <label htmlFor="local-menu-btn" className="local-menu-icon">
                <i className="fas fa-chevron-down"></i>
              </label>
              <div className="local-menu">
                <div className="local-menu-content">
                  <ul>
                    <li>
                      <NamedLink
                        className="nav-link"
                        name="KammuiPlusLandingPage"
                        to={{ search: search }}
                      >
                        <FormattedMessage id="KammuiPlusLandingPage.menuOverview" />
                      </NamedLink>
                    </li>
                    <li>
                      <NamedLink
                        className="nav-link"
                        name="KammuiPlusPropertyAccessPage"
                        to={{ search: search }}
                      >
                        <FormattedMessage id="KammuiPlusLandingPage.menuPropertyAccess" />
                      </NamedLink>
                    </li>
                    <li>
                      <NamedLink
                        className="nav-link"
                        name="KammuiPlusBrandDiscountsPage"
                        to={{ search: search }}
                      >
                        <FormattedMessage id="KammuiPlusLandingPage.menuBrandDiscounts" />
                      </NamedLink>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </Col>
      </Row>
    </nav>
  );
};

export const Menu = compose(withRouter)(MenuComponent);
