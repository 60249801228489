//customization of the MUI Material theme for Kammui


export const CustomMUITheme = {
  breakpoints: {
    values: {
      mobile: 0,
      pc: 1024,
      sm: 512
    },
  },

  components: {
    //Global Kammui Chip style
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: "4px",
        }
      }
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '12px',
          height: '15px',
          margin: "30px 0px 3px 0px",

          fontWeight: "400",
          fontSize: "12px",
          lineHeight: "15px"
        },
        shrink: {
          transform: true,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: "#eeeeee",
        },
        input: {

          maxWidth: {
            pc: "326px",
            mobile: "291px",
          },
          maxHeight: {
            pc: "90.5px",
            mobile: "72.5px",

          }

        }
      },
      defaultProps: {
        sx: {
          maxWidth: {
            pc: "340px",
            mobile: "305px",
          },
          maxHeight: {
            pc: "99px",
            mobile: "81px",
          }
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "#505050",
          fontFamily: [
            'Inter',
            "YakuHanJP",
            "Yu Gothic Medium",
            "游ゴシック Medium",
            "YuGothic",
            "游ゴシック体",
            "sans-serif",
          ].join(','),
        },
        h5: {
          fontSize: { pc: "20px", mobile: "15px" },
          fontWeight: "600",
          lineHeight: { pc: "36px", mobile: "24px" }
        },
        subtitle1: {
          fontSize: "16px",
          fontWeight: "500",
          lineHeight: "24px",
          textTransform: "none",
        },
        body1: {
          fontSize: "14px",
          fontWeight: "400",
          lineHeight: "20px",
        },
        caption: {
          fontSize: "14px",
          fontWeight: "400",
          lineHeight: "20px",
          color: "#999999"
        },
        caption2: {
          fontSize: "14px",
          fontWeight: "500",
          lineHeight: "20px",
          color: "#999999"
        },
        terms: {
          color: '#ffffff',
          fontSize: '10px',
          fontWeight: '400',
          lineHeight: "1px",
        },
        button: {
          color: '#ffffff',
          fontSize: '16px',
          fontWeight: '700',
        },
        links: {
          color: '#ffffff',
          fontSize: '16px',
          fontWeight: '600',
          textDecorationLine: 'underline',
        }

      }

    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 0,
          '&:last-child': {
            paddingBottom: 0,
          },
        },
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 4,
        },
      }
    },
    MuiLoadingButton: {
      styleOverrides: {
        root: {
          backgroundColor: "#5B90CE",
          border: "0px",
          borderRadius: 4,
          width: "100%",
          minHeight: "40px",
        },
      }
    }
  },

  shadows: ["none", "none"],

  palette: {
    submitButton: {
      main: '#5b90ce',
      dark: '#5283bd',
      contrastText: '#ffffff',
    },
    normalButton: {
      main: '#EAE7E4',
      dark: "#DDDDDD",
      contrastText: '#505050',
    }

  },

}
