/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */
import { FormattedMessage } from './util/reactIntl';
import { faPersonHiking } from '@fortawesome/free-solid-svg-icons'
import {
  faPersonBikingMountain,
  faMountain,
  faWater,
  faMaskSnorkel,
  faWaterArrowUp,
  faFishingRod,
  faCircleHeart,
  faTomato,
  faSpa,
  faPersonSkiing,
  faLeafHeart,
  faDroplet,
  faFamily,
  faGrillHot,
  faLeaf,
  faMoon,
  faMoonStars,
  faTents,
  faUserHelmetSafety
} from '@fortawesome/pro-solid-svg-icons'






export const filters = [
  {
    id: 'dates',
    label: 'Dates',
    type: 'BookingDateRangeFilter',
    group: 'primary',
    // Note: BookingDateRangeFilter is fixed filter,
    // you can't change "queryParamNames: ['dates'],"
    queryParamNames: ['dates'],
    config: {},
  },
  {
    id: 'price',
    label: 'Price',
    type: 'PriceFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 1000,
      step: 5,
    },
  },
  {
    id: 'keyword',
    label: 'Keyword',
    type: 'KeywordFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },
  // {
  //   id: 'category',
  //   label: 'Category',
  //   type: 'SelectSingleFilter',
  //   group: 'secondary',
  //   queryParamNames: ['pub_category'],
  //   config: {
  //     // "key" is the option you see in Flex Console.
  //     // "label" is set here for the UI only.
  //     // Note: label is not added through the translation files
  //     // to make filter customizations a bit easier.
  //     options: [
  //       { key: 'smoke', label: 'Smoke' },
  //       { key: 'electric', label: 'Electric' },
  //       { key: 'wood', label: 'Wood' },
  //       { key: 'other', label: 'Other' },
  //     ],
  //   },
  // },
  {
    id: 'experienceLength',
    label: 'Length',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_experienceLength'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: '1hour', id: 'ExperienceLength.1Hour' },
        { key: '2hours', id: 'ExperienceLength.2Hours' },
        { key: '3hours', id: 'ExperienceLength.3Hours' },
        { key: '4hours', id: 'ExperienceLength.4Hours' },
        { key: '5hours', id: 'ExperienceLength.5Hours' },
        { key: '6hours', id: 'ExperienceLength.6Hours' },
        { key: '7hours', id: 'ExperienceLength.7Hours' },
        { key: '8hours', id: 'ExperienceLength.8Hours' },
        { key: '8+hours', id: 'ExperienceLength.8PlusHours' },
        { key: '2days/1night', id: 'ExperienceLength.2days1night' },
        { key: '3days/2nights', id: 'ExperienceLength.3days2nights' },
        { key: '4days/3nights', id: 'ExperienceLength.4days3nights' },
        { key: '5days/4nights', id: 'ExperienceLength.5days4nights' },
        { key: '6days/5nights', id: 'ExperienceLength.6days5nights' },
      ],
    },
  },

  {
    id: 'monthsOptions',
    label: 'Experience Length',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_monthsOptions'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'january', id: 'EditListingFeaturesForm.january' },
        { key: 'february', id: 'EditListingFeaturesForm.february' },
        { key: 'march', id: 'EditListingFeaturesForm.march' },
        { key: 'april', id: 'EditListingFeaturesForm.april' },
        { key: 'may', id: 'EditListingFeaturesForm.may' },
        { key: 'june', id: 'EditListingFeaturesForm.june' },
        { key: 'july', id: 'EditListingFeaturesForm.july' },
        { key: 'august', id: 'EditListingFeaturesForm.august' },
        { key: 'september', id: 'EditListingFeaturesForm.september' },
        { key: 'october', id: 'EditListingFeaturesForm.october' },
        { key: 'november', id: 'EditListingFeaturesForm.november' },
        { key: 'december', id: 'EditListingFeaturesForm.december' },
      ],
    },
  },
  {
    id: 'experienceType',
    label: 'Type',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_experienceType'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'public', id: 'ExperienceType.public' },
        { key: 'private', id: 'ExperienceType.private' },
      ],
    },
  },
  {
    id: 'experiencePickup',
    label: 'Type',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_experiencePickup'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'yes', id: 'ExperiencePickup.yes' },
        { key: 'no', id: 'ExperiencePickup.no' },
      ],
    },
  },
  {
    id: 'styles',
    label: 'Styles',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_styles'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'mindfulness',
          label: <FormattedMessage id="Styles.mindfulness" />,
          icon: faLeafHeart
        },
        {
          key: 'challenging',
          label: <FormattedMessage id="Styles.challenging" />,
          icon: faDroplet
        },
        {
          key: 'familyFriendly',
          label: <FormattedMessage id="Styles.familyFriendly" />,
          icon: faFamily
        },
        {
          key: 'localFood',
          label: <FormattedMessage id="Styles.localFood" />,
          icon: faGrillHot
        },
        {
          key: 'plantsWildlife',
          label: <FormattedMessage id="Styles.plantsWildlife" />,
          icon: faLeaf
        },

      ],
    },
  },
  {
    id: 'activities',
    label: 'Activities',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_activities'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'escapes',
          label: <FormattedMessage id="Activities.escapes" />,
          icon: faMoonStars
        },
        {
          key: 'skiSnowboarding',
          label: <FormattedMessage id="Activities.skiSnowboarding" />,
          icon: faPersonSkiing
        },
        {
          key: 'trekking',
          label: <FormattedMessage id="Activities.trekking" />,
          icon: faPersonHiking
        },

        {
          key: 'camping',
          label: <FormattedMessage id="Activities.camping" />,
          icon: faTents
        },
        {
          key: 'mountainBiking',
          label: <FormattedMessage id="Activities.mountainBiking" />,
          icon: faPersonBikingMountain
        },
        {
          key: 'mountaineering',
          label: <FormattedMessage id="Activities.mountaineering" />,
          icon: faMountain
        },
        {
          key: 'canyoning',
          label: <FormattedMessage id="Activities.canyoning" />,
          icon: faUserHelmetSafety
        },
        {
          key: 'kayaking',
          label: <FormattedMessage id="Activities.kayaking" />,
          icon: faWater
        },
        {
          key: 'surfingDiving',
          label: <FormattedMessage id="Activities.surfingDiving" />,
          icon: faMaskSnorkel
        },
        {
          key: 'sup',
          label: <FormattedMessage id="Activities.sup" />,
          icon: faWaterArrowUp
        },
        {
          key: 'fishing',
          label: <FormattedMessage id="Activities.fishing" />,
          icon: faFishingRod
        },
        {
          key: 'yogaMeditation',
          label: <FormattedMessage id="Activities.yogaMeditation" />,
          icon: faCircleHeart
        },
        {
          key: 'farmExperience',
          label: <FormattedMessage id="Activities.farmExperience" />,
          icon: faTomato
        }
      ],
    },
  },
  {
    id: 'prefectures',
    label: 'Prefectures',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_prefectures'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'Hokkaido',
          id: 'Prefectures.hokkaido',
          label: <FormattedMessage id='Prefectures.hokkaido' />,
        },
        {
          key: 'Aomori',
          id: 'Prefectures.aomori',
          label: <FormattedMessage id='Prefectures.aomori' />,
        },
        {
          key: 'Iwate',
          id: 'Prefectures.iwate',
          label: <FormattedMessage id='Prefectures.iwate' />,
        },
        {
          key: 'Miyagi',
          id: 'Prefectures.miyagi',
          label: <FormattedMessage id='Prefectures.miyagi' />,
        },
        {
          key: 'Akita',
          id: 'Prefectures.akita',
          label: <FormattedMessage id='Prefectures.akita' />,
        },
        {
          key: 'Yamagata',
          id: 'Prefectures.yamagata',
          label: <FormattedMessage id='Prefectures.yamagata' />,
        },
        {
          key: 'Fukushima',
          id: 'Prefectures.fukushima',
          label: <FormattedMessage id='Prefectures.fukushima' />,
        },
        {
          key: 'Ibaraki',
          id: 'Prefectures.ibaraki',
          label: <FormattedMessage id='Prefectures.ibaraki' />,
        },
        {
          key: 'Tochigi',
          id: 'Prefectures.tochigi',
          label: <FormattedMessage id='Prefectures.tochigi' />,
        },
        {
          key: 'Gunma',
          id: 'Prefectures.gunma',
          label: <FormattedMessage id='Prefectures.gunma' />,
        },
        {
          key: 'Saitama',
          id: 'Prefectures.saitama',
          label: <FormattedMessage id='Prefectures.saitama' />,
        },
        {
          key: 'Chiba',
          id: 'Prefectures.chiba',
          label: <FormattedMessage id='Prefectures.chiba' />,
        },
        {
          key: 'Tokyo',
          id: 'Prefectures.tokyo',
          label: <FormattedMessage id='Prefectures.tokyo' />,
        },
        {
          key: 'Kanagawa',
          id: 'Prefectures.kanagawa',
          label: <FormattedMessage id='Prefectures.kanagawa' />,
        },
        {
          key: 'Niigata',
          id: 'Prefectures.niigata',
          label: <FormattedMessage id='Prefectures.niigata' />,
        },
        {
          key: 'Toyama',
          id: 'Prefectures.toyama',
          label: <FormattedMessage id='Prefectures.toyama' />,
        },
        {
          key: 'Ishikawa',
          id: 'Prefectures.ishikawa',
          label: <FormattedMessage id='Prefectures.ishikawa' />,
        },
        {
          key: 'Fukui',
          id: 'Prefectures.fukui',
          label: <FormattedMessage id='Prefectures.fukui' />,
        },
        {
          key: 'Yamanashi',
          id: 'Prefectures.yamanashi',
          label: <FormattedMessage id='Prefectures.yamanashi' />,
        },
        {
          key: 'Nagano',
          id: 'Prefectures.nagano',
          label: <FormattedMessage id='Prefectures.nagano' />,
        },
        {
          key: 'Gifu',
          id: 'Prefectures.gifu',
          label: <FormattedMessage id='Prefectures.gifu' />,
        },
        {
          key: 'Shizuoka',
          id: 'Prefectures.shizuoka',
          label: <FormattedMessage id='Prefectures.shizuoka' />,
        },
        {
          key: 'Aichi',
          id: 'Prefectures.aichi',
          label: <FormattedMessage id='Prefectures.aichi' />,
        },
        {
          key: 'Mie',
          id: 'Prefectures.mie',
          label: <FormattedMessage id='Prefectures.mie' />,
        },
        {
          key: 'Shiga',
          id: 'Prefectures.shiga',
          label: <FormattedMessage id='Prefectures.shiga' />,
        },
        {
          key: 'Kyoto',
          id: 'Prefectures.kyoto',
          label: <FormattedMessage id='Prefectures.kyoto' />,
        },
        {
          key: 'Osaka',
          id: 'Prefectures.osaka',
          label: <FormattedMessage id='Prefectures.osaka' />,
        },
        {
          key: 'Hyogo',
          id: 'Prefectures.hyogo',
          label: <FormattedMessage id='Prefectures.hyogo' />,
        },
        {
          key: 'Nara',
          id: 'Prefectures.nara',
          label: <FormattedMessage id='Prefectures.nara' />,
        },
        {
          key: 'Wakayama',
          id: 'Prefectures.wakayama',
          label: <FormattedMessage id='Prefectures.wakayama' />,
        },
        {
          key: 'Tottori',
          id: 'Prefectures.tottori',
          label: <FormattedMessage id='Prefectures.tottori' />,
        },
        {
          key: 'Shimane',
          id: 'Prefectures.shimane',
          label: <FormattedMessage id='Prefectures.shimane' />,
        },
        {
          key: 'Okayama',
          id: 'Prefectures.okayama',
          label: <FormattedMessage id='Prefectures.okayama' />,
        },
        {
          key: 'Hiroshima',
          id: 'Prefectures.hiroshima',
          label: <FormattedMessage id='Prefectures.hiroshima' />,
        },
        {
          key: 'Yamaguchi',
          id: 'Prefectures.yamaguchi',
          label: <FormattedMessage id='Prefectures.yamaguchi' />,
        },
        {
          key: 'Tokushima',
          id: 'Prefectures.tokushima',
          label: <FormattedMessage id='Prefectures.tokushima' />,
        },
        {
          key: 'Kagawa',
          id: 'Prefectures.kagawa',
          label: <FormattedMessage id='Prefectures.kagawa' />,
        },
        {
          key: 'Ehime',
          id: 'Prefectures.ehime',
          label: <FormattedMessage id='Prefectures.ehime' />,
        },
        {
          key: 'Kouchi',
          id: 'Prefectures.kouchi',
          label: <FormattedMessage id='Prefectures.kouchi' />,
        },
        {
          key: 'Fukuoka',
          id: 'Prefectures.fukuoka',
          label: <FormattedMessage id='Prefectures.fukuoka' />,
        },
        {
          key: 'Saga',
          id: 'Prefectures.saga',
          label: <FormattedMessage id='Prefectures.saga' />,
        },
        {
          key: 'Nagasaki',
          id: 'Prefectures.nagasaki',
          label: <FormattedMessage id='Prefectures.nagasaki' />,
        },
        {
          key: 'Kumamoto',
          id: 'Prefectures.kumamoto',
          label: <FormattedMessage id='Prefectures.kumamoto' />,
        },
        {
          key: 'Oita',
          id: 'Prefectures.oita',
          label: <FormattedMessage id='Prefectures.oita' />,
        },
        {
          key: 'Miyazaki',
          id: 'Prefectures.miyazaki',
          label: <FormattedMessage id='Prefectures.miyazaki' />,
        },
        {
          key: 'Kagoshima',
          id: 'Prefectures.kagoshima',
          label: <FormattedMessage id='Prefectures.kagoshima' />,
        },
        {
          key: 'Okinawa',
          id: 'Prefectures.okinawa',
          label: <FormattedMessage id='Prefectures.okinawa' />,
        },
      ],
    },
  },

];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceWakayamaey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    { key: '-price', label: 'LowestPrice' },
    { key: 'price', label: 'HighestPrice' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};

export const inquireURL = {
  kammuiPlusEN : 'https://9x5119d2yio.typeform.com/to/LTCGLlo1',
  kammuiPlusJA : 'https://9x5119d2yio.typeform.com/to/IAyzEXjy',
  publicEN : 'https://9x5119d2yio.typeform.com/to/hjNVIW44',
  publicJA : 'https://9x5119d2yio.typeform.com/to/GXTWvWex',
  aboutKammuiEN : 'https://about.kammui.com/en/',
  aboutKammuiJA : 'https://about.kammui.com/',
  adventures : source => `https://9x5119d2yio.typeform.com/to/BINcD1mL?utm_source=${source}`,
  adventuresWinter : source => `https://9x5119d2yio.typeform.com/to/YYqUe2cd?utm_source=${source}`,
}
