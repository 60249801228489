import pick from 'lodash/pick';
import merge from 'lodash/merge';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';
import { setLanguageDependantFields } from '../../util/data';
import { denormalisedResponseEntities } from '../../util/data';
const { UUID } = sdkTypes;
// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/LandingPage/SET_INITIAL_VALUES';
export const FETCH_DATA_REQUEST = 'app/LandingPage/FETCH_DATA_REQUEST';
export const FETCH_DATA_SUCCESS = 'app/LandingPage/FETCH_DATA_SUCCESS';
export const FETCH_DATA_ERROR = 'app/LandingPage/FETCH_DATA_ERROR';

// ================ Reducer ================ //

const initialState = {
  data: null,
  fetchDataError: null,
};

const LandingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case FETCH_DATA_REQUEST:
      return { ...state, fetchDataError: null };
    case FETCH_DATA_SUCCESS:
      return {
        ...state,
        data: payload,
      };
    case FETCH_DATA_ERROR:
      return { ...state, fetchDataError: payload };

    default:
      return state;
  }
};

export default LandingPageReducer;

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const fetchDataRequest = () => ({ type: FETCH_DATA_REQUEST });
export const fetchDataSuccess = response => {
  return {
    type: FETCH_DATA_SUCCESS,
    payload: response,
  };
};
export const fetchDataError = error => ({
  type: FETCH_DATA_ERROR,
  error: true,
  payload: error,
});

// ================ Thunks ================ //
const featuredExperiences = config.landingPageConfig ? JSON.parse(config.landingPageConfig) : [];
const isBrowser = typeof window !== 'undefined';

export const loadData = params => (dispatch, getState, sdk) => {
  const validLocales = ['en', 'ja'];
  // Determine the language from params, or browser settings, or default to 'en'.
  const determineLanguage = () => {
    if (validLocales.includes(params?.locale)) {
      return params.locale;
    }
    if (isBrowser) {
      // Use browser's preferred language.
      const browserLang = navigator.language.split('-')[0]; // e.g., 'en-US' becomes 'en'
      if (validLocales.includes(browserLang)) {
        return browserLang;
      }
    }
    // Default to 'en'
    return 'en';
  };
  const language = determineLanguage();

  if (sdk?.listings && featuredExperiences?.length > 0) {
    const promise = sdk.listings
      .query({
        ids: featuredExperiences,
        include: ['images'],
        'fields.listing': ['title', 'geolocation', 'price', 'publicData', 'description'],
        'fields.image': [`variants.landscape-crop`, `variants.landscape-crop2x`],
        'limit.images': 1,
      })
      .then(res => {
        const listings = denormalisedResponseEntities(res).map(l =>
          setLanguageDependantFields({ type: 'listing', ...l }, language)
        );
        return listings;
      });

    return promise
      .then(res => {
        dispatch(fetchDataSuccess(res));
      })
      .catch(e => {
        console.log(e);
        dispatch(fetchDataError(e));
      });
  } else {
    return new Promise(() => []);
  }
};
