import React, { useState } from 'react';
import {
    Box,
    Typography,
} from '@mui/material';
import { NamedLink } from "../../components/index";
import { FormattedMessage } from '../../util/reactIntl';
import { isTooManyEmailVerificationRequestsError } from "../../util/errors";
import { sendVerificationEmailError } from "../../ducks/user.duck"
import { connect } from 'react-redux';
import {
    bool,
    func
} from 'prop-types';
import { compose } from 'redux';
import { sendVerificationEmail } from "../../ducks/user.duck"

export const EmailConfirmationContentsComponent = (props) => {
    const {
        onResendVerificationEmail,
        sendVerificationEmailInProgress,
        user,
    } = props;
    const email = user.id ? <Typography variant='nameOrEmail' sx={{ mb: 5 }} lineHeight="20px">{user.attributes.email} </Typography> : '';
    const tooManyVerificationRequests = isTooManyEmailVerificationRequestsError(
        sendVerificationEmailError
    );
    const [showVerificationEmailSentMessage, setShowVerificationEmailSentMessage] = useState(false);
    let emailSentTimeoutId = null;
    const handleResendVerificationEmail = () => {

        setShowVerificationEmailSentMessage(true)

        onResendVerificationEmail().then(() => {
            emailSentTimeoutId = window.setTimeout(() => {
                setShowVerificationEmailSentMessage(false)
            }, 2000);
        });
    }
    let resendEmailLink = null;
    if (tooManyVerificationRequests) {
        resendEmailLink = (
            <Typography
                component="span"
                fontSize="10px"
            >
                <FormattedMessage id="ContactDetailsForm.tooManyVerificationRequests" />
            </Typography>
        );
    } else if (
        sendVerificationEmailInProgress ||
        showVerificationEmailSentMessage
    ) {
        resendEmailLink = (
            <Typography
                component="span"
                fontSize="10px"
            >
                <FormattedMessage id="ContactDetailsForm.emailSent" />
            </Typography>
        );
    } else {
        resendEmailLink = (
            <Typography
                sx={{
                    textDecoration: 'underline',
                    fontSize: "10px"
                }}
                component="span"
                onClick={handleResendVerificationEmail}
                role="button"
            >
                <FormattedMessage id="AuthenticationPage.resendEmailLinkText" />
            </Typography>
        );
    }
    const fixEmailLink = (
        <NamedLink name="ContactDetailsPage">
            <Typography
                component="span"
                fontSize="10px"

                sx={{ textDecoration: 'underline' }}>
                <FormattedMessage id="AuthenticationPage.fixEmailLinkText" />
            </Typography>
        </NamedLink>
    )
    return (
        <>
            <Typography component="span" mt={2} mb={4} display="block" variant="caption2" color="#505050" >
                <FormattedMessage id="AuthenticationPage.verifyEmailText" values={{ email }} />
            </Typography>
            <Box mt={0} sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                    component="span"
                    fontSize="10px"
                >
                    <FormattedMessage id="AuthenticationPage.resendEmail" values={{ resendEmailLink }} />
                </Typography>
                <Typography
                    component="span"
                    fontSize="10px"
                >
                    <FormattedMessage id="AuthenticationPage.fixEmail" values={{ fixEmailLink }} />
                </Typography>
            </Box>
        </>
    )
}
EmailConfirmationContentsComponent.propsTypes = {
    sendVerificationEmailInProgress: bool.isRequired,
    onResendVerificationEmail: func.isRequired,
}
const mapStateToProps = state => {
    const { sendVerificationEmailInProgress } = state.user;
    return { sendVerificationEmailInProgress }
}
const mapDispatchToProps = dispatch => ({
    onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
});
const EmailConfirmationContents = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(EmailConfirmationContentsComponent)

export default EmailConfirmationContents