import React from 'react';
import { string, func } from 'prop-types';
import { intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import { NamedLink } from '../../components';
import { findOptionsForSelectFilter } from '../../util/search';

import './listingcardsimple.scss'
import Col from 'react-bootstrap/Col';

const MIN_LENGTH_FOR_LONG_WORDS = 10;


export const ListingCardSimpleComponent = props => {
  const {
    className,
    listing,
  } = props;
  const isEnglishSelected = config.locale === 'en';
  const classes = classNames('listingCardSimpleRoot', className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const experienceTitle = isEnglishSelected
    ? currentListing.attributes.title
    : currentListing.attributes.publicData.titleJp ?? currentListing.attributes.title;

  const description = isEnglishSelected
    ? currentListing?.attributes.description
    : currentListing?.attributes.publicData.descriptionJp ?? currentListing?.attributes.description;

  const slug = createSlug(experienceTitle);

  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const listingActivities = listing?.attributes.publicData.activities ?? [];
  const filterConfig = config.custom.filters;
  const activitiesOptions = findOptionsForSelectFilter('activities', filterConfig);
  const activityName = listingActivities
    ? activitiesOptions.find(a => a.key === listingActivities[0])?.label
    : null;
  const listingImage = firstImage?.attributes.variants['landscape-crop2x'].url;

  const descriptionShort =
    description && (description.length > 60 ? description.slice(0, 57) + '...' : description);

  return (
    <Col lg={4} md={6} sm={6} xs={12}>
      <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
        <div className="imageWrapper">
          <img src={listingImage} className="image" loading="lazy" />
        </div>
        <div className="mainInfo">
          <p className="activityName">{activityName}</p>
          <div className="experienceTitle">
            {richText(experienceTitle, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: "longWord",
            })}
          </div>
          <p className="experienceDescription">{descriptionShort}</p>
        </div>
      </NamedLink>
    </Col>
  );
};

ListingCardSimpleComponent.defaultProps = {
  className: null,
};

ListingCardSimpleComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(ListingCardSimpleComponent);
