import pick from 'lodash/pick';
import merge from 'lodash/merge';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { denormalisedResponseEntities, setLanguageDependantFields } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
import { baseQueryCard } from '../../util/listings';

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/StylesPage/SET_INITIAL_VALUES';
export const FETCH_DATA_REQUEST = 'app/StylesPage/FETCH_DATA_REQUEST';
export const FETCH_DATA_SUCCESS = 'app/StylesPage/FETCH_DATA_SUCCESS';
export const FETCH_DATA_ERROR = 'app/StylesPage/FETCH_DATA_ERROR';

// ================ Reducer ================ //

const initialState = {
  data: null,
  fetchDataError: null,
};

const StylesPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case FETCH_DATA_REQUEST:
      return { ...state, fetchDataError: null };
    case FETCH_DATA_SUCCESS:
      return {
        ...state,
        data: payload,
      };
    case FETCH_DATA_ERROR:
      return { ...state, fetchDataError: payload };
    default:
      return state;
  }
};

export default StylesPageReducer;

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const fetchDataRequest = () => ({ type: FETCH_DATA_REQUEST });
export const fetchDataSuccess = response => {
  return {
    type: FETCH_DATA_SUCCESS,
    payload: { data: response },
  };
};
export const fetchDataError = error => ({
  type: FETCH_DATA_ERROR,
  error: true,
  payload: error,
});

// ================ Thunks ================ //
const activitiesOptions = findOptionsForSelectFilter('styles', config.custom.filters);

export const loadData = params => (dispatch, getState, sdk) => {
  const language = params?.locale ? params.locale : 'ja';
  const languageFilter = language === 'ja' ? 'ja,jaen' : 'jaen';

  dispatch(fetchDataRequest());
  const promises = activitiesOptions.map(activity => {
    const key = activity.key;
    const retFunc = res => {
      res.data.data = res.data.data.map(l => setLanguageDependantFields(l, language));
      return { [key]: denormalisedResponseEntities(res) };
    };
    const listingBaseQuery = { ...baseQueryCard, perPage: 3, pub_styles: `has_any:${key}` };

    return sdk.listings.query({ ...listingBaseQuery, pub_language: languageFilter }).then(res => {
      if (res.data.data.length === 0 && languageFilter === 'jaen') {
        return sdk.listings.query(listingBaseQuery).then(retFunc);
      } else {
        return retFunc(res);
      }
    });
  });

  return Promise.all(promises)
    .then(res => {
      const experienceCategories = merge({}, ...res);
      dispatch(fetchDataSuccess(experienceCategories));
    })
    .catch(e => {
      console.log(e);
      dispatch(fetchDataError(e));
    });
};
