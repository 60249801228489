import React, { Component, useState, useEffect } from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { ListingCardSimple } from '../../components';
import css from './SectionListings.module.css';

function SectionListings(props) {
  const { searchParam, bottomLine, listings, hideHeader, hideSeeMore, carousel, titleClassName, } = props;
  const wrapperClass = carousel ? css.scrollableWrapper : css.overflowWrapper;
  const cardClass = carousel ? css.listingCardScrollable : css.listingCardOverflow;

  return listings?.length > 0 ? (
    <>
      {!hideHeader && (
        <h2 className={classNames(css.sectionTitle, css[titleClassName])}>
          <FormattedMessage id="ProfilePage.availableExperiences" />
        </h2>
      )}

      <div className={wrapperClass}>
        {listings.map(l => (
          <ListingCardSimple
            className={cardClass}
            key={l.id.uuid}
            listing={l}
            setActiveListing={() => console.log('')}
          />
        ))}
      </div>
      {bottomLine && <div className={css.separatingLine}></div>}
    </>
  ) : null;
}

export default SectionListings;
