import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import { ExternalLink, NamedLink } from '../../components';
import { pushToPath } from '../../util/urlHelpers';
import css from './Footer.module.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faYoutube, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { bool } from 'prop-types';

const Footer = props => {
  const { rootClassName, className, intl, showBanner, rawParams } = props;
  const classes = classNames(rootClassName || css.root, className);
  const isEnglish = config.locale === 'en';

  const handleLanguageSwitch = lang => {
    if (typeof window !== 'undefined') {
      const pathNameArray = window.location.pathname.split('/');
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set('langswitch', '');
      const languageParam = pathNameArray[1];
      const languageParamExists = languageParam === 'en' || languageParam === 'ja';

      if (languageParamExists) {
        pathNameArray[1] = lang;
        const newUrl = pathNameArray.join('/') + '?' + urlParams;
        return pushToPath(newUrl);
      } else {
        pathNameArray.splice(1, 0, lang);
        const newUrl = pathNameArray.join('/') + '?' + urlParams;
        return pushToPath(newUrl);
      }

    }
  };
  return (
    <div className={classes}>
      {showBanner ? (
        <div className={css.banner}>
          <div className={css.bannerWrapper}>
            <p className={css.bannerHeadline}>Access Nature.</p>
            <NamedLink name="SignupPage" className={css.bannerLink}>
              Join Kammui
            </NamedLink>
            <p className={css.bannerDescription}>
              <FormattedMessage id="Footer.bannerDescription" />
            </p>
          </div>
        </div>
      ) : null}
      <div className={css.topBorderWrapper}>
        <div className={css.content}>
          <div className={css.leftFooter}>
            <p className={css.footerCopyright}>&copy; {new Date().getFullYear()} Kammui</p>

            <NamedLink name="PrivacyPolicyPage" className={css.footerItem}>
              <FormattedMessage id="Footer.privacyPolicy" />
            </NamedLink>

            <NamedLink name="TermsOfServicePage" className={css.footerItem}>
              <FormattedMessage id="Footer.termsOfUse" />
            </NamedLink>

            <ExternalLink
              href={isEnglish ? 'https://about.kammui.com/en/' : 'https://about.kammui.com'}
              sameTab={true}
              className={css.footerItem}
            >
              <FormattedMessage id="Footer.toAboutPage" />
            </ExternalLink>
          </div>

          <div className={css.rightFooter}>
            <div>
              <ExternalLink href="https://instagram.com/kammui_japan" className={css.termsLink}>
                <FontAwesomeIcon icon={faInstagram} className={css.socialIcon}></FontAwesomeIcon>
              </ExternalLink>
              <ExternalLink
                href="https://www.youtube.com/channel/UCepNLsrBCtJwIpjAuc656RQ/"
                className={css.termsLink}
              >
                <FontAwesomeIcon icon={faYoutube} className={css.socialIcon}></FontAwesomeIcon>
              </ExternalLink>
              <ExternalLink href="https://twitter.com/kammui_japan" className={css.termsLink}>
                <FontAwesomeIcon icon={faTwitter} className={css.socialIcon}></FontAwesomeIcon>
              </ExternalLink>
            </div>

            <div className={css.languageSwitchButtonWrapper}>
              <div
                onClick={!isEnglish ? () => { } : () => handleLanguageSwitch('ja')}
                className={isEnglish ? css.lightText : css.strongText}
              >
                JP
              </div>
              /
              <div
                onClick={isEnglish ? () => { } : () => handleLanguageSwitch('en')}
                className={isEnglish ? css.strongText : css.lightText}
              >
                EN
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
  showBanner: false,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
  showBanner: bool,
};

export default injectIntl(Footer);