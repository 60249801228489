import React, { Component } from 'react';
import { array, arrayOf, bool, func, number, string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { Grid, Stack, Divider, Box, Container, Typography, Paper, Chip } from '@mui/material';

import {
  TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
  txIsAccepted,
  txIsCanceled,
  txIsDeclined,
  txIsEnquired,
  txIsPaymentExpired,
  txIsPaymentPending,
  txIsRequested,
  txHasBeenDelivered,
} from '../../util/transaction';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import {
  ensureListing,
  ensureTransaction,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { isMobileSafari } from '../../util/userAgent';
import { formatMoney } from '../../util/currency';
import {
  BookingPanel,
  NamedLink,
  UserDisplayName,
  SecondaryButton,
} from '../../components';
import { SendMessageForm } from '../../forms';
import config from '../../config';

// These are internal components that make this file more readable.
import AddressLinkMaybe from './AddressLinkMaybe';
import BreakdownMaybe from './BreakdownMaybe';
import DetailCardExperience from './DetailCardExperience';
import SaleActionButtonsMaybe from './SaleActionButtonsMaybe';
import PanelHeading, {
  HEADING_ENQUIRED,
  HEADING_PAYMENT_PENDING,
  HEADING_PAYMENT_EXPIRED,
  HEADING_REQUESTED,
  HEADING_ACCEPTED,
  HEADING_DECLINED,
  HEADING_CANCELED,
  HEADING_DELIVERED,
} from './PanelHeading';
import { ActivityFeed } from '../../components';
import { MessageFeed } from '../../components';


import { StatusChip } from '../../../src/containers/InboxPage/InboxPage';
import { formatDate } from '../../util/dates';


import css from './TransactionPanel.module.css';



// Helper function to get display names for different roles
const displayNames = (currentUser, currentProvider, currentCustomer, intl) => {
  const authorDisplayName = <UserDisplayName user={currentProvider} intl={intl} />;
  const customerDisplayName = <UserDisplayName user={currentCustomer} intl={intl} />;

  let otherUserDisplayName = '';
  let otherUserDisplayNameString = '';
  const currentUserIsCustomer =
    currentUser.id && currentCustomer.id && currentUser.id.uuid === currentCustomer.id.uuid;
  const currentUserIsProvider =
    currentUser.id && currentProvider.id && currentUser.id.uuid === currentProvider.id.uuid;

  if (currentUserIsCustomer) {
    otherUserDisplayName = authorDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentProvider, '');
  } else if (currentUserIsProvider) {
    otherUserDisplayName = customerDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentCustomer, '');
  }

  return {
    authorDisplayName,
    customerDisplayName,
    otherUserDisplayName,
    otherUserDisplayNameString,
  };
};

export class TransactionPanelComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendMessageFormFocused: false,
      messageValue: '',
    };
    this.isMobSaf = false;
    this.sendMessageFormName = 'TransactionPanel.SendMessageForm';

    this.onSendMessageFormFocus = this.onSendMessageFormFocus.bind(this);
    this.onSendMessageFormBlur = this.onSendMessageFormBlur.bind(this);
    this.onMessageSubmit = this.onMessageSubmit.bind(this);
    this.scrollToMessage = this.scrollToMessage.bind(this);
  }

  handleMessageChange = (e) => {
    this.setState({ messageValue: e.target.value });
}

  componentDidMount() {
    this.isMobSaf = isMobileSafari();
  }

  onSendMessageFormFocus() {
    this.setState({ sendMessageFormFocused: true });
    if (this.isMobSaf) {
      // Scroll to bottom
      window.scroll({ top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
    }
  }

  onSendMessageFormBlur() {
    this.setState({ sendMessageFormFocused: false });
  }

  onMessageSubmit(values, form) {
    console.log('onSubmit ', values);
    const message = values.message ? values.message.trim() : null;
    const { transaction, onSendMessage } = this.props;
    const ensuredTransaction = ensureTransaction(transaction);

    if (!message) {
      return;
    }
    onSendMessage(ensuredTransaction.id, message)
      .then(messageId => {
        form.reset();
        this.scrollToMessage(messageId);
      })
      .catch(e => {
        // Ignore, Redux handles the error
      });
  }

  scrollToMessage(messageId) {
    const selector = `#msg-${messageId.uuid}`;
    const el = document.querySelector(selector);
    if (el) {
      el.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }
  }

  render() {
    const {
      rootClassName,
      className,
      currentUser,
      transaction,
      totalMessagePages,
      oldestMessagePageFetched,
      messages,
      initialMessageFailed,
      savePaymentMethodFailed,
      fetchMessagesInProgress,
      fetchMessagesError,
      sendMessageInProgress,
      sendMessageError,
      onManageDisableScrolling,
      onShowMoreMessages,
      transactionRole,
      intl,
      onAcceptSale,
      onDeclineSale,
      acceptInProgress,
      declineInProgress,
      acceptSaleError,
      declineSaleError,
      onSubmitBookingRequest,
      timeSlots,
      fetchTimeSlotsError,
      nextTransitions,
      onFetchTransactionLineItems,
      lineItems,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      onCancelSale,
      onCancelSaleWithoutRefund,
    } = this.props;


    const currentTransaction = ensureTransaction(transaction);
    const currentListing = ensureListing(currentTransaction.listing);
    const currentProvider = ensureUser(currentTransaction.provider);
    const currentCustomer = ensureUser(currentTransaction.customer);
    const isCustomer = transactionRole === 'customer';
    const isProvider = transactionRole === 'provider';
    const customerName = currentCustomer.attributes.profile.displayName;
    const providerName = currentProvider.attributes.profile.displayName;
    const listingId = currentListing.id.uuid;



    const listingLoaded = !!currentListing.id;
    const listingDeleted = listingLoaded && currentListing.attributes.deleted;
    const iscustomerLoaded = !!currentCustomer.id;
    const isCustomerBanned = iscustomerLoaded && currentCustomer.attributes.banned;
    const isCustomerDeleted = iscustomerLoaded && currentCustomer.attributes.deleted;
    const isProviderLoaded = !!currentProvider.id;
    const isProviderBanned = isProviderLoaded && currentProvider.attributes.banned;
    const isProviderDeleted = isProviderLoaded && currentProvider.attributes.deleted;



    const stateDataFn = tx => {
      if (txIsEnquired(tx)) {
        const transitions = Array.isArray(nextTransitions)
          ? nextTransitions.map(transition => {
            return transition.attributes.name;
          })
          : [];
        const hasCorrectNextTransition =
          transitions.length > 0 && transitions.includes(TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY);
        return {
          headingState: HEADING_ENQUIRED,
          showBookingPanel: false, //isCustomer && !isProviderBanned && hasCorrectNextTransition,
          showDetailCardHeadings: true,
          sx: {
            height: '28px',

            '& .MuiChip-label': {
              padding: '6px',
              color: '#fff',
              fontWeight: '600',
              borderRadius: '6px',
              backgroundColor: '#FAB504'
            }
          },
          state: intl.formatMessage({
            id: 'InboxPage.stateEnquiry',
          }),
        };
      } else if (txIsPaymentPending(tx)) {
        return {
          headingState: HEADING_PAYMENT_PENDING,
          showDetailCardHeadings: isCustomer,
          sx: {
            height: '28px',
            '& .MuiChip-label': {
              padding: '6px',
              color: '#fff',
              fontWeight: '600',
              borderRadius: '6px',
              backgroundColor: '#FAB504'
            }
          },
          state: intl.formatMessage({
            id: 'InboxPage.statePending',
          }),
        };
      } else if (txIsPaymentExpired(tx)) {
        return {
          headingState: HEADING_PAYMENT_EXPIRED,
          showDetailCardHeadings: isCustomer,
          sx: {
            height: '28px',
            '& .MuiChip-label': {
              padding: '6px',
              color: '#fff',
              fontWeight: '600',
              borderRadius: '6px',
              backgroundColor: '#EA4335'
            }
          },
          state: intl.formatMessage({
            id: 'InboxPage.stateDeclined',
          }),
        };
      } else if (txIsRequested(tx)) {
        return {
          headingState: HEADING_REQUESTED,
          showDetailCardHeadings: isCustomer,
          showSaleButtons: isProvider && !isCustomerBanned,
          sx: {
            height: '28px',
            '& .MuiChip-label': {
              padding: '6px',
              color: '#fff',
              fontWeight: '600',
              borderRadius: '6px',
              backgroundColor: '#FAB504'
            }
          },
          state: intl.formatMessage({
            id: 'InboxPage.statePending',
          }),
        };
      } else if (txIsAccepted(tx)) {
        return {
          headingState: HEADING_ACCEPTED,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
          sx: {
            height: '28px',
            '& .MuiChip-label': {
              padding: '6px',
              color: '#fff',
              fontWeight: '600',
              borderRadius: '6px',
              backgroundColor: '#0DDA21'
            }
          },
          state: intl.formatMessage({
            id: 'InboxPage.stateConfirmed',
          }),
        };
      } else if (txIsDeclined(tx)) {
        return {
          headingState: HEADING_DECLINED,
          showDetailCardHeadings: isCustomer,
          sx: {
            height: '28px',
            '& .MuiChip-label': {
              padding: '6px',
              color: '#fff',
              fontWeight: '600',
              borderRadius: '6px',
              backgroundColor: '#EA4335'
            }
          },
          state: intl.formatMessage({
            id: 'InboxPage.stateDeclined',
          }),
        };
      } else if (txIsCanceled(tx)) {
        return {
          headingState: HEADING_CANCELED,
          showDetailCardHeadings: isCustomer,
          sx: {
            height: '28px',
            '& .MuiChip-label': {
              padding: '6px',
              color: '#fff',
              fontWeight: '600',
              borderRadius: '6px',
              backgroundColor: '#EA4335'
            }
          },
          state: intl.formatMessage({
            id: 'InboxPage.stateCanceled',
          }),
        };
      } else if (txHasBeenDelivered(tx)) {
        return {
          headingState: HEADING_DELIVERED,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
          height: '12px',
          borderRadius: '0',
          sx: {
            '& .MuiChip-label': {
              height: '12px',
              color: '#505050',
              fontWeight: '300',
            }
          },
          state: intl.formatMessage({
            id: 'InboxPage.stateDelivered',
          }),
        };
      } else {
        return { headingState: 'unknown' };
      }
    };
    const stateData = stateDataFn(currentTransaction);




    const deletedListingTitle = intl.formatMessage({
      id: 'TransactionPanel.deletedListingTitle',
    });

    const bookingStateUpdate = intl.formatMessage({
      id: 'TransactionPanel.bookingStateUpdate',
    });

    const conversation = intl.formatMessage({
      id: 'TransactionPanel.conversation',
    });

    const experienceInformation = intl.formatMessage({
      id: 'TransactionPanel.experienceInformation',
    });

    const {
      authorDisplayName,
      customerDisplayName,
      otherUserDisplayName,
      otherUserDisplayNameString,
    } = displayNames(currentUser, currentProvider, currentCustomer, intl);

    const { publicData, geolocation } = currentListing.attributes;
    const location = publicData && publicData.location ? publicData.location : {};
    const listingTitle = currentListing.attributes.deleted
      ? deletedListingTitle
      : currentListing.attributes.title;

    const unitType = config.bookingUnitType;
    const isNightly = unitType === LINE_ITEM_NIGHT;
    const isDaily = unitType === LINE_ITEM_DAY;

    const unitTranslationKey = isNightly
      ? 'TransactionPanel.perNight'
      : isDaily
        ? 'TransactionPanel.perDay'
        : 'TransactionPanel.perUnit';

    const price = currentListing.attributes.price;
    const bookingSubTitle = price
      ? `${formatMoney(intl, price)} ${intl.formatMessage({ id: unitTranslationKey })}`
      : '';

    const firstImage =
      currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

    const saleButtons = (
      <SaleActionButtonsMaybe
        showButtons={stateData.showSaleButtons}
        acceptInProgress={acceptInProgress}
        declineInProgress={declineInProgress}
        acceptSaleError={acceptSaleError}
        declineSaleError={declineSaleError}
        onAcceptSale={() => onAcceptSale(currentTransaction.id)}
        onDeclineSale={() => onDeclineSale(currentTransaction.id)}
      />
    );

    const showSendMessageForm =
      !isCustomerBanned && !isCustomerDeleted && !isProviderBanned && !isProviderDeleted;

    const sendMessagePlaceholder = intl.formatMessage(
      { id: 'TransactionPanel.sendMessagePlaceholder' },
      { name: otherUserDisplayNameString }
    );

    const sendingMessageNotAllowed = intl.formatMessage({
      id: 'TransactionPanel.sendingMessageNotAllowed',
    });

    const paymentMethodsPageLink = (
      <NamedLink name="PaymentMethodsPage">
        <FormattedMessage id="TransactionPanel.paymentMethodsPageLink" />
      </NamedLink>
    );

    const classes = classNames(rootClassName || css.root, className);
    const lastTransitionAcceptOrConfirm =
      currentTransaction.attributes.lastTransition == 'transition/accept' ||
      currentTransaction.attributes.lastTransition == 'transition/confirm-payment';

    const isButtonDisabled = true;
    const todayString = intl.formatMessage({ id: 'ActivityFeed.today' });
    const createdAt = currentTransaction.attributes.createdAt;


    return (
      <Container maxWidth="pc" sx={{
        backgroundColor: "#FCFCFC",
        mt: {
          pc: 20,
          mobile: 7.5
        },
        mb: {
          pc: 10,
          mobile: 7.5
        }
      }}>
        <Grid container
          columnSpacing={3}
          rowSpacing={2}
        >
          <Grid
            item
            pc={7}
            mobile={12}
            columnSpacing={3}
            rowSpacing={3}
          >
            <Stack spacing={2}>
              {savePaymentMethodFailed ? (
                <p className={css.genericError}>
                  <FormattedMessage
                    id="TransactionPanel.savePaymentMethodFailed"
                    values={{ paymentMethodsPageLink }}
                  />
                </p>
              ) : null}
              <Grid item pc={12}>
                <Stack spacing={{ pc: 3, mobile: 2 }}>
                  {initialMessageFailed ? (
                    <p className={css.messageError}>
                      <FormattedMessage id="TransactionPanel.initialMessageFailed" />
                    </p>
                  ) : null}
                  {fetchMessagesError ? (
                    <p className={css.messageError}>
                      <FormattedMessage id="TransactionPanel.messageLoadingFailed" />
                    </p>
                  ) : null}

                  <Paper
                    variant="outlined"
                    sx={{
                      height: "100%",
                      px: 2,
                      py: { pc: 3, mobile: 2 },
                      borderColor: "#dddddd",
                      borderRadius: 1,
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        mt: 1,
                        mb: 1
                      }}>
                      {bookingStateUpdate}
                    </Typography>
                    <Stack alignItems="flex-start" direction="row" spacing={2} sx={{ mt: 3 }}>
                      <Stack direction="column">
                        <PanelHeading
                          customerName={customerName}
                          panelHeadingState={stateData.headingState}
                          providerName={providerName}
                          listingTitle={listingTitle}
                          listingDeleted={listingDeleted}
                          isCustomerBanned={isCustomerBanned}
                          listingId={listingId}
                          isCustomer={isCustomer}
                        />

                        <Typography variant="caption">
                          {formatDate(intl, todayString, createdAt)}
                        </Typography>
                      </Stack>
                      <StatusChip
                        label={stateData.state}
                        sx={stateData.sx}
                        style={{ marginTop: "2px" }}
                      />
                    </Stack>
                    <ActivityFeed
                      className={css.feed}
                      messages={messages}
                      transaction={currentTransaction}
                      currentUser={currentUser}
                      hasOlderMessages={false}
                      onShowOlderMessages={onShowMoreMessages}
                      fetchMessagesInProgress={fetchMessagesInProgress}
                    />
                  </Paper>
                  <Paper
                    variant="outlined"
                    sx={{
                      height: "100%",
                      px: 2,
                      py: { pc: 3, mobile: 2 },
                      borderColor: "#dddddd",
                      borderRadius: 1,
                    }}
                  >
                    <Stack spacing={2}>
                      <Typography
                        variant="h5"
                        sx={{
                          mp: 1,
                          mb: 1
                        }}>
                        {conversation}
                      </Typography>
                      <MessageFeed
                        className={css.feed}
                        messages={messages}
                        currentUser={currentUser}
                        hasOlderMessages={false}
                        onShowOlderMessages={onShowMoreMessages}
                        fetchMessagesInProgress={fetchMessagesInProgress}
                      />
                      {showSendMessageForm ? (
                        <SendMessageForm
                          formId={this.sendMessageFormName}
                          rootClassName={css.sendMessageForm}
                          messagePlaceholder={sendMessagePlaceholder}
                          inProgress={sendMessageInProgress}
                          sendMessageError={sendMessageError}
                          onFocus={this.onSendMessageFormFocus}
                          onBlur={this.onSendMessageFormBlur}
                          onSubmit={this.onMessageSubmit}
                          transactionRole={transactionRole}
                          input={{ 
                            value: this.state.messageValue, 
                            onChange: this.handleMessageChange 
                        }}
                        />
                      ) : (
                        <div className={css.sendingMessageNotAllowed}>{sendingMessageNotAllowed}</div>
                      )}
                    </Stack>
                  </Paper>
                </Stack>
              </Grid>
              {stateData.showSaleButtons ? (
                <div className={css.mobileActionButtons}>{saleButtons}</div>
              ) : null}
            </Stack>

          </Grid>

          <Grid item pc={5}
            columnSpacing={3}
            rowSpacing={3}>
            <Paper
              variant="outlined"
              sx={{
                height: "fit-content",
                px: 2,
                py: { pc: 3, mobile: 2 },
                borderColor: "#DDDDDD",
                borderRadius: 1,
              }}
            >
              <Stack
                spacing={2}
              >
                <Typography
                  variant="h5"
                  sx={{
                    mt: 1,
                    mb: 1
                  }}>
                  {experienceInformation}
                </Typography>

                <DetailCardExperience
                  listingTitle={listingTitle}
                  image={firstImage}
                  provider={currentProvider}
                  transaction={currentTransaction}
                />
                <Divider />
                {stateData.showBookingPanel ? (
                  <BookingPanel
                    // className={css.bookingPanel}
                    titleClassName={css.bookingTitle}
                    isOwnListing={false}
                    listing={currentListing}
                    title={listingTitle}
                    subTitle={bookingSubTitle}
                    authorDisplayName={authorDisplayName}
                    onSubmit={onSubmitBookingRequest}
                    onManageDisableScrolling={onManageDisableScrolling}
                    timeSlots={timeSlots}
                    fetchTimeSlotsError={fetchTimeSlotsError}
                    onFetchTransactionLineItems={onFetchTransactionLineItems}
                    lineItems={lineItems}
                    fetchLineItemsInProgress={fetchLineItemsInProgress}
                    fetchLineItemsError={fetchLineItemsError}
                  />
                ) : null}
                <BreakdownMaybe
                  transaction={currentTransaction}
                  transactionRole={transactionRole}
                  onCancelSale={onCancelSale}
                  onCancelSaleWithoutRefund={onCancelSaleWithoutRefund}
                />

                {stateData.showSaleButtons ? (
                  <div className={css.desktopActionButtons}>{saleButtons}</div>
                ) : null}
              </Stack>
            </Paper>
          </Grid>
        </Grid >
      </Container >
    );
  }
}

TransactionPanelComponent.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  acceptSaleError: null,
  declineSaleError: null,
  fetchMessagesError: null,
  initialMessageFailed: false,
  savePaymentMethodFailed: false,
  sendMessageError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  nextTransitions: null,
  lineItems: null,
  fetchLineItemsError: null,
};

TransactionPanelComponent.propTypes = {
  rootClassName: string,
  className: string,

  currentUser: propTypes.currentUser,
  transaction: propTypes.transaction.isRequired,
  totalMessagePages: number.isRequired,
  oldestMessagePageFetched: number.isRequired,
  messages: arrayOf(propTypes.message).isRequired,
  initialMessageFailed: bool,
  savePaymentMethodFailed: bool,
  fetchMessagesInProgress: bool.isRequired,
  fetchMessagesError: propTypes.error,
  sendMessageInProgress: bool.isRequired,
  sendMessageError: propTypes.error,
  onManageDisableScrolling: func.isRequired,
  onShowMoreMessages: func.isRequired,
  onSendMessage: func.isRequired,
  onSubmitBookingRequest: func.isRequired,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  nextTransitions: array,

  // Sale related props
  onAcceptSale: func.isRequired,
  onDeclineSale: func.isRequired,
  acceptInProgress: bool.isRequired,
  declineInProgress: bool.isRequired,
  acceptSaleError: propTypes.error,
  declineSaleError: propTypes.error,

  // line items
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape,


};

const TransactionPanel = injectIntl(TransactionPanelComponent);

export default TransactionPanel;
