import React, { useState } from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form, Field } from 'react-final-form';
import { KammuiSubmitButton, FieldTextInput, IconEnquiry } from '../../components';
import { propTypes } from '../../util/types';

import css from './EnquiryForm.module.css';

import { TextField, Box, InputLabel, Divider, Typography } from '@mui/material';

const EnquiryFormComponent = props => {

  const onSubmit = props.onSubmit

  return (
    <Form
      onSubmit={onSubmit}
      {...props}

      render={fieldRenderProps => {
        const {
          submitButtonWrapperClassName,
          formId,
          handleSubmit,
          inProgress,
          intl,
          listingTitle,
          authorDisplayName,
          sendEnquiryError,
          pristine,
        } = fieldRenderProps;

        const messageLabel = intl.formatMessage(
          {
            id: 'EnquiryForm.messageLabel',
          },
          { authorDisplayName }
        );
        const messagePlaceholder = intl.formatMessage(
          {
            id: 'EnquiryForm.messagePlaceholder',
          },
          { authorDisplayName }
        );
        const messageRequiredMessage = intl.formatMessage({
          id: 'EnquiryForm.messageRequired',
        });


        const submitInProgress = inProgress;
        const submitDisabled = submitInProgress || pristine;

        const [hasMessage, setHasMessage] = useState(false)

        const required = (event) => (event ? undefined : messageRequiredMessage);


        return (
          <form onSubmit={handleSubmit}>
            <Box
              sx={{
                padding: "0px",
                width: { pc: "100%", mobile: "100%" },
                height: "40px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
              }}>
              <Box
                sx={{
                  width: { pc: "100%", mobile: "100%" },
                  height: "20px",
                  fontFamily: 'Inter',
                  fontStyle: "normal",
                  fontWeight: "700",
                  fontSize: "16px",
                  lineHeight: "20px",
                }} >
                <FormattedMessage id="EnquiryForm.askAQuestion" />
              </Box>
            </Box>
            < Divider />

            <InputLabel>
              {messageLabel}
            </InputLabel>

            <Field
              name="message"
              validate={required}
            >
              {({ input, meta }) => (
                <>
                  <TextField
                    {...input}
                    inputProps={{
                      style: {
                        fontSize: "16px",
                        fontWeight: 400,
                        color: "#505050",
                        width: "340px",

                        backgroundColor: "#eeeeee",
                        border: "#cccccc",

                      }
                    }}
                    variant="outlined"
                    type="text"
                    id={formId ? `${formId}.message` : 'message'}
                    multiline={true}
                    rows={4}
                    name="message"
                    size="small"
                  />
                </>
              )}
            </Field>
            <br />
            <KammuiSubmitButton
              className={css.frame5}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}>
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: "16px",
                  lineHeight: "19px",
                  color: "#ffffff"
                }}>
                <FormattedMessage id="EnquiryForm.submitButtonText" />
              </Typography>
            </KammuiSubmitButton>
          </form >
        );
      }}
    />
  );
}
EnquiryFormComponent.defaultProps = {
  submitButtonWrapperClassName: null,
  inProgress: false,
  sendEnquiryError: null,

};

EnquiryFormComponent.propTypes = {
  submitButtonWrapperClassName: string,

  inProgress: bool,

  listingTitle: string.isRequired,
  authorDisplayName: string.isRequired,
  sendEnquiryError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const EnquiryForm = compose(injectIntl)(EnquiryFormComponent);

EnquiryForm.displayName = 'EnquiryForm';

export default EnquiryForm;
