import React, { useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import css from './ActivityOptionSelector.module.css';

export default ({ options, onSelect, selected }) => {
  const optionsArray = [
    {
      key: 'all',
      label: <FormattedMessage id="Activities.all" />,
    },
    ...options,
  ];


  return (
    <Row className={css.activityOptionsSelectorWrapper}>
      {optionsArray.map(a => {
        const isLastItem =
          optionsArray.findIndex(x => x.key === a.key) === optionsArray.length - 1;
        return (
          <Col lg={2} md={4} sm={3} xs={6} key={a.key} className={css.activityFilters}>
            <span
              className={
                selected === a.key ? css.activityFilterItemSelected : css.activityFilterItem
              }
              onClick={() => onSelect(a.key)}
              style={isLastItem ? { borderRight: 'none' } : {}}
            >
              {a.key}
            </span>
          </Col>
        );
      })}
    </Row>
  );
};
