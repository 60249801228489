import React, { useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { PrimaryButton, SecondaryButton } from '../../components';
import css from './TransactionPanel.module.css';
import Confirmation from './Confirmation';
import Dialog from '@mui/material/Dialog';



// Functional component as a helper to build ActionButtons for
// provider when state is preauthorized
const SaleActionButtonsMaybe = props => {
  const {
    className,
    rootClassName,
    showButtons,
    acceptInProgress,
    declineInProgress,
    acceptSaleError,
    declineSaleError,
    onAcceptSale,
    onDeclineSale,
  } = props;

  const buttonsDisabled = acceptInProgress || declineInProgress;

  const acceptErrorMessage = acceptSaleError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.acceptSaleFailed" />
    </p>
  ) : null;
  const declineErrorMessage = declineSaleError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.declineSaleFailed" />
    </p>
  ) : null;

  const classes = classNames(rootClassName || css.actionButtons, className);

  const [show, setShow] = useState(false);
  const [confirmType, setConfirmType] = useState("");

  function openModal(e, action) {
    setShow(true);
    setConfirmType(action);
  }

  return showButtons ? (
    <div className={classes}>
      <div className={css.actionErrors}>
        {acceptErrorMessage}
        {declineErrorMessage}
      </div>
      <div className={css.actionButtonWrapper}>


        <SecondaryButton
          inProgress={declineInProgress}
          disabled={buttonsDisabled}
          onClick={(e) => {
            openModal(e, "decline");
          }}
        >
          <FormattedMessage id="TransactionPanel.declineButton" />
        </SecondaryButton>


        <PrimaryButton
          inProgress={acceptInProgress}
          disabled={buttonsDisabled}
          onClick={(e) => {
            openModal(e, "accept");
          }}
        >
          <FormattedMessage id="TransactionPanel.acceptButton" />
        </PrimaryButton>

        <Dialog
          open={show}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            sx: {
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0px",
              position: "absolute",
              minWidth: {
                pc: "400px",
                mobile: "340px"
              },
              Height: {
                pc: "309px",
                mobile: '294px'
              },
              background: "#EAE7E4",
              borderRadius: "4px",
            }
          }}
        >
          <Confirmation
            show={show}
            setShow={setShow}
            confirmType={confirmType}
            onAcceptSale={onAcceptSale}
            onDeclineSale={onDeclineSale}
          />

        </Dialog>
      </div>
    </div >
  ) : null;
};

export default SaleActionButtonsMaybe;
