import pick from 'lodash/pick';
import merge from 'lodash/merge';
import config from '../../config';
import { denormalisedResponseEntities, setLanguageDependantFields } from '../../util/data';

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/FavListingsPage/SET_INITIAL_VALUES';
export const FETCH_DATA_REQUEST = 'app/FavListingsPage/FETCH_DATA_REQUEST';
export const FETCH_DATA_SUCCESS = 'app/FavListingsPage/FETCH_DATA_SUCCESS';
export const FETCH_DATA_ERROR = 'app/FavListingsPage/FETCH_DATA_ERROR';

// ================ Reducer ================ //

const initialState = {
  data: null,
  fetchDataError: null,
  isLoading: false,
};

const FavListingsPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case FETCH_DATA_REQUEST:
      return { ...state, fetchDataError: null, isLoading: true };
    case FETCH_DATA_SUCCESS:
      return {
        ...state,
        data: payload,
        isLoading: false,
      };
    case FETCH_DATA_ERROR:
      return { ...state, fetchDataError: payload, isLoading: false };
    default:
      return state;
  }
};

export default FavListingsPageReducer;

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const fetchDataRequest = () => ({ type: FETCH_DATA_REQUEST });
export const fetchDataSuccess = response => {
  return {
    type: FETCH_DATA_SUCCESS,
    payload: { data: response },
  };
};
export const fetchDataError = error => ({
  type: FETCH_DATA_ERROR,
  error: true,
  payload: error,
});

// ================ Thunks ================ //

export const loadData = params => (dispatch, getState, sdk) => {
  const language = params?.locale ? params.locale : 'ja';
  dispatch(fetchDataRequest());
  return sdk.currentUser.show().then(res => {
    const userPrivateData = res.data.data.attributes.profile.privateData;
    if (userPrivateData.favListingsArray) {
      const favListingsIdsArray = userPrivateData.favListingsArray;
      return sdk.listings
        .query({
          ids: favListingsIdsArray,
          include: ['images'],
          'fields.listing': ['title', 'geolocation', 'price', 'publicData', 'description'],
          'fields.image': [`variants.landscape-crop`, `variants.landscape-crop2x`],
          'limit.images': 1,
        })
        .then(res => {
          res.data.data = res.data.data.map(l => setLanguageDependantFields(l, language));
          const resp = denormalisedResponseEntities(res);
          dispatch(fetchDataSuccess(resp));
          return resp;
        })
        .catch(e => {
          console.log(e);
          dispatch(fetchDataError(e));
        });
    }
  });
};
