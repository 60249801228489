import pick from 'lodash/pick';
import moment from 'moment';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { denormalisedResponseEntities, setLanguageDependantFields } from '../../util/data';
import { getGuides } from '../../util/api';

const { UUID } = sdkTypes;
// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/GuidesPage/SET_INITIAL_VALUES';
export const FETCH_GUIDES_REQUEST = 'app/GuidesPage/FETCH_GUIDES_REQUEST';
export const FETCH_GUIDES_SUCCESS = 'app/GuidesPage/FETCH_GUIDES_SUCCESS';
export const FETCH_GUIDES_ERROR = 'app/GuidesPage/FETCH_GUIDES_ERROR';

// ================ Reducer ================ //

const initialState = {
  guidesIds: [],
  guides: null,
  fetchGuidesError: null,
};

const GuidesPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case FETCH_GUIDES_REQUEST:
      return { ...state, fetchGuidesError: null };
    case FETCH_GUIDES_SUCCESS:
      return {
        ...state,
        guidesIds: payload.data.data.map(u => u.id),
        guides: payload,
      };
    case FETCH_GUIDES_ERROR:
      return { ...state, fetchGuidesError: payload };

    default:
      return state;
  }
};

export default GuidesPageReducer;

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const fetchGuidesRequest = () => ({ type: FETCH_GUIDES_REQUEST });
export const fetchGuidesSuccess = response => {
  return {
    type: FETCH_GUIDES_SUCCESS,
    payload: { data: response.data },
  };
};
export const fetchGuidesError = error => ({
  type: FETCH_GUIDES_ERROR,
  error: true,
  payload: error,
});

// ================ Thunks ================ //

const guidesRequest = () => {
  return getGuides();
};

export const loadData = (params, search) => dispatch => {

  const language = params?.locale?params.locale:'ja';

  dispatch(fetchGuidesRequest());
  return guidesRequest().then(guides => {
    dispatch(fetchGuidesSuccess({ data: { data: guides.map(g => setLanguageDependantFields(g, language)) } }));
    return { data: { data: guides } };
  }).catch (e => {
    dispatch(fetchGuidesError(e))
  });
};
