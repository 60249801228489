import pick from 'lodash/pick';
import merge from 'lodash/merge';
import config from '../../config';

import { fetchCurrentUser } from '../../ducks/user.duck';
// ================ Action types ================ //
// ================ Reducer ================ //
// ================ Action creators ================ //
// ================ Thunks ================ //


export const loadData = (params = { locale: 'en' }) => (dispatch, getState, sdk) => {
  return sdk.currentUser?.show()
}
