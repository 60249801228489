import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { NamedLink } from '../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import SectionListings from '../../containers/LandingPage/SectionListings';
import { FormattedMessage } from 'react-intl';
import config from '../../config';
import './ActivitiesSection.scss';

const ActivitiesSection = ({ activitiesOptions, experienceGroups }) => (
  <Container className="custom-wrapper">
    {activitiesOptions.map(a => {
      let searchQuery;
      if (a.label && a.label.props && a.label.props.id.startsWith('Activities')) {
        searchQuery = `?pub_activities=has_any%3A${a.key}`;
      } else if (a.id && a.id.startsWith('Prefectures')) {
        searchQuery = `?pub_area=${a.key}`;
      } else {
        searchQuery = `?pub_styles=has_any%3A${a.key}`;
      }
      const label = a.id ? <FormattedMessage id={`${a.id}EnOnly`} /> : a.label;
      return (
        <Row key={a.key} className="align-items-stretch">
          <Col xs={8} sm={8} lg={10} md={8}>
            <p className="sectionTitleLabel">{label}</p>
          </Col>
          <Col xs={4} sm={4} lg={2} md={4} className="seeAllWrapper">
            <NamedLink
              name="SearchPage"
              params={{ locale: config.locale }}
              to={{
                search: searchQuery,
              }}
            >
              <p className="seeAllLabel">
                <FormattedMessage id="ExperiencePage.seeAll" />
              </p>
              <FontAwesomeIcon icon={faChevronRight} />
            </NamedLink>
          </Col>
          <Col xs={12}>
            <SectionListings
              listings={experienceGroups[a.key]}
              bottomLine={true}
              hideHeader={true}
            />
          </Col>
        </Row>
      );
    })}
  </Container>
);

export default ActivitiesSection;
