import axios from 'axios';
import config from '../../config';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { loadData as paymentMethodsLoadData } from '../PaymentMethodsPage/PaymentMethodsPage.duck';

// ================ Action types ================ //
export const STRIPE_SUBSCRIPTION_REQUEST = 'app/SubscriptionsPage/STRIPE_SUBSCRIPTION_REQUEST';
export const STRIPE_SUBSCRIPTION_SUCCESS = 'app/SubscriptionsPage/STRIPE_SUBSCRIPTION_SUCCESS';
export const STRIPE_SUBSCRIPTION_ERROR = 'app/SubscriptionsPage/STRIPE_SUBSCRIPTION_ERROR';

// ================ Reducer ================ //
const initialState = {
  stripeSubscriptionInProgress: false,
  subscriptionSuccess: false,
  stripeSubscriptionError: null,
};

export default function subscriptionsPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case STRIPE_SUBSCRIPTION_REQUEST:
      return { ...state, stripeSubscriptionInProgress: true, setupIntentError: null, stripeSubscriptionError: null };
    case STRIPE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        stripeSubscriptionInProgress: false,
        subscriptionSuccess: true,
        stripeSubscriptionError: null,
      };
    case STRIPE_SUBSCRIPTION_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, stripeSubscriptionInProgress: false, stripeSubscriptionError: payload };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const stripeSubscriptionRequest = () => ({ type: STRIPE_SUBSCRIPTION_REQUEST });
export const stripeSubscriptionSuccess = () => ({ type: STRIPE_SUBSCRIPTION_SUCCESS });
export const stripeSubscriptionError = e => ({
  type: STRIPE_SUBSCRIPTION_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //
export const createSubscription = params => (dispatch, getState, sdk) => {
  const { locale } = params;
  dispatch(stripeSubscriptionRequest());
  sdk.currentUser.show({ include: ['stripeCustomer'] }).then(u => {
    if (u.data.included) {
      const stripeCustomerId = u.data.included[0].attributes.stripeCustomerId;
      const firstName = u.data.data.attributes.profile.firstName;
      const lastName = u.data.data.attributes.profile.lastName;
      const email = u.data.data.attributes.email;

      return axios
        .post(`${config.canonicalRootURL}/api/subscription`, {
          stripeCustomerId: stripeCustomerId,
          firstName: firstName,
          secondName: lastName,
          locale,
          email: email,
        })
        .then(res => {
          dispatch(stripeSubscriptionSuccess());
          return dispatch(paymentMethodsLoadData());
        })
        .catch(e => {
          console.error("received error! ", e)
          dispatch(stripeSubscriptionError(e?.response?.data));
        });
    } else {
      dispatch(stripeSubscriptionError('No stripe customer included'));
    }
  });
};
export const loadData = () => (dispatch, getState, sdk) => {
  const ret = sdk.currentUser
    .updateProfile({
      protectedData: {
        kammuiPlusSecretCode: true,
      },
    })
    .then(res => {
      return dispatch(paymentMethodsLoadData());
    });
  return ret;
};
