import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import routeConfiguration from '../../routeConfiguration';
import config from '../../config';
import { metaTagProps } from '../../util/seo';
import { canonicalRoutePath } from '../../util/routes';

import facebookImage from '../../assets/ogp.jpg';
import twitterImage from '../../assets/ogp.jpg';

import "../../styles/bootstrap/base.scss"
import "../../styles/bootstrap/global.scss"


const twitterPageURL = siteTwitterHandle => {
  if (siteTwitterHandle && siteTwitterHandle.charAt(0) === '@') {
    return `https://twitter.com/${siteTwitterHandle.substring(1)}`;
  }
  return null;
};

const PageComponent = props => {
  useEffect(() => {
    // It's in <body> to improve initial rendering performance,
    // but after web app is initialized, react-helmet-async operates with <head>
    const pageSchema = document.getElementById('page-schema');
    if (pageSchema) {
      pageSchema.remove();
    }
  });

  const {
    children,
    location,
    intl,
    referrer,
    author,
    description,
    facebookImages,
    published,
    schema,
    tags,
    title,
    twitterHandle,
    twitterImages,
    updated,
  } = props;

  const referrerMeta = referrer ? <meta name="referrer" content={referrer} /> : null;

  const canonicalRootURL = config.canonicalRootURL;
  const shouldReturnPathOnly = referrer && referrer !== 'unsafe-url';
  const canonicalPath = canonicalRoutePath(
    routeConfiguration(),
    location,
    shouldReturnPathOnly,
    false
  );
  const canonicalUrl = `${canonicalRootURL}${canonicalPath}`;

  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'Page.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'Page.schemaDescription' });
  const metaTitle = title || schemaTitle;
  const metaDescription = description || schemaDescription;
  const facebookImgs = facebookImages || [
    {
      name: 'facebook',
      url: `${canonicalRootURL}${facebookImage}`,
      width: 1200,
      height: 630,
    },
  ];
  const twitterImgs = twitterImages || [
    {
      name: 'twitter',
      url: `${canonicalRootURL}${twitterImage}`,
      width: 600,
      height: 314,
    },
  ];

  const metaToHead = metaTagProps({
    author,
    contentType: 'website',
    description: metaDescription,
    facebookImages: facebookImgs,
    twitterImages: twitterImgs,
    published,
    tags,
    title: metaTitle,
    twitterHandle,
    updated,
    url: canonicalUrl,
    locale: intl.locale,
  });

  // eslint-disable-next-line react/no-array-index-key
  const metaTags = metaToHead.map((metaProps, i) => <meta key={i} {...metaProps} />);

  const facebookPage = config.siteFacebookPage;
  const twitterPage = twitterPageURL(config.siteTwitterHandle);
  const instagramPage = config.siteInstagramPage;
  const sameOrganizationAs = [facebookPage, twitterPage, instagramPage].filter(v => v != null);

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format

  // Schema attribute can be either single schema object or an array of objects
  // This makes it possible to include several different items from the same page.
  // E.g. Product, Place, Video
  const schemaFromProps = Array.isArray(schema) ? schema : [schema];
  const schemaArrayJSONString = JSON.stringify([
    ...schemaFromProps,
    {
      '@context': 'http://schema.org',
      '@type': 'Organization',
      '@id': `${canonicalRootURL}#organization`,
      url: canonicalRootURL,
      name: siteTitle,
      sameAs: sameOrganizationAs,
      logo: `${canonicalRootURL}/static/webapp-icon-192x192.png`,
      address: config.address,
    },
    {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url: canonicalRootURL,
      description: schemaDescription,
      name: schemaTitle,
      publisher: {
        '@id': `${canonicalRootURL}#organization`,
      },
    },
  ]);

  return (
    <div>
      <Helmet
        htmlAttributes={{
          lang: intl.locale,
        }}
      >
        <title>{title}</title>
        {referrerMeta}
        <link rel="canonical" href={canonicalUrl} />
        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
        <meta httpEquiv="Content-Language" content={intl.locale} />
        {metaTags}
        <script id="page-schema" type="application/ld+json">
          {schemaArrayJSONString.replace(/</g, '\\u003c')}
        </script>
      </Helmet>

      <div>{children}</div>
    </div>
  );
};
const Page = injectIntl(withRouter(PageComponent));
Page.displayName = 'Page';

export default Page;
