import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { parse } from '../../util/urlHelpers';
import config from '../../config';
import { getGuides } from '../../util/api';
import { setLanguageDependantFields } from '../../util/data';
const RESULT_PAGE_SIZE = 50;

// ================ Action types ================ //

export const SEARCH_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/SearchPage/SEARCH_LISTINGS_ERROR';

export const SEARCH_GUIDES_REQUEST= 'app/SearchPage/SEARCH_GUIDES_REQUEST';
export const SEARCH_GUIDES_SUCCESS = 'app/SearchPage/SEARCH_GUIDES_SUCCESS';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  searchParams: null,
  searchInProgress: false,
  searchListingsError: null,
  currentPageResultIds: [],
  searchMapListingIds: [],
  searchMapListingsError: null,
  guides: [],
};

const resultIds = data => data.data.map(l => l.id);

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchInProgress: true,
        searchMapListingIds: [],
        searchListingsError: null,
        guides: [],
      };
    case SEARCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.listings.data),
        guides: payload.guides,
        pagination: payload.listings.data.meta,
        searchInProgress: false,
      };
    case SEARCH_GUIDES_SUCCESS:
      return {
        ...state,
        guides: payload.guides.data,
      };

    case SEARCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchInProgress: false, searchListingsError: payload };
    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const searchListingsRequest = searchParams => ({
  type: SEARCH_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const searchListingsSuccess = (listings, guides) => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: { guides: guides, listings: listings },
});
export const searchGuidesSuccess = guides => ({
  type: SEARCH_GUIDES_SUCCESS,
  payload: guides,
});

export const searchListingsError = e => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const searchAll = (searchParams, language) => (dispatch, getState, sdk) => {
  dispatch(searchListingsRequest(searchParams));

  const { keywords, perPage, ...rest } = searchParams;

  const params = {
    keywords,
    ...rest,
    per_page: perPage,
  };

  const listings = sdk.listings
    .query(params)
    .then(response => {
      response.data.data = response.data.data.map(l => setLanguageDependantFields(l, language));

      //sort results to make EN show first on EN pages
      if (language === 'en') {
        response.data.data.sort((a, b) => {
          const langA = a.attributes.publicData?.language === 'jaen' ? 1 : 0;
          const langB = b.attributes.publicData?.language === 'jaen' ? 1 : 0;

          return langB - langA;
        });
      }

      dispatch(addMarketplaceEntities(response));
      return response;
    })
    .catch(e => {
      dispatch(searchListingsError(storableError(e)));
      throw e;
    });
  const guides = getGuides(keywords);

  return Promise.all([listings, guides]).then(res => {
    dispatch(searchListingsSuccess(...res));
  });
};

export const loadData = (params, search) => {
  const language = params?.locale ? params.locale : 'ja';
  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  const { page = 1, address, origin, ...rest } = queryParams;
  const originMaybe = config.sortSearchByDistance && origin ? { origin } : {};
  const searches = searchAll (
    {
      ...rest,
      ...originMaybe,
      page,
      perPage: RESULT_PAGE_SIZE,
      include: ['author', 'images'],
      'fields.listing': ['title', 'geolocation', 'price', 'publicData', 'description'],
      'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
      'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
      'limit.images': 1,
    },
    language
  );

  return searches;
};
