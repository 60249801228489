import React from 'react';
import loadable from '@loadable/component';
import getPageDataLoadingAPI from './containers/pageDataLoadingAPI';
import { NotFoundPage } from './containers';

// routeConfiguration needs to initialize containers first
// Otherwise, components will import form container eventually and
// at that point css bundling / imports will happen in wrong order.
import { NamedRedirect } from './components';

const pageDataLoadingAPI = getPageDataLoadingAPI();

const AboutPage = loadable(() => import(/* webpackChunkName: "AboutPage" */ './containers/AboutPage/AboutPage'));
const ExperiencesPage = loadable(() => import(/* webpackChunkName: "ExperiencesPage" */ './containers/ExperiencesPage/ExperiencesPage'));
const StylesPage = loadable(() => import(/* webpackChunkName: "StylesPage" */ './containers/StylesPage/StylesPage'));
const AreasPage = loadable(() => import(/* webpackChunkName: "AreasPage" */ './containers/AreasPage/AreasPage'));
const FavListingsPage = loadable(() => import(/* webpackChunkName: "FavListingsPage" */ './containers/FavListingsPage/FavListingsPage'));
const AuthenticationPage = loadable(() => import(/* webpackChunkName: "AuthenticationPage" */ './containers/AuthenticationPage/AuthenticationPage'));
const CheckoutPage = loadable(() => import(/* webpackChunkName: "CheckoutPage" */ './containers/CheckoutPage/CheckoutPage'));
const ContactDetailsPage = loadable(() => import(/* webpackChunkName: "ContactDetailsPage" */ './containers/ContactDetailsPage/ContactDetailsPage'));
const SubscriptionsPage = loadable(() => import(/* webpackChunkName: "SubscriptionsPage" */ './containers/SubscriptionsPage/SubscriptionsPage'));
const EditListingPage = loadable(() => import(/* webpackChunkName: "EditListingPage" */ './containers/EditListingPage/EditListingPage'));
const EmailVerificationPage = loadable(() => import(/* webpackChunkName: "EmailVerificationPage" */ './containers/EmailVerificationPage/EmailVerificationPage'));
const InboxPage = loadable(() => import(/* webpackChunkName: "InboxPage" */ './containers/InboxPage/InboxPage'));
const LandingPage = loadable(() => import(/* webpackChunkName: "LandingPage" */ './containers/LandingPage/LandingPage'));
const KammuiPlusLandingPage = loadable(() => import(/* webpackChunkName: "KammuiPlusLandingPage" */ './containers/LandingPages/KammuiPlusLandingPage'));
const KammuiPlusPropertyAccessPage = loadable(() => import(/* webpackChunkName: "KammuiPlusPropertyAccessPage" */ './containers/LandingPages/KammuiPlusPropertyAccessPage'));
const KammuiPlusBrandDiscountsPage = loadable(() => import(/* webpackChunkName: "KammuiPlusBrandDiscountsPage" */ './containers/LandingPages/KammuiPlusBrandDiscountsPage'));
const WinterLandingPage = loadable(() => import(/* webpackChunkName: "WinterLandingPage" */ './containers/LandingPages/LandingPageWinter'));
const ListingPage = loadable(() => import(/* webpackChunkName: "ListingPage" */ /* webpackPrefetch: true */ './containers/ListingPage/ListingPage'));
const ManageListingsPage = loadable(() => import(/* webpackChunkName: "ManageListingsPage" */ './containers/ManageListingsPage/ManageListingsPage'));
const PasswordChangePage = loadable(() => import(/* webpackChunkName: "PasswordChangePage" */ './containers/PasswordChangePage/PasswordChangePage'));
const PasswordRecoveryPage = loadable(() => import(/* webpackChunkName: "PasswordRecoveryPage" */ './containers/PasswordRecoveryPage/PasswordRecoveryPage'));
const PasswordResetPage = loadable(() => import(/* webpackChunkName: "PasswordResetPage" */ './containers/PasswordResetPage/PasswordResetPage'));
const PaymentMethodsPage = loadable(() => import(/* webpackChunkName: "PaymentMethodsPage" */ './containers/PaymentMethodsPage/PaymentMethodsPage'));
const PrivacyPolicyPage = loadable(() => import(/* webpackChunkName: "PrivacyPolicyPage" */ './containers/PrivacyPolicyPage/PrivacyPolicyPage'));
const ProfilePage = loadable(() => import(/* webpackChunkName: "ProfilePage" */ './containers/ProfilePage/ProfilePage'));
const ProfileSettingsPage = loadable(() => import(/* webpackChunkName: "ProfileSettingsPage" */ './containers/ProfileSettingsPage/ProfileSettingsPage'));
const SearchPage = loadable(() => import(/* webpackChunkName: "SearchPage" */ /* webpackPrefetch: true */  './containers/SearchPage/SearchPage'));
const MapPage = loadable(() => import(/* webpackChunkName: "MapPage" */ /* webpackPrefetch: true */  './containers/MapPage/MapPage'));
const GuidesPage = loadable(() => import(/* webpackChunkName: "GuidesPage" */ /* webpackPrefetch: true */ './containers/GuidesPage/GuidesPage'));
const FeaturedGuidesLandingPage = loadable(() => import(/* webpackChunkName: "FeaturedGuidesLandingPage" */ /* webpackPrefetch: true */ './containers/LandingPage/FeaturedGuidesLandingPage'));
const StripePayoutPage = loadable(() => import(/* webpackChunkName: "StripePayoutPage" */ './containers/StripePayoutPage/StripePayoutPage'));
const TermsOfServicePage = loadable(() => import(/* webpackChunkName: "TermsOfServicePage" */ './containers/TermsOfServicePage/TermsOfServicePage'));
const TransactionPage = loadable(() => import(/* webpackChunkName: "TransactionPage" */ './containers/TransactionPage/TransactionPage'));
const MovingInnAdventurePage = loadable(() => import(/* webpackChunkName: "MovingInnAdventurePage" */ './containers/AdventurePage/pages/MovingInn'));
const NisekoAdventurePage = loadable(() => import(/* webpackChunkName: "NisekoAdventurePage" */ './containers/AdventurePage/pages/NisekoWinter'));
const RishiriAdventurePage = loadable(() => import(/* webpackChunkName: "RishiriAdventurePage" */ './containers/AdventurePage/pages/Rishiri'));
const KagoshimaAdventurePage = loadable(() => import(/* webpackChunkName: "KagoshimaAdventurePage" */ './containers/AdventurePage/pages/Kagoshima'));
const NisekoWineryCyclingAdventurePage = loadable(() => import(/* webpackChunkName: "NisekoWineryCyclingAdventurePage" */ './containers/AdventurePage/pages/NisekoWineryCycling'));
const MiyazakiAdventurePage = loadable(() => import(/* webpackChunkName: "MiyazakiAdventurePage" */ './containers/AdventurePage/pages/Miyazaki'));
const YakushimaAdventurePage = loadable(() => import(/* webpackChunkName: "YakushimaAdventurePage" */ './containers/AdventurePage/pages/Yakushima'));
const AkanAdventurePage = loadable(() => import(/* webpackChunkName: "AkanAdventurePage" */ './containers/AdventurePage/pages/Akan'));
const HakubaAdventurePage = loadable(() => import(/* webpackChunkName: "HakubaAdventurePage" */ './containers/AdventurePage/pages/Hakuba'));
const NozawaAdventurePage = loadable(() => import(/* webpackChunkName: "NozawaAdventurePage" */ './containers/AdventurePage/pages/Nozawa'));
const AdventuresPage = loadable(() => import(/* webpackChunkName: "AdventuresPage" */ './containers/AdventurePage/pages/Adventures'));

export const ACCOUNT_SETTINGS_PAGES = [
  'ContactDetailsPage',
  'PasswordChangePage',
  'StripePayoutPage',
  'PaymentMethodsPage',
];

// https://en.wikipedia.org/wiki/Universally_unique_identifier#Nil_UUID
const draftId = '00000000-0000-0000-0000-000000000000';
const draftSlug = 'draft';

const RedirectToLandingPage = () => <NamedRedirect name="LandingPage" />;

// NOTE: Most server-side endpoints are prefixed with /api. Requests to those
// endpoints are indended to be handled in the server instead of the browser and
// they will not render the application. So remember to avoid routes starting
// with /api and if you encounter clashing routes see server/index.js if there's
// a conflicting route defined there.

// Our routes are exact by default.
// See behaviour from Routes.js where Route is created.
const routeConfiguration = () => {
  return [
    {
      path: '/:locale([a-z]{2})?/',
      name: 'LandingPage',
      component: LandingPage,
      loadData: pageDataLoadingAPI.LandingPage.loadData,
    },
    {
      path: '/winter',
      name: 'LandingPageWinterRoot',
      component: () => (
        <NamedRedirect name="LandingPageWinter" params={{ locale: 'en' }} />
      ),
    },
    {
      path: '/:locale([a-z]{2})?/winter',
      name: 'LandingPageWinter',
      component: WinterLandingPage,
      loadData: pageDataLoadingAPI.WinterLandingPage.loadData,
    },

    {
      path: '/:locale([a-z]{2})?/kammuiplus',
      name: 'KammuiPlusLandingPage',
      component: KammuiPlusLandingPage,
    },
    {
      path: '/:locale([a-z]{2})?/kammuiplus/propertyaccess',
      name: 'KammuiPlusPropertyAccessPage',
      component: KammuiPlusPropertyAccessPage,
    },
    {
      path: '/:locale([a-z]{2})?/kammuiplus/branddiscounts',
      name: 'KammuiPlusBrandDiscountsPage',
      component: KammuiPlusBrandDiscountsPage,
    },

    {
      path: '/:locale([a-z]{2})?/about',
      name: 'AboutPage',
      component: AboutPage,
    },
    {
      path: '/:locale([a-z]{2})?/experiences',
      name: 'ExperiencesPage',
      component: ExperiencesPage,
      loadData: pageDataLoadingAPI.ExperiencesPage.loadData,
    },
    {
      path: '/:locale([a-z]{2})?/styles',
      name: 'StylesPage',
      component: StylesPage,
      loadData: pageDataLoadingAPI.StylesPage.loadData,
    },
    {
      path: '/:locale([a-z]{2})?/areas',
      name: 'AreasPage',
      component: AreasPage,
      loadData: pageDataLoadingAPI.AreasPage.loadData,
    },
    {
      path: '/:locale([a-z]{2})?/favListings',
      name: 'FavListingsPage',
      component: FavListingsPage,
      loadData: pageDataLoadingAPI.FavListingsPage.loadData,
    },
    {
      path: '/:locale([a-z]{2})?/s',
      name: 'SearchPage',
      component: SearchPage,
      loadData: pageDataLoadingAPI.SearchPage.loadData,
      extraProps: { noSearch: true },
    },
    {
      path: '/:locale([a-z]{2})?/search',
      name: 'SearchClientPage',
      component: SearchPage,
      loadData: pageDataLoadingAPI.SearchPage.loadData,
    },
    {
      path: '/:locale([a-z]{2})?/map',
      name: 'MapPage',
      component: MapPage,
      loadData: pageDataLoadingAPI.MapPage.loadData,
    },

    {
      path: '/:locale([a-z]{2})?/guides',
      name: 'GuidesPage',
      component: GuidesPage,
      loadData: pageDataLoadingAPI.GuidesPage.loadData,
    },
    {
      path: '/:locale([a-z]{2})?/l',
      name: 'ListingBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/:locale([a-z]{2})?/l/:slug/:id',
      name: 'ListingPage',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/:locale([a-z]{2})?/kammui-guides',
      name: 'FeaturedGuidesLandingPage',
      component: FeaturedGuidesLandingPage,
    },
    {
      path: '/:locale([a-z]{2})?/l/:slug/:id/checkout',
      name: 'CheckoutPage',
      auth: true,
      component: CheckoutPage,
      setInitialValues: pageDataLoadingAPI.CheckoutPage.setInitialValues,
    },
    {
      path: '/:locale([a-z]{2})?/l/:slug/:id/:variant',
      name: 'ListingPageVariant',
      auth: true,
      authPage: 'LoginPage',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/:locale([a-z]{2})?/l/new',
      name: 'NewListingPage',
      auth: true,
      component: () => (
        <NamedRedirect
          name="EditListingPage"
          params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'description' }}
        />
      ),
    },
    {
      path: '/:locale([a-z]{2})?/l/:slug/:id/:type/:tab',
      name: 'EditListingPage',
      auth: true,
      component: EditListingPage,
      loadData: pageDataLoadingAPI.EditListingPage.loadData,
    },
    {
      path: '/:locale([a-z]{2})?/l/:slug/:id/:type/:tab/:returnURLType',
      name: 'EditListingStripeOnboardingPage',
      auth: true,
      component: EditListingPage,
      loadData: pageDataLoadingAPI.EditListingPage.loadData,
    },

    // Canonical path should be after the `/l/new` path since they
    // conflict and `new` is not a valid listing UUID.
    {
      path: '/:locale([a-z]{2})?/l/:id',
      name: 'ListingPageCanonical',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/:locale([a-z]{2})?/u',
      name: 'ProfileBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/:locale([a-z]{2})?/u/:id',
      name: 'ProfilePage',
      component: ProfilePage,
      loadData: pageDataLoadingAPI.ProfilePage.loadData,
    },
    {
      path: '/:locale([a-z]{2})?/profile-settings',
      name: 'ProfileSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ProfileSettingsPage,
    },

    // Note: authenticating with IdP (e.g. Facebook) expects that /login path exists
    // so that in the error case users can be redirected back to the LoginPage
    // In case you change this, remember to update the route in server/api/auth/loginWithIdp.js
    {
      path: '/:locale([a-z]{2})?/login',
      name: 'LoginPage',
      component: AuthenticationPage,
      extraProps: { tab: 'login' },
    },
    {
      path: '/:locale([a-z]{2})?/signup',
      name: 'SignupPage',
      component: AuthenticationPage,
      extraProps: { tab: 'signup' },
    },
    {
      path: '/:locale([a-z]{2})?/confirm',
      name: 'ConfirmPage',
      component: AuthenticationPage,
      extraProps: { tab: 'confirm' },
    },
    {
      path: '/:locale([a-z]{2})?/recover-password',
      name: 'PasswordRecoveryPage',
      component: PasswordRecoveryPage,
    },
    {
      path: '/:locale([a-z]{2})?/inbox',
      name: 'InboxPage',
      auth: true,
      authPage: 'LoginPage',
      component: InboxPage,
      loadData: pageDataLoadingAPI.InboxPage.loadData,
    },
    {
      path: '/:locale([a-z]{2})?/order/:id',
      name: 'OrderPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="OrderDetailsPage" params={{ ...props.params }} />,
    },
    {
      path: '/:locale([a-z]{2})?/order/:id/details',
      name: 'OrderDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: TransactionPage,
      extraProps: { transactionRole: 'customer' },
      loadData: params =>
        pageDataLoadingAPI.TransactionPage.loadData({ ...params, transactionRole: 'customer' }),
      setInitialValues: pageDataLoadingAPI.TransactionPage.setInitialValues,
    },
    {
      path: '/:locale([a-z]{2})?/sale/:id',
      name: 'SalePage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="SaleDetailsPage" params={{ ...props.params }} />,
    },
    {
      path: '/:locale([a-z]{2})?/sale/:id/details',
      name: 'SaleDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: TransactionPage,
      extraProps: { transactionRole: 'provider' },
      loadData: params =>
        pageDataLoadingAPI.TransactionPage.loadData({ ...params, transactionRole: 'provider' }),
    },
    {
      path: '/:locale([a-z]{2})?/listings',
      name: 'ManageListingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ManageListingsPage,
      loadData: pageDataLoadingAPI.ManageListingsPage.loadData,
    },
    {
      path: '/:locale([a-z]{2})?/account',
      name: 'AccountSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="ContactDetailsPage" />,
    },
    {
      path: '/:locale([a-z]{2})?/account/subscriptions',
      name: 'SubscriptionsPage',
      auth: true,
      authPage: 'LoginPage',
      component: SubscriptionsPage,
      loadData: pageDataLoadingAPI.SubscriptionsPage.loadData,
    },
    {
      path: '/:locale([a-z]{2})?/account/contact-details',
      name: 'ContactDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ContactDetailsPage,
      loadData: pageDataLoadingAPI.ContactDetailsPage.loadData,
    },
    {
      path: '/:locale([a-z]{2})?/account/change-password',
      name: 'PasswordChangePage',
      auth: true,
      authPage: 'LoginPage',
      component: PasswordChangePage,
    },
    {
      path: '/:locale([a-z]{2})?/account/payments',
      name: 'StripePayoutPage',
      auth: true,
      authPage: 'LoginPage',
      component: StripePayoutPage,
      loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
    },
    {
      path: '/:locale([a-z]{2})?/account/payments/:returnURLType',
      name: 'StripePayoutOnboardingPage',
      auth: true,
      authPage: 'LoginPage',
      component: StripePayoutPage,
      loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
    },
    {
      path: '/:locale([a-z]{2})?/account/payment-methods',
      name: 'PaymentMethodsPage',
      auth: true,
      authPage: 'LoginPage',
      component: PaymentMethodsPage,
      loadData: pageDataLoadingAPI.PaymentMethodsPage.loadData,
    },
    {
      path: '/:locale([a-z]{2})?/terms-of-service',
      name: 'TermsOfServicePage',
      component: TermsOfServicePage,
    },
    {
      path: '/:locale([a-z]{2})?/privacy-policy',
      name: 'PrivacyPolicyPage',
      component: PrivacyPolicyPage,
    },
    {
      path: '/:locale([a-z]{2})?/notfound',
      name: 'NotFoundPage',
      component: props => <NotFoundPage {...props} />,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /reset-password endpoint
    {
      path: '/:locale([a-z]{2})?/reset-password',
      name: 'PasswordResetPage',
      component: PasswordResetPage,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /verify-email endpoint
    {
      path: '/:locale([a-z]{2})?/verify-email',
      name: 'EmailVerificationPage',
      auth: true,
      authPage: 'LoginPage',
      component: EmailVerificationPage,
      loadData: pageDataLoadingAPI.EmailVerificationPage.loadData,
    },
    {
      path: '/:locale([a-z]{2})?/adventures',
      name: 'AdventuresPage',
      component: AdventuresPage,
    },
    {
      path: '/:locale([a-z]{2})?/adventures/niseko',
      name: 'NisekoAdventurePage',
      component: NisekoAdventurePage,
    },

    {
      path: '/:locale([a-z]{2})?/adventures/movingInn',
      name: 'MovingInnAdventurePage',
      component: MovingInnAdventurePage,
    },
    {
      path: '/:locale([a-z]{2})?/adventures/rishiri',
      name: 'RishiriAdventurePage',
      component: RishiriAdventurePage,
    },
    {
      path: '/:locale([a-z]{2})?/adventures/kagoshima',
      name: 'KagoshimaAdventurePage',
      component: KagoshimaAdventurePage,
    },
    {
      path: '/:locale([a-z]{2})?/adventures/nisekoWineryCycling',
      name: 'NisekoWineryCyclingAdventurePage',
      component: NisekoWineryCyclingAdventurePage,
    },
    {
      path: '/:locale([a-z]{2})?/adventures/miyazaki',
      name: 'MiyazakiAdventurePage',
      component: MiyazakiAdventurePage,
    },
    {
      path: '/:locale([a-z]{2})?/adventures/yakushima',
      name: 'YakushimaAdventurePage',
      component: YakushimaAdventurePage,
    },
    {
      path: '/:locale([a-z]{2})?/adventures/akan',
      name: 'AkanAdventurePage',
      component: AkanAdventurePage,
    },
     {
      path: '/:locale([a-z]{2})?/adventures/hakuba',
      name: 'HakubaAdventurePage',
      component: HakubaAdventurePage,
     },
    {
      path: '/:locale([a-z]{2})?/adventures/nozawa',
      name: 'NozawaAdventurePage',
      component: NozawaAdventurePage,
    },
  ];
};

export default routeConfiguration;
