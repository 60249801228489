import React from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingLanguageForm } from '../../forms';

const EditListingLanguagePanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || className);
  const currentListing = ensureOwnListing(listing);
  const { publicData } = currentListing.attributes;
  const {
    descriptionEN,
    titleEN,
    headline,
    headlineEN,
    requirementsEN,
    equipmentEN,
    facilitiesEN,
    itineraryEN,
    locationDescriptionEN,

    pickUpEN,
    nearestStationEN,
    accessInfoEN,
    distanceByCarEN,
    meetingPointEN,
  } = publicData;
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = <FormattedMessage id="EditListingLanguagePanel.title" />;

  return (
    <div className={classes}>
      <h1>{panelTitle}</h1>
      <EditListingLanguageForm
        initialValues={{
          titleEN,
          descriptionEN,
          headlineEN,
          requirementsEN,
          equipmentEN,
          facilitiesEN,
          itineraryEN,
          locationDescriptionEN,

          pickUpEN,
          nearestStationEN,
          accessInfoEN,
          distanceByCarEN,
          meetingPointEN,
        }}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          const {
            titleEN,
            descriptionEN,
            headlineEN,
            requirementsEN,
            equipmentEN,
            facilitiesEN,
            itineraryEN,
            locationDescriptionEN,

            pickUpEN,
            nearestStationEN,
            accessInfoEN,
            distanceByCarEN,
            meetingPointEN,
          } = values;

          const language = (titleEN && descriptionEN && headlineEN)?'jaen':'ja';

          const updateValues = {
            publicData: {
              titleEN,
              descriptionEN,
              headlineEN,
              requirementsEN,
              equipmentEN,
              facilitiesEN,
              itineraryEN,
              locationDescriptionEN,
              pickUpEN,
              nearestStationEN,
              accessInfoEN,
              distanceByCarEN,
              meetingPointEN,

              language,
            },
          };

          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
      />
    </div>
  );
};

EditListingLanguagePanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingLanguagePanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingLanguagePanel;
