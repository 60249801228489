import React from 'react';
import { DialogWithCloseXButton } from "../../components/index"

function Confirmation(props) {
  const {
    show,
    setShow,
    confirmType,
    onAcceptSale,
    onDeclineSale,
    onCancelSale,
    onCancelSaleWithoutRefund,
  } = props;

  const closeModal = () => {
    setShow(false);
  };

  // Accept, Decline, Cancel, Cancel without refound
  const confirm = {
    confirmTitleObj: {
      accept: 'Confirmation.accept',
      decline: 'Confirmation.decline',
      cancel: 'Confirmation.cancel',
      cancelWithoutRefund: 'Confirmation.cancel',
    },

    confirmSubTitleObj: {
      accept: 'Confirmation.acceptSubTitle',
      decline: 'Confirmation.declineSubTitle',
      cancel: 'Confirmation.cancelSubTitle',
      cancelWithoutRefund: 'Confirmation.cancelSubTitle',
    },
  };

  const confirmTitleId = confirm.confirmTitleObj[confirmType];

  let confirmation;

  if (confirmType === 'accept') {
    confirmation = onAcceptSale;
  } else if (confirmType === 'decline') {
    confirmation = onDeclineSale;
  } else if (confirmType === 'cancel') {
    confirmation = onCancelSale;
  } else if (confirmType === 'cancelWithoutRefund') {
    confirmation = onCancelSaleWithoutRefund;
  }



  return show ? (

    <DialogWithCloseXButton
      backgroundColor={"#EAE7E4"}
      titleId={"Confirmation.confirm"}
      contentId={`${confirmTitleId}`}
      onClose={closeModal}
      onClick={() => confirmation()}
    />

  ) : null;
}

export default Confirmation;

