import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import * as validators from '../../util/validators';
import { FormattedMessage } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';
import config from '../../config';
import { Button, FieldCheckboxGroup, Form, FieldSelect, FieldTextInput } from '../../components';

import css from './EditListingFeaturesForm.module.css';

const EditListingFeaturesFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        disabled,
        ready,
        rootClassName,
        className,
        name,
        handleSubmit,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        filterConfig,
        intl,
      } = formRenderProps;

      const length = intl.formatMessage({
        id: 'EditListingFeaturesForm.length',
      });
      const lengthPlaceHolder = intl.formatMessage({
        id: 'EditListingFeaturesForm.lengthPlaceHolder',
      });
      const requiredMessage = intl.formatMessage({
        id: 'EditListingFeaturesForm.required',
      });

      const season = intl.formatMessage({
        id: 'EditListingFeaturesForm.season',
      });

      const start = intl.formatMessage({
        id: 'EditListingFeaturesForm.start',
      });

      const middle = intl.formatMessage({
        id: 'EditListingFeaturesForm.middle',
      });

      const end = intl.formatMessage({
        id: 'EditListingFeaturesForm.end',
      });

      const groupSize = intl.formatMessage({
        id: 'EditListingFeaturesForm.groupSize',
      });

      const to = intl.formatMessage({
        id: 'EditListingFeaturesForm.to',
      });

      const counterPeople = intl.formatMessage({
        id: 'EditListingFeaturesForm.counterPeople',
      });

      const experienceType = intl.formatMessage({
        id: 'EditListingFeaturesForm.experienceType',
      });

      const itinerary = intl.formatMessage({
        id: 'EditListingFeaturesForm.itinerary',
      });

      const itineraryPlaceHolder = intl.formatMessage({
        id: 'EditListingFeaturesForm.itineraryPlaceHolder',
      });

      const requirements = intl.formatMessage({
        id: 'EditListingFeaturesForm.requirements',
      });

      const requirementsPlaceHolder = intl.formatMessage({
        id: 'EditListingFeaturesForm.requirementsPlaceHolder',
      });

      const equipment = intl.formatMessage({
        id: 'EditListingFeaturesForm.equipment',
      });

      const equipmentPlaceHolder = intl.formatMessage({
        id: 'EditListingFeaturesForm.equipmentPlaceHolder',
      });

      const facilities = intl.formatMessage({
        id: 'EditListingFeaturesForm.facilities',
      });

      const facilitiesPlaceHolder = intl.formatMessage({
        id: 'EditListingFeaturesForm.facilitiesPlaceHolder',
      });

      const chooseActivities = intl.formatMessage({
        id: 'EditListingFeaturesForm.chooseActivities',
      });

      const chooseStyles = intl.formatMessage({
        id: 'EditListingFeaturesForm.chooseStyles',
      });

      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = disabled || submitInProgress;

      //location description
      const locationDescriptionMessage = intl.formatMessage({
        id: 'EditListingLocationForm.locationDescription',
      });
      const locationDescriptionPlaceholderMessage = intl.formatMessage({
        id: 'EditListingLocationForm.locationDescriptionPlaceholder',
      });

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.showListingFailed" />
        </p>
      ) : null;

      const required = validators.required(
        intl.formatMessage({
          id: 'EditListingFeaturesForm.required',
        })
      );

      const experienceLengthOptions = findOptionsForSelectFilter('experienceLength', filterConfig);
      const experienceTypeOptions = findOptionsForSelectFilter('experienceType', filterConfig);
      const activitiesOptions = findOptionsForSelectFilter('activities', filterConfig);
      const stylesOptions = findOptionsForSelectFilter('styles', filterConfig);
      const monthsOptionsIntl = findOptionsForSelectFilter('monthsOptions', filterConfig);

      const makeOption = x => <option value={x.key}>{intl.formatMessage({ id: x.id })}</option>;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}

          {/* <FieldCheckboxGroup className={css.features} id={name} name={name} options={options} /> */}

          <FieldSelect
            id="experienceLength"
            name="experienceLength"
            label={length}
            validate={required}
            className={css.selectField}
          >
            <option value="" disabled hidden>
              {lengthPlaceHolder}
            </option>
            {experienceLengthOptions.map(makeOption)}
          </FieldSelect>

          <p className={css.fieldLabel}>{season}</p>
          <div className={css.selectFieldsWrapper}>
            <p className={css.selectFieldsMidText}>{start}</p>
            <FieldSelect
              className={css.selectFlexDirection}
              id="seasonStart"
              name="seasonStart"
              // label="Experience length"
              validate={required}
            >
              <option value="" disabled hidden>
                {lengthPlaceHolder}
              </option>
              {monthsOptionsIntl.map(makeOption)}
            </FieldSelect>
            <p className={css.selectFieldsMidText}>{middle}</p>
            <FieldSelect
              className={css.selectFlexDirection}
              id="seasonEnd"
              name="seasonEnd"
              // label="Experience length"
              validate={required}
            >
              <option value="" disabled hidden>
                {lengthPlaceHolder}
              </option>
              {monthsOptionsIntl.map(makeOption)}
            </FieldSelect>
            <p className={css.selectFieldsMidText}>{end}</p>
          </div>

          <FieldTextInput
            className={css.field}
            type="text"
            name="locationDescription"
            id={`locationDescription`}
            label={locationDescriptionMessage}
            placeholder={locationDescriptionPlaceholderMessage}
            validate={required}
          />



          <p className={css.fieldLabel}>{groupSize}</p>
          <div className={css.selectFieldsWrapper2}>
            <FieldTextInput
              className={css.fieldText}
              type="number"
              id={`groupMin`}
              name="groupMin"
              placeholder="1"
              validate={required}
            />
            <p style={{ marginRight: '2pc' }}>{counterPeople}</p>
            <p style={{ marginRight: '2pc' }}>{to}</p>
            <FieldTextInput
              className={css.fieldText}
              type="number"
              id={`groupMax`}
              name="groupMax"
              placeholder="6"
              validate={required}
            />
            <p style={{ marginRight: '2pc' }}>{counterPeople}</p>
          </div>

          <FieldSelect
            id="experienceType"
            name="experienceType"
            label={experienceType}
            validate={required}
          >
            <option value="" disabled hidden>
              {lengthPlaceHolder}
            </option>
            {experienceTypeOptions.map(makeOption)}
          </FieldSelect>

          <FieldTextInput
            className={css.field}
            type="textarea"
            id={`itinerary`}
            name="itinerary"
            label={itinerary}
            placeholder={itineraryPlaceHolder}
            validate={required}
          />

          <FieldTextInput
            className={css.field}
            type="textarea"
            id={`requirements`}
            name="requirements"
            label={requirements}
            placeholder={requirementsPlaceHolder}
            validate={required}
          />

          <FieldTextInput
            className={css.field}
            type="textarea"
            id={`equipment`}
            name="equipment"
            label={equipment}
            placeholder={equipmentPlaceHolder}
            validate={required}
          />

          <FieldTextInput
            className={css.field}
            type="textarea"
            id={`facilities`}
            name="facilities"
            label={facilities}
            placeholder={facilitiesPlaceHolder}
            validate={required}
          />

          <p className={css.fieldLabel2}>{chooseActivities}</p>
          <FieldCheckboxGroup
            twoColumns={true}
            className={css.features}
            id={'activities'}
            name={'activities'}
            options={activitiesOptions}
            validate={required}
          />

          <p className={css.fieldLabel2}>{chooseStyles}</p>
          <FieldCheckboxGroup
            twoColumns={true}
            className={css.features}
            id={'styles'}
            name={'styles'}
            options={stylesOptions}
            validate={required}
          />

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingFeaturesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingFeaturesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  name: string.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

const EditListingFeaturesForm = EditListingFeaturesFormComponent;

export default EditListingFeaturesForm;
