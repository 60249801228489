/**
 * This component will show the booking info and calculated total price.
 * I.e. dates and other details related to payment decision in receipt format.
 */
import React, { useState } from 'react';
import { oneOf, string } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  propTypes,
  LINE_ITEM_CUSTOMER_COMMISSION,
  LINE_ITEM_PROVIDER_COMMISSION,
} from '../../util/types';
import { daysBetween } from '../../util/dates';
import LineItemBookingPeriod from './LineItemBookingPeriod';
import LineItemBasePriceMaybe from './LineItemBasePriceMaybe';
import LineItemUnitsMaybe from './LineItemUnitsMaybe';
import LineItemSubTotalMaybe from './LineItemSubTotalMaybe';
import LineItemCustomerCommissionMaybe from './LineItemCustomerCommissionMaybe';
import LineItemCustomerCommissionRefundMaybe from './LineItemCustomerCommissionRefundMaybe';
import LineItemProviderCommissionMaybe from './LineItemProviderCommissionMaybe';
import LineItemProviderCommissionRefundMaybe from './LineItemProviderCommissionRefundMaybe';
import LineItemRefundMaybe from './LineItemRefundMaybe';
import LineItemSalesTax from './LineItemSalesTax';
import LineItemTotalPrice from './LineItemTotalPrice';
import { SecondaryButton } from '../../components';
import css from './BookingBreakdown.module.css';
import Confirmation from '../TransactionPanel/Confirmation';
import { Stack, Dialog } from '@mui/material';

export const BookingBreakdownComponent = props => {
  const {
    rootClassName,
    className,
    userRole,
    unitType,
    transaction,
    booking,
    intl,
    dateType,
    onCancelSale,
    onCancelSaleWithoutRefund,
  } = props;

  const lastTransitionAcceptOrConfirm =
    transaction.attributes.lastTransition == 'transition/accept' ||
    transaction.attributes.lastTransition == 'transition/confirm-payment';

  const rawCurrentDate = new Date();
  const currentDate = rawCurrentDate.getDate();
  const rawStartDate = transaction.booking.attributes.start;

  const startBookDate = rawStartDate.getDate();


  const isButtonDisabled = rawStartDate <= rawCurrentDate;

  const numberOfDaysBetween = !isButtonDisabled ? daysBetween(rawCurrentDate, rawStartDate) : -1;

  const isMoreThanFiveDays = !isButtonDisabled && numberOfDaysBetween >= 5;

  const unitTypeBug = 'line-item/unit';

  const isCustomer = userRole === 'customer';
  const isProvider = userRole === 'provider';
  const txProtectedData = transaction && transaction.attributes.protectedData;
  const hasCommissionLineItem = transaction.attributes.lineItems.find(item => {
    const hasCustomerCommission = isCustomer && item.code === LINE_ITEM_CUSTOMER_COMMISSION;
    const hasProviderCommission = isProvider && item.code === LINE_ITEM_PROVIDER_COMMISSION;
    return (hasCustomerCommission || hasProviderCommission) && !item.reversal;
  });

  const numberOfPersons = txProtectedData && txProtectedData.numberOfPersons;
  const classes = classNames(rootClassName || css.root, className);

  const [show, setShow] = useState(false);
  const [confirmType, setConfirmType] = useState('');

  function openModal(e, action) {
    setShow(true);
    setConfirmType(action);
  }

  /**
   * BookingBreakdown contains different line items:
   *
   * LineItemBookingPeriod: prints booking start and booking end types. Prop dateType
   * determines if the date and time or only the date is shown
   *
   * LineItemUnitsMaybe: if he unitType is line-＝＝　/unit print the name and
   * quantity of the unit
   *
   * LineItemBasePriceMaybe: prints the base price calculation for the listing, e.g.
   * "$150.00 * 2 nights $300"
   *
   * LineItemUnitPriceMaybe: prints just the unit price, e.g. "Price per night $32.00".
   * This line item is not used by default in the BookingBreakdown.
   *
   * LineItemUnknownItemsMaybe: prints the line items that are unknown. In ideal case there
   * should not be unknown line items. If you are using custom pricing, you should create
   * new custom line items if you need them.
   *
   * LineItemSubTotalMaybe: prints subtotal of line items before possible
   * commission or refunds
   *
   * LineItemRefundMaybe: prints the amount of refund
   *
   * LineItemCustomerCommissionMaybe: prints the amount of customer commission
   * The default transaction process used by FTW doesn't include the customer commission.
   *
   * LineItemCustomerCommissionRefundMaybe: prints the amount of refunded customer commission
   *
   * LineItemProviderCommissionMaybe: prints the amount of provider commission
   *
   * LineItemProviderCommissionRefundMaybe: prints the amount of refunded provider commission
   *
   * LineItemTotalPrice: prints total price of the transaction
   *
   */

  return (
    <Stack spacing={1.5} >
      <LineItemBookingPeriod
        booking={booking}
        unitType={unitType}
        dateType={dateType}
        numberOfPersons={numberOfPersons}
      />
      <LineItemUnitsMaybe
        transaction={transaction}
        unitType={unitType}
      />

      {/* <LineItemBasePriceMaybe transaction={transaction} unitType={unitTypeBug} intl={intl} />
       */}

      {/* <LineItemUnknownItemsMaybe transaction={transaction} isProvider={isProvider} intl={intl} /> */}

      <LineItemSubTotalMaybe
        transaction={transaction}
        unitType={unitType}
        userRole={userRole}
        intl={intl}
      />
      <LineItemRefundMaybe
        transaction={transaction}
        intl={intl}
      />

      <LineItemCustomerCommissionMaybe
        transaction={transaction}
        isCustomer={isCustomer}
        intl={intl}
      />
      <LineItemCustomerCommissionRefundMaybe
        transaction={transaction}
        isCustomer={isCustomer}
        intl={intl}
      />

      <LineItemProviderCommissionMaybe
        transaction={transaction}
        isProvider={isProvider}
        intl={intl}
      />
      <LineItemProviderCommissionRefundMaybe
        transaction={transaction}
        isProvider={isProvider}
        intl={intl}
      />

      <LineItemSalesTax
        transaction={transaction}
        isProvider={isProvider}
        intl={intl}
      />

      <LineItemTotalPrice
        transaction={transaction}
        isProvider={isProvider}
        intl={intl} />

      <div className={css.lineItemCancellation}>
        {!isProvider && lastTransitionAcceptOrConfirm ? (
          <div className={css.bottomWrapper}>
            <p className={css.bottomWrapperText} style={{ textAlign: 'center', fontSize: 14 }}>
              <span className={css.termsText}>
                <FormattedMessage id="BookingBreakdown.cancellationPolicy" />
              </span>
            </p>

            <SecondaryButton
              className={isButtonDisabled ? css.cancelButtonDisabled : css.cancelButton}
              onClick={e => {
                openModal(e, isMoreThanFiveDays ? 'cancel' : 'cancelWithoutRefund');
              }}
            >
              <FormattedMessage id="BookingBreakdown.cancel" />
            </SecondaryButton>

            <Dialog
              open={show}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <Confirmation
                show={show}
                setShow={setShow}
                confirmType={confirmType}
                onCancelSale={() => onCancelSale(transaction.id)}
                onCancelSaleWithoutRefund={() => onCancelSaleWithoutRefund(transaction.id)}
              />
            </Dialog>
          </div>
        ) : null}
      </div>
    </Stack>
  );
};

BookingBreakdownComponent.defaultProps = { rootClassName: null, className: null, dateType: null };

BookingBreakdownComponent.propTypes = {
  rootClassName: string,
  className: string,

  userRole: oneOf(['customer', 'provider']).isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
  transaction: propTypes.transaction.isRequired,
  booking: propTypes.booking.isRequired,
  dateType: propTypes.dateType,

  // from injectIntl
  intl: intlShape.isRequired,
};

const BookingBreakdown = injectIntl(BookingBreakdownComponent);

BookingBreakdown.displayName = 'BookingBreakdown';

export default BookingBreakdown;
