import React from 'react';
import { FormattedMessage, FormattedDate } from '../../util/reactIntl';
import moment from 'moment';
import { LINE_ITEM_NIGHT, DATE_TYPE_DATE, propTypes } from '../../util/types';
import { dateFromAPIToLocalNoon } from '../../util/dates';
import config from "../../config"

import css from './BookingBreakdown.module.css';
import { Grid, Typography, Divider, Box } from '@mui/material';

const BookingPeriod = props => {
  const isEnglish = config.locale === 'en';
  const { startDate, endDate, dateType, numberOfPersons } = props;

  const timeFormatOptions =
    dateType === DATE_TYPE_DATE
      ? {
        weekday: 'long',
      }
      : {
        weekday: 'short',
        hour: 'numeric',
        minute: 'numeric',
      };

  const dateFormatOptions = {
    month: 'short',
    day: 'numeric',
  };

  return (
      <Grid container direction="row" alignItems="flex-start">

        <Grid container item direction="column" pc={6} mobile={8} rowSpacing={1}>
          <Typography variant="caption">
            <FormattedMessage id="BookingBreakdown.bookingStart" />
          </Typography>
          {isEnglish ?
            <Grid container item direction="row" columnSpacing={1}>
              <Grid item>
                <Typography variant="subtitle1">
                  <FormattedDate value={startDate} {...timeFormatOptions} />
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1">
                  <FormattedDate value={startDate} {...dateFormatOptions} />
                </Typography>
              </Grid>
            </Grid>
            :
            <Grid container item direction="row" columnSpacing={1} rowSpacing={1} >
              <Grid item>
                <Typography variant="subtitle1">
                  <FormattedDate value={startDate} {...dateFormatOptions} />
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1">
                  <FormattedDate value={startDate} {...timeFormatOptions} />
                </Typography>
              </Grid>
            </Grid>
          }
        </Grid>




        {
          numberOfPersons &&
          <Grid container item direction="column" pc={6} mobile={4} rowSpacing={1} alignItems="flex-end">
            <Typography variant="caption" >
              <FormattedMessage id="BookingBreakdown.numberOfPersonsLabel" />
            </Typography>
            <Grid item >
              <Typography variant="subtitle1">
                <FormattedMessage id="BookingBreakdown.numberOfPersonsInfo" values={{ numberOfPersons }} />
              </Typography>
            </Grid>
          </Grid>
        }
      </Grid>

  );
};

const LineItemBookingPeriod = props => {
  const { booking, unitType, dateType, numberOfPersons } = props;

  // Attributes: displayStart and displayEnd can be used to differentiate shown time range
  // from actual start and end times used for availability reservation. It can help in situations
  // where there are preparation time needed between bookings.
  // Read more: https://www.sharetribe.com/api-reference/marketplace.html#bookings
  const { start, end, displayStart, displayEnd } = booking.attributes;
  const localStartDate = dateFromAPIToLocalNoon(displayStart || start);
  const localEndDateRaw = dateFromAPIToLocalNoon(displayEnd || end);

  const isNightly = unitType === LINE_ITEM_NIGHT;
  const endDay = isNightly ? localEndDateRaw : moment(localEndDateRaw).subtract(1, 'days');

  return (
    <>
      <Box>
        <BookingPeriod startDate={localStartDate} endDate={endDay} dateType={dateType} numberOfPersons={numberOfPersons} />
      </Box>
      <Divider />
    </>
  );
};
LineItemBookingPeriod.defaultProps = { dateType: null };

LineItemBookingPeriod.propTypes = {
  booking: propTypes.booking.isRequired,
  dateType: propTypes.dateType,
};

export default LineItemBookingPeriod;
