import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import Decimal from 'decimal.js';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { LINE_ITEM_SALEX_TAX_FEE, propTypes } from '../../util/types';
import config from '../../config';

import { Stack, Typography } from '@mui/material';

const { Money } = sdkTypes;

/**
 * Calculates the total price in sub units for multiple line items.
 */
const lineItemsTotal = lineItems => {
  const amount = lineItems.reduce((total, item) => {
    return total.plus(item.lineTotal.amount);
  }, new Decimal(0));
  const currency = lineItems[0] ? lineItems[0].lineTotal.currency : config.currency;
  return new Money(amount, currency);
};

/**
 * Checks if line item represents commission
 */
const isCommission = lineItem => {
  return (
    lineItem.code === LINE_ITEM_PROVIDER_COMMISSION ||
    lineItem.code === LINE_ITEM_CUSTOMER_COMMISSION
  );
};

/**
 * Returns non-commission, reversal line items
 */
const nonCommissionReversalLineItems = transaction => {
  return transaction.attributes.lineItems.filter(item => !isCommission(item) && item.reversal);
};

const LineItemSalesTax = props => {
  const { transaction, isProvider, intl } = props;

  const includeFilter = isProvider ? 'provider' : 'customer';

  const salesTaxLineItems = transaction.attributes.lineItems.find(item => {
    return (
      item.includeFor.includes(includeFilter) &&
      item.code === LINE_ITEM_SALEX_TAX_FEE &&
      !item.reversal
    );
  });

  if (salesTaxLineItems === undefined) return null;

  const salesTax = salesTaxLineItems.lineTotal;

  const formattedSalesTax = formatMoney(intl, salesTax);

  return formattedSalesTax ? (
    <Stack direction="row" justifyContent="space-between">
      <Typography variant="subtitle1">
        <FormattedMessage id="BookingBreakdown.salesTax" />
      </Typography>
      <Typography variant="subtitle1">{formattedSalesTax}</Typography>
    </Stack>
  ) : null;
};

LineItemSalesTax.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemSalesTax;
