import React from 'react';
import { intlShape } from '../../util/reactIntl';
import { bool, object, string } from 'prop-types';
import config from '../../config';
import * as validators from '../../util/validators';
import getCountryCodes from '../../translations/countryCodes';
import { FieldTextInput, FieldSelect } from '../../components';

import css from './StripePaymentAddress.module.css';


const StripePaymentAddress = props => {
  const { className, intl, disabled, form, fieldId, card } = props;

  const optionalText = intl.formatMessage({
    id: 'StripePaymentAddress.optionalText',
  });

  const addressLine1Label = intl.formatMessage({
    id: 'StripePaymentAddress.addressLine1Label',
  });

  const addressLine1Required = validators.required(
    intl.formatMessage({
      id: 'StripePaymentAddress.addressLine1Required',
    })
  );

  const addressLine2Label = intl.formatMessage(
    { id: 'StripePaymentAddress.addressLine2Label' },
    { optionalText: optionalText }
  );

  const addressLine2Placeholder = intl.formatMessage({
    id: 'StripePaymentAddress.addressLine2Placeholder',
  });

  const postalCodeLabel = intl.formatMessage({ id: 'StripePaymentAddress.postalCodeLabel' });
  const postalCodePlaceholder = intl.formatMessage({
    id: 'StripePaymentAddress.postalCodePlaceholder',
  });
  const postalCodeRequired = validators.required(
    intl.formatMessage({
      id: 'StripePaymentAddress.postalCodeRequired',
    })
  );

  const cityLabel = intl.formatMessage({ id: 'StripePaymentAddress.cityLabel' });
  const cityPlaceholder = intl.formatMessage({ id: 'StripePaymentAddress.cityPlaceholder' });
  const cityRequired = validators.required(
    intl.formatMessage({
      id: 'StripePaymentAddress.cityRequired',
    })
  );

  const stateLabel = intl.formatMessage(
    { id: 'StripePaymentAddress.stateLabel' },
    { optionalText: optionalText }
  );
  const statePlaceholder = intl.formatMessage({ id: 'StripePaymentAddress.statePlaceholder' });

  const countryLabel = intl.formatMessage({ id: 'StripePaymentAddress.countryLabel' });
  const countryPlaceholder = intl.formatMessage({ id: 'StripePaymentAddress.countryPlaceholder' });
  const countryRequired = validators.required(
    intl.formatMessage({
      id: 'StripePaymentAddress.countryRequired',
    })
  );

  const handleOnChange = event => {
    const value = event.target.value;
    form.change('postal', value);
    card.update({ value: { postalCode: value } });
  };

  // Use tha language set in config.locale to get the correct translations of the country names
  const countryCodes = getCountryCodes(config.locale);

  const isJapanese = config.locale === "ja"

  return (
    <div className={className ? className : css.root}>
      <div className={isJapanese ? css.japaneseDisplay : null}>
        <div className={css.formRow}>

          <FieldTextInput
            id={`${fieldId}.addressLine1`}
            name="addressLine1"
            disabled={disabled}
            className={css.field}
            type="text"
            autoComplete="off"
            label={addressLine1Label}

            validate={addressLine1Required}
            onUnmount={() => form.change('addressLine1', undefined)}
          />


          <FieldTextInput
            id={`${fieldId}.addressLine2`}
            name="addressLine2"
            disabled={disabled}
            className={css.field}
            type="text"
            autoComplete="off"
            label={addressLine2Label}

            onUnmount={() => form.change('addressLine2', undefined)}
          />
        </div>
      </div>
      <div className={isJapanese ? css.japaneseDisplay : null}>
        <div className={css.formRow}>
          <FieldTextInput
            id={`${fieldId}.postalCode`}
            name="postal"
            disabled={disabled}
            className={css.field}
            type="text"
            autoComplete="off"
            label={postalCodeLabel}

            validate={postalCodeRequired}
            onUnmount={() => form.change('postal', undefined)}
            onChange={event => handleOnChange(event)}
          />

          <FieldTextInput
            id={`${fieldId}.city`}
            name="city"
            disabled={disabled}
            className={css.field}
            type="text"
            autoComplete="billing address-level2"
            label={cityLabel}
            validate={cityRequired}
            onUnmount={() => form.change('city', undefined)}
          />
        </div>
      </div>
      <div className={isJapanese ? css.japaneseDisplay : null}>
        <div className={css.formRow}>
          <FieldTextInput
            id={`${fieldId}.state`}
            name="state"
            disabled={disabled}
            className={css.field}
            type="text"
            autoComplete="off"
            label={stateLabel}

            onUnmount={() => form.change('state', undefined)}
          />


          <FieldSelect
            id={`${fieldId}.country`}
            name="country"
            disabled={disabled}
            className={css.field}
            label={countryLabel}
            validate={countryRequired}
          >
            <option disabled value="">
              {countryPlaceholder}
            </option>
            {countryCodes.map(country => {
              return (
                <option key={country.code} value={country.code}>
                  {country.name}
                </option>
              );
            })}
          </FieldSelect>
        </div>
      </div>
      <div className={isJapanese ? null : css.japaneseDisplay}>
        <div className={css.formRow}>
          <FieldSelect
            id={`${fieldId}.country`}
            name="country"
            disabled={disabled}
            className={css.field}
            label={countryLabel}
            validate={countryRequired}
          >
            <option disabled value="">
              {countryPlaceholder}
            </option>
            {countryCodes.map(country => {
              return (
                <option key={country.code} value={country.code}>
                  {country.name}
                </option>
              );
            })}
          </FieldSelect>
          <FieldTextInput
            id={`${fieldId}.postalCode`}
            name="postal"
            disabled={disabled}
            className={css.field}
            type="text"
            autoComplete="off"
            label={postalCodeLabel}
            placeholder={postalCodePlaceholder}
            validate={postalCodeRequired}
            onUnmount={() => form.change('postal', undefined)}
            onChange={event => handleOnChange(event)}
          />

        </div>
      </div>

      <div className={isJapanese ? null : css.japaneseDisplay}>
        <div className={css.formRow}>
          <FieldTextInput
            id={`${fieldId}.state`}
            name="state"
            disabled={disabled}
            className={css.field}
            type="text"
            autoComplete="off"
            label={stateLabel}
            placeholder={statePlaceholder}
            onUnmount={() => form.change('state', undefined)}
          />
          <FieldTextInput
            id={`${fieldId}.city`}
            name="city"
            disabled={disabled}
            className={css.field}
            type="text"
            autoComplete="off"
            label={cityLabel}
            placeholder={cityPlaceholder}
            validate={cityRequired}
            onUnmount={() => form.change('city', undefined)}
          />

        </div>
      </div>
      <div className={isJapanese ? null : css.japaneseDisplay}>
        <div className={css.formRow}>
          <FieldTextInput
            id={`${fieldId}.addressLine1`}
            name="addressLine1"
            disabled={disabled}
            className={css.field}
            type="text"
            autoComplete="off"
            label={addressLine1Label}

            validate={addressLine1Required}
            onUnmount={() => form.change('addressLine1', undefined)}
          />
          <FieldTextInput
            id={`${fieldId}.addressLine2`}
            name="addressLine2"
            disabled={disabled}
            className={css.field}
            type="text"
            autoComplete="off"
            label={addressLine2Label}
            placeholder={addressLine2Placeholder}
            onUnmount={() => form.change('addressLine2', undefined)}
          />
        </div>
      </div>
    </div >
  );
};
StripePaymentAddress.defaultProps = {
  country: null,
  disabled: false,
  fieldId: null,
};

StripePaymentAddress.propTypes = {
  country: string,
  disabled: bool,
  form: object.isRequired,
  fieldId: string,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default StripePaymentAddress;
