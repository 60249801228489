import pick from 'lodash/pick';
import merge from 'lodash/merge';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';
import { setLanguageDependantFields } from '../../util/data';
import { denormalisedResponseEntities } from '../../util/data';
const { UUID } = sdkTypes;
// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/LandingPageWinter/SET_INITIAL_VALUES';
export const FETCH_DATA_REQUEST = 'app/LandingPageWinter/FETCH_DATA_REQUEST';
export const FETCH_DATA_SUCCESS = 'app/LandingPageWinter/FETCH_DATA_SUCCESS';
export const FETCH_DATA_ERROR = 'app/LandingPageWinter/FETCH_DATA_ERROR';

// ================ Reducer ================ //

const initialState = {
  data: null,
  fetchDataError: null,
};

const LandingPageWinterReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case FETCH_DATA_REQUEST:
      return { ...state, fetchDataError: null };
    case FETCH_DATA_SUCCESS:
      return {
        ...state,
        data: payload,
      };
    case FETCH_DATA_ERROR:
      return { ...state, fetchDataError: payload };

    default:
      return state;
  }
};

export default LandingPageWinterReducer;

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const fetchDataRequest = () => ({ type: FETCH_DATA_REQUEST });
export const fetchDataSuccess = response => {
  return {
    type: FETCH_DATA_SUCCESS,
    payload: response,
  };
};
export const fetchDataError = error => ({
  type: FETCH_DATA_ERROR,
  error: true,
  payload: error,
});

// ================ Thunks ================ //

const locations = config.winterLandingPageConfig ? JSON.parse(config.winterLandingPageConfig) : [];

export const loadData = (params = { locale: 'en' }) => (dispatch, getState, sdk) => {
  const language = params?.locale ? params.locale : 'ja';
  const promises = locations[params.locale]?.map(loc => {
    const key = loc.key;
    return sdk.listings
      .query({
        ids: loc.listingIds,
        include: ['images'],
        'fields.listing': ['title', 'geolocation', 'price', 'publicData', 'description'],
        'fields.image': [`variants.landscape-crop`, `variants.landscape-crop2x`],
        'limit.images': 1,
      })
      .then(res => {
        const listings = denormalisedResponseEntities(res).map(l =>
          setLanguageDependantFields({ type: 'listing', ...l }, language)
        );
        return { title: loc.title, listings: listings };
      });
  });

  return Promise.all(promises)
    .then(res => {
      dispatch(fetchDataSuccess(res));
    })
    .catch(e => {
      console.log(e);
      dispatch(fetchDataError(e));
    });
};
