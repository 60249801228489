import React, { Component } from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import { propTypes } from '../../util/types';
import { ModalMissingInformation, NamedLink, ExternalLink, KammuiLogo } from '../../components';
import { parseSubscription, bookingRequestURL } from '../../containers/SubscriptionsPage/util';

import './topbar.scss';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Container from 'react-bootstrap/Container';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

const activitiesOptions = findOptionsForSelectFilter('activities', config.custom.filters);
const stylesOptions = findOptionsForSelectFilter('styles', config.custom.filters);

const NavMenu = props => {
  const experienceActivityLinks = activitiesOptions.map(a => {
    const { label, icon, key } = a;
    return (
      <li key={key} className="menuLinkChildItem">
        <NamedLink
          name="SearchPage"
          to={{ search: `?pub_activities=has_any%3A${key}` }}
          onClick={props.onClose}
        >
          <FontAwesomeIcon className="icon" icon={icon}></FontAwesomeIcon>
          {label}
        </NamedLink>
      </li>
    );
  });
  const experienceStyleLinks = stylesOptions.map(a => {
    const { label, icon, key } = a;
    return (
      <li key={key} className="menuLinkChildItem">
        <NamedLink
          name="SearchPage"
          to={{ search: `?pub_styles=has_any%3A${key}` }}
          onClick={props.onClose}
        >
          <FontAwesomeIcon className="icon" icon={icon}></FontAwesomeIcon>
          {label}
        </NamedLink>
      </li>
    );
  });
  return (
    <Offcanvas
      className={'offcanvas-size-xl'}
      show={props.show}
      onHide={props.onClose}
      placement={'top'}
    >
      <Offcanvas.Header closeButton></Offcanvas.Header>
      <Offcanvas.Body>
        <div className="row menu-content">
          <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
            <ul>
              <li className="menuLinkParentItem">
                <NamedLink name="AdventuresPage" className="link-parent" onClick={props.onClose}>
                  <FormattedMessage id="Topbar.adventuresButtonLabel" />
                </NamedLink>
              </li>
              <li className="menuLinkParentItem">
                <NamedLink name="ExperiencesPage" className="link-parent" onClick={props.onClose}>
                  <FormattedMessage id="Topbar.experiencesButtonLabel" />
                </NamedLink>
              </li>
              {experienceActivityLinks}
            </ul>
          </div>
          <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
            <ul>
              <li className="menuLinkParentItem">
                <NamedLink name="StylesPage" className="link-parent" onClick={props.onClose}>
                  Styles
                </NamedLink>
              </li>
              {experienceStyleLinks}
            </ul>
            <ul className="mt-4">
              <li className="menuLinkParentItem">
                <NamedLink name="AreasPage" className="link-parent" onClick={props.onClose}>
                  <FormattedMessage id="Topbar.areasButtonLabel" />
                </NamedLink>
              </li>
              <li className="menuLinkParentItem">
                <NamedLink name="GuidesPage" className="link-parent" onClick={props.onClose}>
                  <FormattedMessage id="Topbar.guidesButtonLabel" />
                </NamedLink>
              </li>
              <li className="menuLinkParentItem">
                <ExternalLink
                  href={'https://stories.kammui.com/en/'}
                  sameTab={true}
                  className="link-parent"
                >
                  <FormattedMessage id="Topbar.storiesButtonLabel" />
                </ExternalLink>
              </li>
            
           
            </ul>
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

const UserMenu = props => {
  const handleLogout = () => {
    const { onLogout, history } = props;
    onLogout().then(() => {
      const path = pathByRouteName('LandingPage', routeConfiguration());

      // In production we ensure that data is really lost,
      // but in development mode we use stored values for debugging
      if (config.dev) {
        history.push(path);
      } else if (typeof window !== 'undefined') {
        window.location = path;
      }

      console.log('logged out'); // eslint-disable-line
    });
  };

  const { currentUser, isAuthenticated, notificationCount, transparentBackground } = props;

  const subscriptions = (
    <NamedLink className="dropdown-item" name="SubscriptionsPage" key="SubscriptionsPage">
      <FormattedMessage id="Topbar.SubscriptionsPage" />
    </NamedLink>
  );
  const inboxLink = (
    <NamedLink className="dropdown-item" name="InboxPage" key="InboxPage">
      <FormattedMessage id="Topbar.inbox" />
      {notificationCount > 0 && <Badge bg="danger">{notificationCount}</Badge>}
    </NamedLink>
  );
  const favListing = (
    <NamedLink className="dropdown-item" name="FavListingsPage" key="FavListingsPage">
      <FormattedMessage id="Topbar.FavListingsPage" />
    </NamedLink>
  );

  const accountSettings = (
    <NamedLink className="dropdown-item" name="AccountSettingsPage" key="AccountSettingsPage">
      <FormattedMessage id="Topbar.accountSettingsLink" />
    </NamedLink>
  );

  const client = () => {
    const items = [];
    items.push(subscriptions);

    if (!isGuide) {
      items.push(inboxLink);
    }
    items.push([favListing, accountSettings]);
    return items;
  };

  const guide = () => {
    const items = [
      <Dropdown.Header key="GuideMenuHeader">Guide Menu</Dropdown.Header>,
      inboxLink,
      <NamedLink className="dropdown-item" name="ManageListingsPage" key="ManageListingsPage">
        <FormattedMessage id="Topbar.yourListingsLink" />
      </NamedLink>,
      <NamedLink className="dropdown-item" name="NewListingPage" key="NewListingPage">
        <FormattedMessage id="Topbar.createListing" />
      </NamedLink>,
      <NamedLink className="dropdown-item" name="ProfileSettingsPage" key="ProfileSettingsPage">
        <FormattedMessage id="Topbar.profileSettingsLink" />
      </NamedLink>,
      <Dropdown.Divider key="divider" />,
      <Dropdown.Header key="UserMenuHeader">User Menu</Dropdown.Header>,
      ...client(),
    ];
    return items;
  };

  const isGuide = currentUser && currentUser.attributes.profile.protectedData.isGuide;

  return isAuthenticated ? (
    <Dropdown className="ms-auto" drop="down" align="end">
      <Dropdown.Toggle variant="link" className="btn-rightmenu-icon" id="dropdown-user-menu">
        {notificationCount > 0 ? (
          <div>
            <i className={`fa fa-user-circle ${transparentBackground ? 'icon-white' : ''}`}></i>
            <span className="red-dot"></span>
          </div>
        ) : (
          <i className={`fa fa-user-circle ${transparentBackground ? 'icon-white' : ''}`}></i>
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu className="btn-rightmenu">
        {isGuide ? guide() : client()}

        <Dropdown.Divider />
        <Dropdown.Item onClick={handleLogout}>
          <FormattedMessage id="Topbar.logout" />
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  ) : (
    <Dropdown className="ms-auto" drop="down" align="end">
      <Dropdown.Toggle variant="link" className="btn-rightmenu-icon" id="dropdown-user-menu">
          <i className={`fa fa-user-circle ${transparentBackground ? 'icon-white' : ''}`}></i>
      </Dropdown.Toggle>
      <Dropdown.Menu className="btn-rightmenu">
        <NamedLink name="LoginPage" key="topbarlogin" className="dropdown-item">
          <FormattedMessage id="Topbar.login" />
        </NamedLink>
        <NamedLink name="SignupPage" key="topbarsignup" className="dropdown-item">
          <FormattedMessage id="Topbar.signup" />
        </NamedLink>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const TopbarComponent = props => {
  const [showHamburger, setShowHamburger] = useState(false);
  const {
    currentUser,
    isAuthenticated,
    authScopes,
    transparentBackground,
  } = props;
  //when logged in through admin interface
  const limitedAccess =
    isAuthenticated && authScopes && authScopes.length === 1 && authScopes[0] === 'user:limited';

  const isEnglish = config.locale === 'en';

  const { subscriptionActive } = parseSubscription(currentUser);
  const topBarContainerClassName = transparentBackground ? 'topbarTransparent' : 'topbarContainer';

  const inquireLink = subscriptionActive
    ? bookingRequestURL(currentUser, config.locale)
    : isEnglish
    ? config.custom.inquireURL.publicEN
    : config.custom.inquireURL.publicJA;

  return (
    <>
      <Navbar className="topbar" bg={limitedAccess && 'warning'}>
        <Container fluid className={topBarContainerClassName}>
          <Nav.Item>
            <Button
              variant="link"
              className={`btn-hamburger ${transparentBackground ? 'btn-hamburger-transparent' : ''}`}
              onClick={() => setShowHamburger(true)}
            >
              <FontAwesomeIcon icon={faBars} />
            </Button>
          </Nav.Item>

          {limitedAccess && <h2 className="ms-auto">Logged in through Admin</h2>}
          <Nav.Item className="mr-auto">
            <div className={transparentBackground ? 'navbar-brand navbar-brand-transparent' : 'navbar-brand'}>
              <KammuiLogo />
            </div>
          </Nav.Item>
          <Nav.Item className="ms-auto">
          </Nav.Item>
          <Nav.Item>
            <UserMenu {...props} transparentBackground={transparentBackground} />
          </Nav.Item>
        </Container>
        <NavMenu show={showHamburger} onClose={() => setShowHamburger(false)} />
      </Navbar>
      <ModalMissingInformation id="MissingInformationReminder" {...props} />
    </>
  );
};

TopbarComponent.defaultProps = {
  notificationCount: 0,
  currentUser: null,
  currentUserHasOrders: null,
  currentPage: null,
  sendVerificationEmailError: null,
  authScopes: [],
  transparentBackground: false,
};

const { array, func, number, shape, string, bool } = PropTypes;

TopbarComponent.propTypes = {
  isAuthenticated: bool.isRequired,
  authScopes: array,
  authInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentUserHasListings: bool.isRequired,
  currentUserHasOrders: bool,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
  onResendVerificationEmail: func.isRequired,
  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  showGenericError: bool.isRequired,
  transparentBackground: PropTypes.bool,

  // These are passed from Page to keep Topbar rendering aware of location changes
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const Topbar = compose(injectIntl)(TopbarComponent);

Topbar.displayName = 'Topbar';

export default Topbar;
