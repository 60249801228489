import React from 'react';

import {
  Button,
  Box,
  Typography,
  Divider,
  Modal,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  IconButton,
  DialogActions
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';


import { FormattedMessage } from "../../util/reactIntl";


export const SimpleDialogWithTitle = props => {
  const {
    titleId,
    id,
    open,
    onClose,
    children,
    maxWidth = 'sm',
    buttonId
  } = props;

  return (
    <Dialog
      id={id}
      fullWidth={false}
      maxWidth={maxWidth}
      open={open}
      onClose={onClose}
      backgroundColor="#EAE7E4">
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {onClose ? (
          <Box sx={{ justifyContent: 'flex-end' }}>
            <Typography
              sx={{
                fontWeight: "700",
                fontSize: "16px",
                mt: 1,
              }}>
              <FormattedMessage id={titleId} />
            </Typography>
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            >
              <FontAwesomeIcon icon={faXmark} />
            </IconButton>
          </Box>
        ) : null}
        <Divider
          sx={{
            mt: 2
          }}
        />
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      {buttonId ? (<DialogActions  >
        <Button
          size='large'
          onClick={onClose}
          variant="contained"
          color="normalButton"
          sx={{
            border: "1px solid #CCCCCC",
            marginRight: 2,
            mb: 2
          }}
        >
          <FormattedMessage variant="subtitle1" id={buttonId} />
        </Button>

      </DialogActions>) : null
      }
    </Dialog >
  );
};

export const SimpleDialog = props => {
  const { id, open, onClose, children, maxWidth = 'sm' } = props;

  return (
    <Dialog id={id} fullWidth={false} maxWidth={maxWidth} open={open} onClose={onClose}>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <FontAwesomeIcon icon={faXmark} />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export const SimpleModal = props => {
  const { id, isOpen, onCloseModal, children } = props;

  return (
    <Modal id={id} open={isOpen} onClose={onCloseModal}>
      {children ? children : <p />}
    </Modal>
  );
};

export const DialogWithCloseXButton = props => {
  const {
    backgroundColor,
    titleId,
    contentId,
    onClose,
    onClick,
  } = props;
  return (
    <Box sx={{ backgroundColor: { backgroundColor } }}>
      <DialogTitle>
        <Box sx={{ justifyContent: 'flex-end' }}>
          <Typography sx={{ fontWeight: "700", fontSize: "16px" }}>
            <FormattedMessage id={titleId} />
          </Typography>
          <IconButton
            size="small"
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <FontAwesomeIcon icon={faXmark} />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider variant="middle" />
      <DialogContent
        sx={{
          padding: {
            pc: '30px ',
            mobile: '30px 20px ',
          },
          width: {
            pc: '400px',
            mobile: '340px',
          },
          maxHeight: {
            pc: '130px',
            mobile: '150px',
          },
        }} >
        <DialogContentText
          sx={{
            fontWeight: "700",
            fontSize: "16px",
            lineHeight: "30px"
          }} >
          <FormattedMessage id={contentId} />
        </DialogContentText>
      </DialogContent>
      <DialogActions >
        <Button
          size='large'
          onClick={onClose}
          variant="contained"
          sx={{
            border: "1px solid #CCCCCC"
          }}
          color="normalButton"
        >
          <FormattedMessage variant="subtitle1" id="Confirmation.no" />
        </Button>

        <Button
          size='large'
          onClick={onClick}
          variant="contained"
          color="submitButton"
        >
          <FormattedMessage variant="subtitle1" id="Confirmation.yes" />
        </Button>

      </DialogActions>
    </Box >
  );

}

export default SimpleModal;
