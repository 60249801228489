import { parse } from '../../util/urlHelpers';
import { denormalisedResponseEntities, setLanguageDependantFields } from '../../util/data';

export const SEARCH_LISTINGS_REQUEST = 'app/MapPage/SHOW_LISTING_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/MapPage/SHOW_LISTING_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/MapPage/SHOW_LISTING_ERROR';

import { types as sdkTypes } from '../../util/sdkLoader';
const { LatLng } = sdkTypes;

const initialState = {
  searchInProgress: false,
  listings: [],
  filters: { pub_activities: '' },
};

const mapPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_LISTINGS_REQUEST:
      return { ...state, searchInProgress: true, filters: payload.filters };
    case SEARCH_LISTINGS_SUCCESS:
      return { ...state, listings: payload, searchInProgress: false };
    case SEARCH_LISTINGS_ERROR:
      return { ...state, searchInProgress: false };

    default:
      return state;
  }
};

const searchListingsRequest = (keywords, filters) => ({
  type: SEARCH_LISTINGS_REQUEST,
  payload: { filters: filters, keywords: keywords },
});
const searchListingsSuccess = listings => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: listings,
});
const searchListingsError = listings => ({
  type: SEARCH_LISTINGS_ERROR,
  payload: {},
});

export const searchListings = (keywords, filters, origin, language = 'en') => (
  dispatch,
  getState,
  sdk
) => {
  const searchCenter = new LatLng(origin.lat, origin.lon);
  dispatch(searchListingsRequest(keywords, filters));
  const listings = sdk.listings
    .query({
      include: ['images'],
      origin: searchCenter,
      ...filters,
      'limit.images': 1,
    })
    .then(response => {
      response.data.data = response.data.data.map(l => setLanguageDependantFields(l, language));
      const denorm = denormalisedResponseEntities(response);
      dispatch(searchListingsSuccess(denorm));
      return denorm;
    });
  return listings;
};

export default mapPageReducer;

export const STARTING_MAP_LOCATION = { lat: 35.1, lon: 138.1 };

export const loadData = (params, search) => dispatch => {
  const language = params?.locale ? params.locale : 'en';
  const queryParams = parse(search);

  const { keywords } = queryParams;

  return dispatch(
    searchListings(keywords, { pub_activities: '' }, STARTING_MAP_LOCATION, language)
  );
};
