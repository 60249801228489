import React, { Component } from 'react';
import { string, bool, func } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { Form, SecondaryButton } from '../../components';
import TextField from '@mui/material/TextField';
import { propTypes } from '../../util/types';
import { Field } from 'react-final-form';
import { Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';


import css from './SendMessageForm.module.css';

const BLUR_TIMEOUT_MS = 100;



class SendMessageFormComponent extends Component {
  constructor(props) {
    super(props);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.blurTimeoutId = null;
    this.textareaRef = React.createRef();
    this.state = {
      minHeight: "40px" // default height
  };

  }


  autoResizeTextArea() {
    const textarea = this.textareaRef.current;
    if (textarea) {
        const MAX_LINES = 20; 
        const LINE_HEIGHT = 20;  

        textarea.style.height = 'auto'; 
        const newHeight = Math.min(textarea.scrollHeight, MAX_LINES * LINE_HEIGHT);
        textarea.style.height = `${newHeight}px`;

        this.setState({ minHeight: `${newHeight}px` });  
    }
}



  componentDidUpdate(prevProps) {
    const prevFieldValue = prevProps.input && prevProps.input.value;
    const currentFieldValue = this.props.input && this.props.input.value;

    if (prevFieldValue !== currentFieldValue) {
        this.autoResizeTextArea();
    }


  }


  

  handleFocus() {
    this.props.onFocus();
    window.clearTimeout(this.blurTimeoutId);
  }

  handleBlur() {
    // We only trigger a blur if another focus event doesn't come
    // within a timeout. This enables keeping the focus synced when
    // focus is switched between the message area and the submit
    // button.
    this.blurTimeoutId = window.setTimeout(() => {
      this.props.onBlur();
    }, BLUR_TIMEOUT_MS);
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        render={formRenderProps => {
          const {
            rootClassName,
            className,
            messagePlaceholder,
            handleSubmit,
            inProgress,
            sendMessageError,
            invalid,
            form,
            formId,
            intl,
            transactionRole,
            pristine,
          } = formRenderProps;



          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = inProgress;
          const isLoading = submitInProgress ? true : false;
          const submitDisabled = pristine || submitInProgress;
          const messageToCustomerPlaceholder = intl.formatMessage(
            { id: 'SendMessageForm.messageToCustomerPlaceholder' },
          );
          const messageToGuidPlaceholder = intl.formatMessage(
            { id: 'SendMessageForm.messageToGuidPlaceholder' },
          );


          const whoISendMessage = () => {
            if (transactionRole === 'customer') {
              return messageToGuidPlaceholder;
            } else if (transactionRole === 'provider') {
              return messageToCustomerPlaceholder
            }
          }

          return (
            <Form onSubmit={values => handleSubmit(values, form)}>
              <Grid
                container
                spacing={2}
                sx={{ mt: 1 }}
              >
                <Field name="message">
                  {props => (
                    <Grid item pc={10} mobile={8} >
                    
                    <TextField
                      id={formId ? `${formId}.message1` : 'message'}
                      name="message"
                      fullWidth
                      value={props.input.value}
                      placeholder={whoISendMessage()}
                      multiline
                      variant="outlined"
                      maxRows={25}
                      size="small"
                      inputRef={this.textareaRef}
                      onChange={(e) => {
                        props.input.onChange(e);
                        this.autoResizeTextArea();
                     }}
                      sx={{
                        fontSize: "16px",
                        fontWeight: 400,
                        color: "#505050",
                        boxSizing: 'border-box',
                        '& .MuiOutlinedInput-root': {
                          minWidth: "100%",
                          minHeight: this.state.minHeight,// Initial height
                          backgroundColor: "#ffffff",
                          border: "1px solid #dddddd"
                        },
                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'transparent',
                        },
                      
                   }}
                      InputProps={{
                        style: {
                            overflow: 'hidden',
                            resize: 'none',
                            lineHeight:"20px",
                        }
                    }}
                    
                    />

                    </Grid>
                  )}
                </Field>
                <Grid item pc={2} mobile={4} sx={{ alignSelf: 'flex-end' }}>
                  <LoadingButton loading={isLoading} type="submit" fullWidth variant="contained" disabled={submitDisabled} >
                    <FormattedMessage id="SendMessageForm.sendMessage" />
                  </LoadingButton>
                </Grid>
              </Grid>
            </Form >
          );
        }
        }
      />
    );
  }
}

SendMessageFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inProgress: false,
  messagePlaceholder: null,
  onFocus: () => null,
  onBlur: () => null,
  sendMessageError: null,
};

SendMessageFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  inProgress: bool,

  messagePlaceholder: string,
  onSubmit: func.isRequired,
  onFocus: func,
  onBlur: func,
  sendMessageError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SendMessageForm = compose(injectIntl)(SendMessageFormComponent);

SendMessageForm.displayName = 'SendMessageForm';

export default SendMessageForm;
