import { types as sdkTypes } from './sdkLoader';
const { Money, UUID, LatLng } = sdkTypes;
const sharetribeSdk = require('sharetribe-flex-sdk');
const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});

export const baseQueryCard = {
  include: ['images'],
  'fields.listing': ['title', 'geolocation', 'price', 'publicData', 'description'],
  'fields.image': [`variants.landscape-crop`, `variants.landscape-crop2x`],
  'limit.images': 1,
};
const getListingInfo = id => {
  return sdk.listings
    .show({ id: new UUID(id), include: ['images'] })
    .then(res => {
      const listing = res.data.data;
      const listingImages = res.data.included?.filter(i => i.type === 'image');
      listing.attributes.images = listingImages;
      return listing;
    })
    .catch(e => {
      throw e;
    });
};

const imageUrlToSharetribeImageId = imageUrl => {
  if (!imageUrl) {
    return null;
  }

  const toDataURL = url =>
    fetch(url)
      .then(response => response.blob())
      .then(
        blob =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
      );

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  return toDataURL(imageUrl).then(dataUrl => {
    const fileData = dataURLtoFile(dataUrl, 'imageName.jpg');

    return sdk.images
      .upload(
        {
          image: fileData,
        },
        {
          expand: true,
        }
      )
      .then(resp => {
        const imageUrl = resp.data.data.attributes?.variants?.default?.url;
        const imageId = resp.data.data.id.uuid;
        const finalImgObj = {
          image: imageUrl,
          id: imageId,
        };
        return finalImgObj;
      })
      .catch(e => {
        console.log(e);
        return null;
      });
  });
};

const imagesUrlsToStUploadReady = urls => {
  const uploadImagesPromises = urls.map(url => {
    return imageUrlToSharetribeImageId(url)
      .then(resp => {
        return resp;
      })
      .catch(e => {
        return console.log(e);
      });
  });
  return Promise.all(uploadImagesPromises)
    .then(res => {
      const imagesUUIDsArray = res.map(x => new UUID(x.id));
      const imagesArray = imagesUUIDsArray ? [...imagesUUIDsArray] : [];
      return imagesArray;
    })
    .catch(e => {
      console.log(e);
      return [];
    });
};

const listingInfoToCreateListingObj = (listing, images) => {
  const latitude = listing.attributes.geolocation?.lat;
  const longitude = listing.attributes.geolocation?.lng;

  const geolocationMaybe =
    latitude && longitude ? { geolocation: new LatLng(latitude, longitude) } : {};
  const createListingObject = {
    title: listing.attributes.title,
    description: listing.attributes.description,
    availabilityPlan: listing.attributes.availabilityPlan,
    ...geolocationMaybe,
    privateData: { ...listing.attributes.privateData },
    publicData: { ...listing.attributes.publicData },
    price: new Money(listing.attributes.price.amount, listing.attributes.price.currency),
    images,
  };

  return createListingObject;
};

export const duplicateListing = listing => {
  if (!listing?.id?.uuid) {
    return null;
  }
  const listingId = listing.id.uuid;
  return getListingInfo(listingId)
    .then(resp => {
      const listingInfo = resp;
      const listingImages = listingInfo.attributes.images || [];
      const listingImagesUrls = listingImages.map(img => img?.attributes?.variants?.default?.url);

      return imagesUrlsToStUploadReady(listingImagesUrls)
        .then(res => {
          const images = res;
          const createListingObj = listingInfoToCreateListingObj(listing, images);

          return sdk.ownListings
            .create({
              ...createListingObj,
            })
            .then(resp => {
              //TODO reload page data instead, to avoid refresh
              if (typeof window !== 'undefined') {
                window.location.reload();
              }
            })
            .catch(e => {
              throw e;
            });
        })
        .catch(e => {
          throw e;
        });
    })
    .catch(e => {
      console.log(e);
    });
};
