import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { propTypes, LINE_ITEM_PROVIDER_COMMISSION } from '../../util/types';

import { Stack, Typography } from '@mui/material';

const LineItemProviderCommissionRefundMaybe = props => {
  const { transaction, isProvider, intl } = props;

  const refund = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_PROVIDER_COMMISSION && item.reversal
  );

  return isProvider && refund ? (
    <Stack direction='row' justifyContent="space-between">
      <Typography variant='subtitle1'>
        <FormattedMessage id="BookingBreakdown.refundProviderFee" />
      </Typography>
      <Typography variant='subtitle1'>{formatMoney(intl, refund.lineTotal)}</Typography>
    </Stack >
  ) : null;
};

LineItemProviderCommissionRefundMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemProviderCommissionRefundMaybe;
