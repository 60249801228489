import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import config from '../../config';
import { findOptionsForSelectFilter } from '../../util/search';
import {
  autocompleteSearchRequired,
  autocompletePlaceSelected,
  composeValidators,
} from '../../util/validators';
import * as validators from '../../util/validators';
import {
  Form,
  LocationAutocompleteInputField,
  Button,
  FieldTextInput,
  FieldSelect,
} from '../../components';

import css from './EditListingLocationForm.module.css';

const identity = v => v;

export const EditListingLocationFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values,
        filterConfig,
      } = formRenderProps;

      const required = validators.required(
        intl.formatMessage({
          id: 'EditListingFeaturesForm.required',
        })
      );
      const titleRequiredMessage = intl.formatMessage({ id: 'EditListingLocationForm.address' });

      const addressPlaceholderMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressPlaceholder',
      });
      const addressRequiredMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressRequired',
      });
      const addressNotRecognizedMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressNotRecognized',
      });

      const optionalText = intl.formatMessage({
        id: 'EditListingLocationForm.optionalText',
      });

      const buildingMessage = intl.formatMessage(
        { id: 'EditListingLocationForm.building' },
        { optionalText: optionalText }
      );
      const buildingPlaceholderMessage = intl.formatMessage({
        id: 'EditListingLocationForm.buildingPlaceholder',
      });

      //pick up info
      const pickUpMessage = intl.formatMessage({ id: 'EditListingLocationForm.pickUp' });

      const areaMessage = intl.formatMessage({ id: 'EditListingLocationForm.area' });

      const experiencePickupOptions = findOptionsForSelectFilter('experiencePickup', filterConfig);

      const experienceAreaOptions = findOptionsForSelectFilter('prefectures', filterConfig);

      //nearest station
      const nearestStationMessage = intl.formatMessage({
        id: 'EditListingLocationForm.nearestStation',
      });
      const nearestStationPlaceholderMessage = intl.formatMessage({
        id: 'EditListingLocationForm.nearestStationPlaceholder',
      });

      //meetingPoint
      const meetingPointMessage = intl.formatMessage({
        id: 'EditListingLocationForm.meetingPoint',
      });
      const meetingPointPlaceholderMessage = intl.formatMessage({
        id: 'EditListingLocationForm.meetingPointPlaceholder',
      });

      //access info
      const accessInfoMessage = intl.formatMessage({
        id: 'EditListingLocationForm.accessInfo',
      });
      const accessInfoPlaceholderMessage = intl.formatMessage({
        id: 'EditListingLocationForm.accessInfoPlaceholder',
      });

      //distance by car
      const distanceByCarMessage = intl.formatMessage({
        id: 'EditListingLocationForm.distanceByCar',
      });
      const distanceByCarPlaceholderMessage = intl.formatMessage({
        id: 'EditListingLocationForm.distanceByCarPlaceholder',
      });

      const choose = intl.formatMessage({
        id: 'EditListingLocationForm.choose',
      });

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingLocationForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingLocationForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const makeOption = x => (
        <option value={x.key} key={x.key}>
          {intl.formatMessage({ id: x.id })}
        </option>
      );

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}

          <FieldSelect id="area" name="area" label={areaMessage} validate={required}>
            <option value="" disabled hidden>
              {choose}
            </option>
            {experienceAreaOptions.map(makeOption)}
          </FieldSelect>

          <FieldTextInput
            className={css.building}
            type="textarea"
            name="accessInfo"
            id="accessInfo"
            label={accessInfoMessage}
            placeholder={accessInfoPlaceholderMessage}
            validate={required}
          />
          <FieldSelect id="pickUp" name="pickUp" label={pickUpMessage} validate={required}>
            <option value="" disabled hidden>
              {choose}
            </option>
            {experiencePickupOptions.map(makeOption)}
          </FieldSelect>

          <FieldTextInput
            className={css.building}
            type="text"
            name="nearestStation"
            id="nearestStation"
            label={nearestStationMessage}
            placeholder={nearestStationPlaceholderMessage}
            validate={required}
          />

          <FieldTextInput
            className={css.building}
            type="text"
            name="distanceByCar"
            id="distanceByCar"
            label={distanceByCarMessage}
            placeholder={distanceByCarPlaceholderMessage}
            validate={required}
          />

          <FieldTextInput
            className={css.building}
            type="text"
            name="meetingPoint"
            id="meetingPoint"
            label={meetingPointMessage}
            placeholder={meetingPointPlaceholderMessage}
            validate={required}
          />

          <LocationAutocompleteInputField
            className={css.locationAddress}
            inputClassName={css.locationAutocompleteInput}
            iconClassName={css.locationAutocompleteInputIcon}
            predictionsClassName={css.predictionsRoot}
            validClassName={css.validLocation}
            autoFocus
            name="location"
            label={titleRequiredMessage}
            placeholder={addressPlaceholderMessage}
            useDefaultPredictions={false}
            format={identity}
            valueFromForm={values.location}
            validate={required}
          />

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingLocationFormComponent.defaultProps = {
  selectedPlace: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingLocationFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

export default compose(injectIntl)(EditListingLocationFormComponent);
