import React, { Component, useState } from 'react';
import { bool, string } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Field, Form as FinalForm } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { isUploadImageOverLimitError } from '../../util/errors';
import { Form, Avatar, Button, ImageFromFile, IconSpinner, FieldTextInput } from '../../components';
import arrayMutators from 'final-form-arrays';
import Grid from '@mui/material/Grid';
import CloseIcon from '@mui/icons-material/Close';
import { FieldArray } from 'react-final-form-arrays';
import AddIcon from '@mui/icons-material/Add';
import { MultipleImagesUploader } from './MultipleImagesUploader';
import css from './ProfileSettingsForm.module.css';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const ACCEPT_IMAGES = 'image/*';
const UPLOAD_CHANGE_DELAY = 2000; // Show spinner so that browser has time to load img srcset

class ProfileSettingsFormComponent extends Component {
  constructor(props) {
    super(props);

    this.uploadDelayTimeoutId = null;
    this.state = {
      uploadDelay: false,
      enSwitchChecked: props.enSwitchChecked ? props.enSwitchChecked : false,
    };
    this.submittedValues = {};
  }

  componentDidUpdate(prevProps) {
    // Upload delay is additional time window where Avatar is added to the DOM,
    // but not yet visible (time to load image URL from srcset)
    if (prevProps.uploadInProgress && !this.props.uploadInProgress) {
      this.setState({ uploadDelay: true });
      this.uploadDelayTimeoutId = window.setTimeout(() => {
        this.setState({ uploadDelay: false });
      }, UPLOAD_CHANGE_DELAY);
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this.uploadDelayTimeoutId);
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        mutators={{ ...arrayMutators }}
        render={fieldRenderProps => {
          const {
            className,
            currentUser,
            handleSubmit,
            intl,
            invalid,
            onImageUpload,
            pristine,
            profileImage,
            rootClassName,
            updateInProgress,
            updateProfileError,
            uploadImageError,
            uploadInProgress,
            form,
            form: {
              mutators: { push },
            },
            values,
            images,
            setImages,
            savedImages,
            setSavedImages,
          } = fieldRenderProps;

          const user = ensureCurrentUser(currentUser);

          // First name
          const firstNameLabel = intl.formatMessage({
            id: 'ProfileSettingsForm.firstNameLabel',
          });
          const firstNamePlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.firstNamePlaceholder',
          });
          const firstNameRequiredMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.firstNameRequired',
          });
          const required = validators.required(
            intl.formatMessage({
              id: 'EditListingFeaturesForm.required',
            })
          );

          // Last name
          const lastNameLabel = intl.formatMessage({
            id: 'ProfileSettingsForm.lastNameLabel',
          });
          const lastNamePlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.lastNamePlaceholder',
          });

          // pronounciation name
          const namePronunciationLabel = intl.formatMessage({
            id: 'ProfileSettingsForm.namePronunciationLabel',
          });
          const namePronunciationPlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.namePronunciationPlaceholder',
          });

          // Bio
          const bioLabel = intl.formatMessage({
            id: 'ProfileSettingsForm.bioLabel',
          });
          const bioPlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.bioPlaceholder',
          });

          // profile header
          const headerLabel = intl.formatMessage({
            id: 'ProfileSettingsForm.headerLabel',
          });
          const headerPlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.headerPlaceholder',
          });

          //message
          const messageLabel = intl.formatMessage({
            id: 'ProfileSettingsForm.messageLabel',
          });
          const messagePlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.messagePlaceholder',
          });

          //highlights
          const highlightsLabel = intl.formatMessage({
            id: 'ProfileSettingsForm.highlightsLabel',
          });
          const highlightsPlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.highlightsPlaceholder',
          });

          //instagram
          const instaLabel = intl.formatMessage({
            id: 'ProfileSettingsForm.instaLabel',
          });

          const instaPlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.instaPlaceholder',
          });

          //facebook
          const fbLabel = intl.formatMessage({
            id: 'ProfileSettingsForm.fbLabel',
          });

          const fbPlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.fbPlaceholder',
          });

          //twitter
          const twitterLabel = intl.formatMessage({
            id: 'ProfileSettingsForm.twitterLabel',
          });

          const twitterPlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.twitterPlaceholder',
          });

          //youtube
          const validYoutubeURL = validators.validYoutubeURL('Valid Youtube vide url required');

          const youtubeLinkLabel = intl.formatMessage({
            id: 'ProfileSettingsForm.youtubeLinkLabel',
          });

          const youtubeLinkPlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.youtubeLinkPlaceholder',
          });

          //website

          const uploadingOverlay =
            uploadInProgress || this.state.uploadDelay ? (
              <div className={css.uploadingImageOverlay}>
                <IconSpinner />
              </div>
            ) : null;

          const hasUploadError = !!uploadImageError && !uploadInProgress;
          const errorClasses = classNames({ [css.avatarUploadError]: hasUploadError });
          const transientUserProfileImage = profileImage.uploadedImage || user.profileImage;
          const transientUser = { ...user, profileImage: transientUserProfileImage };

          // Ensure that file exists if imageFromFile is used
          const fileExists = !!profileImage.file;
          const fileUploadInProgress = uploadInProgress && fileExists;
          const delayAfterUpload = profileImage.imageId && this.state.uploadDelay;
          const imageFromFile =
            fileExists && (fileUploadInProgress || delayAfterUpload) ? (
              <ImageFromFile
                id={profileImage.id}
                className={errorClasses}
                rootClassName={css.uploadingImage}
                aspectRatioClassName={css.squareAspectRatio}
                file={profileImage.file}
              >
                {uploadingOverlay}
              </ImageFromFile>
            ) : null;

          // Avatar is rendered in hidden during the upload delay
          // Upload delay smoothes image change process:
          // responsive img has time to load srcset stuff before it is shown to user.
          const avatarClasses = classNames(errorClasses, css.avatar, {
            [css.avatarInvisible]: this.state.uploadDelay,
          });
          const avatarComponent =
            !fileUploadInProgress && profileImage.imageId ? (
              <Avatar
                className={avatarClasses}
                renderSizes="(max-width: 767px) 96px, 240px"
                user={transientUser}
                disableProfileLink
              />
            ) : null;

          const chooseAvatarLabel =
            profileImage.imageId || fileUploadInProgress ? (
              <div className={css.avatarContainer}>
                {imageFromFile}
                {avatarComponent}
                <div className={css.changeAvatar}>
                  <FormattedMessage id="ProfileSettingsForm.changeAvatar" />
                </div>
              </div>
            ) : (
              <div className={css.avatarPlaceholder}>
                <div className={css.avatarPlaceholderText}>
                  <FormattedMessage id="ProfileSettingsForm.addYourProfilePicture" />
                </div>
                <div className={css.avatarPlaceholderTextMobile}>
                  <FormattedMessage id="ProfileSettingsForm.addYourProfilePictureMobile" />
                </div>
              </div>
            );

          const submitError = updateProfileError ? (
            <div className={css.error}>
              <FormattedMessage id="ProfileSettingsForm.updateProfileFailed" />
            </div>
          ) : null;

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = updateInProgress;
          const submittedOnce = Object.keys(this.submittedValues).length > 0;
          const pristineSinceLastSubmit = submittedOnce && isEqual(values, this.submittedValues);
          const submitDisabled = invalid || uploadInProgress || submitInProgress;
          //invalid || pristine || pristineSinceLastSubmit || uploadInProgress || submitInProgress;
          //
          const handleChange = () => {
            this.setState({ enSwitchChecked: !this.state.enSwitchChecked });
          };

          const QA = fieldName => {
            return (
              <>
                <div className={css.questionFieldsLabel}>
                  <FormattedMessage id="ProfileSettingsForm.qaLabel" />
                  <AddIcon className={css.addButton} onClick={() => push(fieldName, undefined)} />
                </div>

                <div className={css.questionFieldsWrapper}>
                  <FieldArray name={fieldName}>
                    {({ fields }) =>
                      fields.map((name, index) => (
                        <div className={css.questionFieldWrapper} key={name}>
                          <div
                            onClick={() => fields.remove(index)}
                            className={css.closeIconWrapper}
                          >
                            <CloseIcon style={{ color: '#827D8D' }} />
                          </div>
                          <label className={css.quationItemLabel}>
                            <FormattedMessage id="ProfileSettingsForm.questionLabel" /> {index + 1}
                          </label>
                          <Field
                            name={`${name}.question`}
                            className={css.questionField}
                            component="input"
                            placeholder={intl.formatMessage({
                              id: 'ProfileSettingsForm.questionPlaceholder',
                            })}
                          />
                          <label className={css.quationItemLabel}>
                            <FormattedMessage id="ProfileSettingsForm.answerLabel" /> {index + 1}
                          </label>
                          <Field
                            name={`${name}.answer`}
                            className={css.questionField}
                            component="input"
                            placeholder={intl.formatMessage({
                              id: 'ProfileSettingsForm.answerPlaceholder',
                            })}
                          />
                        </div>
                      ))
                    }
                  </FieldArray>
                </div>
              </>
            );
          };

          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedValues = values;
                handleSubmit(e);
              }}
            >
              <div className={css.sectionContainer}>
                <h3 className={css.sectionTitle}>
                  <FormattedMessage id="ProfileSettingsForm.yourProfilePicture" />
                </h3>
                <Field
                  accept={ACCEPT_IMAGES}
                  id="profileImage"
                  name="profileImage"
                  label={chooseAvatarLabel}
                  type="file"
                  form={null}
                  uploadImageError={uploadImageError}
                  disabled={uploadInProgress}
                >
                  {fieldProps => {
                    const { accept, id, input, label, disabled, uploadImageError } = fieldProps;
                    const { name, type } = input;
                    const onChange = e => {
                      const file = e.target.files[0];
                      form.change(`profileImage`, file);
                      form.blur(`profileImage`);
                      if (file != null) {
                        const tempId = `${file.name}_${Date.now()}`;
                        onImageUpload({ id: tempId, file });
                      }
                    };

                    let error = null;

                    if (isUploadImageOverLimitError(uploadImageError)) {
                      error = (
                        <div className={css.error}>
                          <FormattedMessage id="ProfileSettingsForm.imageUploadFailedFileTooLarge" />
                        </div>
                      );
                    } else if (uploadImageError) {
                      error = (
                        <div className={css.error}>
                          <FormattedMessage id="ProfileSettingsForm.imageUploadFailed" />
                        </div>
                      );
                    }

                    return (
                      <div className={css.uploadAvatarWrapper}>
                        <label className={css.label} htmlFor={id}>
                          {label}
                        </label>
                        <input
                          accept={accept}
                          id={id}
                          name={name}
                          className={css.uploadAvatarInput}
                          disabled={disabled}
                          onChange={onChange}
                          type={type}
                        />
                        {error}
                      </div>
                    );
                  }}
                </Field>
                {/* <div className={css.tip}>
                  <FormattedMessage id="ProfileSettingsForm.tip" />
                </div> */}
                <div className={css.fileInfo}>
                  <FormattedMessage id="ProfileSettingsForm.fileInfo" />
                </div>

                {values.profileImage && (
                  <div className={css.extraPhotosWrapper}>
                    <MultipleImagesUploader
                      maxNumber={20}
                      label={<FormattedMessage id="ProfileSettingsForm.extraPhotosTitle" />}
                      underLabel={false}
                      // handleUploadPhotos={handleUploadPhotos}
                      // metadataKey={'beforePhotos'}
                      images={images}
                      setImages={setImages}
                      savedImages={savedImages}
                      setSavedImages={setSavedImages}
                    />
                  </div>
                )}
              </div>
              <div className={css.sectionContainer}>
                <h3 className={css.sectionTitle}>
                  <FormattedMessage id="ProfileSettingsForm.yourName" />
                </h3>
                <div className={css.nameContainer}>
                  <FieldTextInput
                    className={css.firstName}
                    type="text"
                    id="firstName"
                    name="firstName"
                    label={firstNameLabel}
                    placeholder={firstNamePlaceholder}
                    validate={required}
                  />
                  <FieldTextInput
                    className={css.lastName}
                    type="text"
                    id="lastName"
                    name="lastName"
                    label={lastNameLabel}
                    placeholder={lastNamePlaceholder}
                    validate={required}
                  />
                </div>
                <FieldTextInput
                  type="text"
                  id="namePronunciation"
                  name="namePronunciation"
                  label={namePronunciationLabel}
                  placeholder={namePronunciationPlaceholder}
                />
                <FieldTextInput
                  type="textarea"
                  id="profileHeader"
                  name="profileHeader"
                  label={headerLabel}
                  placeholder={headerPlaceholder}
                  className={css.headerField}
                  validate={required}
                />
              </div>
              <div className={classNames(css.sectionContainer, css.lastSection)}>
                <h3 className={css.sectionTitle}>
                  <FormattedMessage id="ProfileSettingsForm.bioHeading" />
                </h3>
                <FieldTextInput
                  type="textarea"
                  id="bio"
                  name="bio"
                  label={bioLabel}
                  placeholder={bioPlaceholder}
                  validate={required}
                />

                <FieldTextInput
                  type="url"
                  id="youtubeLink"
                  name="youtubeLink"
                  label={youtubeLinkLabel}
                  placeholder={youtubeLinkPlaceholder}
                  className={css.infoField}
                  validate={validYoutubeURL}
                />

                <FieldTextInput
                  type="textarea"
                  id="message"
                  name="message"
                  label={messageLabel}
                  placeholder={messagePlaceholder}
                  className={css.infoField}
                  validate={required}
                />

                <FieldTextInput
                  type="textarea"
                  id="highlights"
                  name="highlights"
                  label={highlightsLabel}
                  placeholder={highlightsPlaceholder}
                  className={css.infoField}
                  validate={required}
                />

                <FieldTextInput
                  type="text"
                  id="instagram"
                  name="instagram"
                  label={instaLabel}
                  placeholder={instaPlaceholder}
                  className={css.infoField}
                />

                <FieldTextInput
                  type="text"
                  id="facebook"
                  name="facebook"
                  label={fbLabel}
                  placeholder={fbPlaceholder}
                  className={css.infoField}
                />

                <FieldTextInput
                  type="text"
                  id="twitter"
                  name="twitter"
                  label={twitterLabel}
                  placeholder={twitterPlaceholder}
                  className={css.infoField}
                />

                {QA('qa')}
              </div>

              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.enSwitchChecked}
                      onChange={handleChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label="Show English translations"
                />
              </FormGroup>

              {this.state.enSwitchChecked ? (
                <>
                  <FieldTextInput
                    type="textarea"
                    id="profileHeaderEN"
                    name="profileHeaderEN"
                    label={headerLabel}
                    placeholder={headerPlaceholder}
                  />

                  <FieldTextInput
                    type="textarea"
                    id="bioEN"
                    name="bioEN"
                    label={bioLabel}
                    placeholder={bioPlaceholder}
                  />
                  <FieldTextInput
                    type="textarea"
                    id="messageEN"
                    name="messageEN"
                    label={messageLabel}
                    placeholder={messagePlaceholder}
                  />
                  <FieldTextInput
                    type="textarea"
                    id="highlightsEN"
                    name="highlightsEN"
                    label={highlightsLabel}
                    placeholder={highlightsPlaceholder}
                  />
                  
                  {QA('qaEN')}
                </>
              ) : null}

              {submitError}
              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={pristineSinceLastSubmit}
              >
                <FormattedMessage id="ProfileSettingsForm.saveChanges" />
              </Button>
            </Form>
          );
        }}
      />
    );
  }
}

ProfileSettingsFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  uploadImageError: null,
  updateProfileError: null,
  updateProfileReady: false,
};

ProfileSettingsFormComponent.propTypes = {
  rootClassName: string,
  className: string,

  uploadImageError: propTypes.error,
  uploadInProgress: bool.isRequired,
  updateInProgress: bool.isRequired,
  updateProfileError: propTypes.error,
  updateProfileReady: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ProfileSettingsForm = compose(injectIntl)(ProfileSettingsFormComponent);

ProfileSettingsForm.displayName = 'ProfileSettingsForm';

export default ProfileSettingsForm;
