import React from 'react';
import classNames from 'classnames';
import { Avatar, ResponsiveImage, NamedLink } from '../../components';

import { createSlug } from '../../util/urlHelpers';
import css from './TransactionPanel.module.css';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// Functional component as a helper to build AddressLinkMaybe
const DetailCardExperience = props => {
  const {
    className,
    rootClassName,
    avatarWrapperClassName,
    listingTitle,
    image,
    provider,
    isCustomer,
    transaction,
  } = props;
  const listing = transaction && transaction.listing;
  const slug = createSlug(listingTitle);
  const listingId = listing ? listing.id.uuid : draftId;

  return (
    <Grid p={0} container columnSpacing={1}>
      <Grid item pc={7} mobile={6}>
        <NamedLink name="ListingPage" params={{ id: listingId, slug }}>
          <Typography variant="subtitle1">{listingTitle}</Typography>
          <Typography variant="caption2">by {provider.attributes.profile.displayName}</Typography>
        </NamedLink>
      </Grid>
      <Grid item container pc={5} mobile={6}>
        <ResponsiveImage
          rootClassName={css.detailCardImage}
          alt={listingTitle}
          image={image}
          variants={['landscape-crop', 'landscape-crop2x']}
        />
      </Grid>
    </Grid>
  );
};

export default DetailCardExperience;
