import React, { useRef } from 'react';
import useIntersectionObserver from '@react-hook/intersection-observer';

const VideoEmbed = props => {
  const { videoUrl, ...rest } = props;

  const containerRef = useRef();
  const { isIntersecting } = useIntersectionObserver(containerRef);
  const lockRef = useRef(false);
  if (isIntersecting && !lockRef.current) {
    lockRef.current = true;
  }

  try {
    const youtubeUrl = new URL(videoUrl);

    let videoId = youtubeUrl.searchParams.get('v');
    //short url or long url
    if (!videoId) {
      videoId = youtubeUrl.pathname.slice(1);
    }
    const embedURL = `https://www.youtube.com/embed/${videoId}`;
    return (
      <div {...rest} ref={containerRef}>
        {lockRef.current && (
          <iframe
            width="100%"
            height="100%"
            src={embedURL}
            title="My latest video"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        )}
      </div>
    );
  } catch (e) {
    console.log(e);
    return null;
  }
};

export default VideoEmbed;
