import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Row, Col } from 'react-bootstrap';
import { SignUpButton } from '../../containers/LandingPages/Util'
import '../../containers/LandingPages/kammuiplus.scss';


const SectionBanner = () => {
  return (

    <div className="section-banner top-kammuiplus-join-wrapper">
    <Row className="inner justify-content-center">
      <Col lg={7} md={7} sm={12} xs={12}>
           <h3>
        <FormattedMessage id='KammuiPlusLandingPage.sectionComingSoon'/>
        </h3>
        {/* <h2>
          <FormattedMessage id="KammuiPlusLandingPage.sectionComingSoonText1" />
        </h2> */}
        <p>
          <FormattedMessage id="KammuiPlusLandingPage.sectionComingSoonText3.part1" />
          {/* <strong>
            <FormattedMessage id="KammuiPlusLandingPage.sectionComingSoonText3.boldWord" />
          </strong>
          <FormattedMessage id="KammuiPlusLandingPage.sectionComingSoonText3.part2" />
        </p>
        <p>
          <FormattedMessage id="KammuiPlusLandingPage.sectionComingSoonText3" /> */}
        </p>


        <SignUpButton />
      </Col>
    </Row>
  </div>
  );
};

export default SectionBanner;
