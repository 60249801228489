/**
 * Export loadData calls from ducks modules of different containers
 */
import { setInitialValues as CheckoutPageInitialValues } from './CheckoutPage/CheckoutPage.duck';
import { loadData as ContactDetailsPageLoader } from './ContactDetailsPage/ContactDetailsPage.duck';
import { loadData as SubscriptionsPageLoader } from './SubscriptionsPage/SubscriptionsPage.duck';
import { loadData as EditListingPageLoader } from './EditListingPage/EditListingPage.duck';
import { loadData as EmailVerificationPageLoader } from './EmailVerificationPage/EmailVerificationPage.duck';
import { loadData as InboxPageLoader } from './InboxPage/InboxPage.duck';
import { loadData as ListingPageLoader } from './ListingPage/ListingPage.duck';
import { loadData as ManageListingsPageLoader } from './ManageListingsPage/ManageListingsPage.duck';
import { loadData as PaymentMethodsPageLoader } from './PaymentMethodsPage/PaymentMethodsPage.duck';
import { loadData as ProfilePageLoader } from './ProfilePage/ProfilePage.duck';
import { loadData as SearchPageLoader } from './SearchPage/SearchPage.duck';
import { loadData as MapPageLoader } from './MapPage/MapPage.duck';
import { loadData as StripePayoutPageLoader } from './StripePayoutPage/StripePayoutPage.duck';
import { loadData as GuidesPageLoader } from './GuidesPage/GuidesPage.duck';
import { loadData as ExperiencesPageLoader } from './ExperiencesPage/ExperiencesPage.duck';
import { loadData as StylesPageLoader } from './StylesPage/StylesPage.duck';
import { loadData as AreasPageLoader } from './AreasPage/AreasPage.duck';
import { loadData as KammuiPlusLandingPageLoader } from './LandingPages/KammuiPlusLandingPage.duck';
import { loadData as WinterLandingPageLoader } from './LandingPages/LandingPageWinter.duck';
import { loadData as LandingPageLoader } from './LandingPage/LandingPage.duck';
import { loadData as FavListingsPageLoader } from './FavListingsPage/FavListingsPage.duck';

import {
  loadData as TransactionPageLoader,
  setInitialValues as TransactionPageInitialValues,
} from './TransactionPage/TransactionPage.duck';

const getPageDataLoadingAPI = () => {
  return {
    CheckoutPage: {
      setInitialValues: CheckoutPageInitialValues,
    },
    ContactDetailsPage: {
      loadData: ContactDetailsPageLoader,
    },
    SubscriptionsPage: {
      loadData: SubscriptionsPageLoader,
    },
    EditListingPage: {
      loadData: EditListingPageLoader,
    },
    EmailVerificationPage: {
      loadData: EmailVerificationPageLoader,
    },
    GuidesPage: {
      loadData: GuidesPageLoader,
    },
    ExperiencesPage: {
      loadData: ExperiencesPageLoader,
    },
    StylesPage: {
      loadData: StylesPageLoader,
    },
    AreasPage: {
      loadData: AreasPageLoader,
    },
    InboxPage: {
      loadData: InboxPageLoader,
    },

    KammuiPlusLandingPage: {
      loadData: KammuiPlusLandingPageLoader,
    },
    // WinterLandingPage: {
    WinterLandingPage: {
      loadData: WinterLandingPageLoader,
    },
    LandingPage: {
      loadData: LandingPageLoader,
    },
    ListingPage: {
      loadData: ListingPageLoader,
    },
    ManageListingsPage: {
      loadData: ManageListingsPageLoader,
    },
    PaymentMethodsPage: {
      loadData: PaymentMethodsPageLoader,
    },
    ProfilePage: {
      loadData: ProfilePageLoader,
    },
    SearchPage: {
      loadData: SearchPageLoader,
    },
    StripePayoutPage: {
      loadData: StripePayoutPageLoader,
    },
    TransactionPage: {
      loadData: TransactionPageLoader,
      setInitialValues: TransactionPageInitialValues,
    },
    FavListingsPage: {
      loadData: FavListingsPageLoader,
    },
    MapPage: {
      loadData: MapPageLoader,
    },

  };
};

export default getPageDataLoadingAPI;
