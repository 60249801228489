const BASE_URL = process.env.REACT_APP_IMGIX_URL
const formatQueryLarge = '?q=100&auto=compress,format&w=1200&h=800&fit=crop&crop=focalpoint&bm=normal'
const formatQueryThumb = '?q=100&auto=compress,format&w=300&h=300&fit=crop&crop=focalpoint&bm=normal'

const reFname = /.*\/(.+)$/

function getBase(url) {
  const fname = url.match(reFname)[1]
  return `${BASE_URL}/${fname}`
}

export function toImgixLarge(url) {
  if (url == null) return ''
  return `${getBase(url)}${formatQueryLarge}`
}

export function toImgixThumbnail(url) {
  if (url == null) return ''
  return `${getBase(url)}${formatQueryThumb}`
}
