import React from 'react';
import { arrayOf, bool, number, oneOf, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Grid, Card, CardContent, CardActionArea, Chip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  txIsAccepted,
  txIsCanceled,
  txIsDeclined,
  txIsEnquired,
  txIsRequested,
  txHasBeenDelivered,
  txIsPaymentExpired,
  txIsPaymentPending,
} from '../../util/transaction';
import { propTypes, DATE_TYPE_DATE } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  Avatar,
  BookingTimeInfo,
  NamedLink,
  NotificationBadge,
  Page,
  PaginationLinks,
  TabNav,
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  IconSpinner,
  UserDisplayName,
} from '../../components';
import { TopbarContainer, NotFoundPage } from '../../containers';
import config from '../../config';

import css from './InboxPage.module.css';

const formatDate = (intl, date) => {
  return {
    short: intl.formatDate(date, {
      month: 'short',
      day: 'numeric',
    }),
    long: `${intl.formatDate(date)} ${intl.formatTime(date)}`,
  };
};

//to customize Chip just for Status display
export const StatusChip = styled(Chip)(({ theme }) => ({
  height: '20px',
  margin: '0',
  padding: '0',
  borderRadius: '4px',
  fontSize: '12px',
  fontWeight: '300',
  color: '#505050',
  backgroundColor: '#fff',
  lineHeight: '100%',

  '& .MuiChip-label': {
    margin: '0',
    padding: '0',
    fontSize: '12px',
    fontWeight: '300',
    lineHeight: '100%'
  },
}));

// Translated name of the state of the given transaction
export const txState = (intl, tx, type) => {
  const isOrder = type === 'order';

  if (txIsEnquired(tx)) {
    //"ENQUIRY"
    return {
      //Specific style for Inquiry chip
      sx: {
        height: '28px',

        '& .MuiChip-label': {
          padding: '6px',
          color: '#fff',
          fontWeight: '600',
          borderRadius: '6px',
          backgroundColor: '#FAB504'
        }
      },
      state: intl.formatMessage({
        id: 'InboxPage.stateEnquiry',
      }),
    };
  } else if (txIsRequested(tx) || txIsPaymentPending(tx)) {
    //"PENDING"
    return {
      sx: {
        height: '28px',
        '& .MuiChip-label': {
          padding: '6px',
          color: '#fff',
          fontWeight: '600',
          borderRadius: '6px',
          backgroundColor: '#FAB504'
        }
      },
      state: intl.formatMessage({
        id: 'InboxPage.statePending',
      }),
    };
  } else if (txIsDeclined(tx) || txIsPaymentExpired(tx)) {
    //DECLINED
    return {
      sx: {
        height: '28px',
        '& .MuiChip-label': {
          padding: '6px',
          color: '#fff',
          fontWeight: '600',
          borderRadius: '6px',
          backgroundColor: '#EA4335'
        }
      },
      state: intl.formatMessage({
        id: 'InboxPage.stateDeclined',
      }),
    };
  } else if (txIsAccepted(tx)) {
    //CONFIRMED
    return {
      sx: {
        height: '28px',
        '& .MuiChip-label': {
          padding: '6px',
          color: '#fff',
          fontWeight: '600',
          borderRadius: '6px',
          backgroundColor: '#0DDA21'
        }
      },
      state: intl.formatMessage({
        id: 'InboxPage.stateConfirmed',
      }),
    };
  } else if (txIsCanceled(tx)) {
    //CANCELLED
    return {
      sx: {
        height: '28px',
        '& .MuiChip-label': {
          padding: '6px',
          color: '#fff',
          fontWeight: '600',
          borderRadius: '6px',
          backgroundColor: '#EA4335'
        }
      },
      state: intl.formatMessage({
        id: 'InboxPage.stateCanceled',
      }),
    };
  } else if (txHasBeenDelivered(tx)) {
    //COMPLETED
    return {
      height: '12px',
      borderRadius: '0',
      sx: {
        '& .MuiChip-label': {
          height: '12px',
          color: '#505050',
          fontWeight: '300',
        }
      },
      state: intl.formatMessage({
        id: 'InboxPage.stateDelivered',
      }),
    };
  } else {
    console.warn('This transition is unknown:', tx.attributes.lastTransition);
    return null;
  }
};

// Functional component as internal helper to print BookingTimeInfo if that is needed
const BookingInfoMaybe = props => {
  const { bookingClassName, isOrder, intl, tx, unitType } = props;
  const isEnquiry = txIsEnquired(tx);

  if (isEnquiry) {
    return null;
  }

  // If you want to show the booking price after the booking time on InboxPage you can
  // add the price after the BookingTimeInfo component. You can get the price by uncommenting
  // sthe following lines:

  // const bookingPrice = isOrder ? tx.attributes.payinTotal : tx.attributes.payoutTotal;
  // const price = bookingPrice ? formatMoney(intl, bookingPrice) : null;

  // Remember to also add formatMoney function from 'util/currency.js' and add this after BookingTimeInfo:
  // <div className={css.itemPrice}>{price}</div>

  return (
    <div>
      <p className={css.cardLabel}><FormattedMessage id="InboxPage.dates" /></p>
      <BookingTimeInfo
        isOrder={isOrder}
        intl={intl}
        tx={tx}
        unitType={unitType}
        dateType={DATE_TYPE_DATE}
      />
    </div>
  );
};

BookingInfoMaybe.propTypes = {
  intl: intlShape.isRequired,
  isOrder: bool.isRequired,
  tx: propTypes.transaction.isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
};

export const InboxItem = props => {
  const { currentUser, unitType, type, tx, intl, stateData } = props;

  const { customer, provider, listing, messages } = tx;

  const isOrder = type === 'order';

  const otherUser = isOrder ? provider : customer;
  const otherUserDisplayName = <UserDisplayName user={otherUser} intl={intl} />;
  const isOtherUserBanned = otherUser.attributes.banned;

  const isSaleNotification = !isOrder && txIsRequested(tx);
  const lastTransitionedAt = formatDate(intl, tx.attributes.lastTransitionedAt);

  const lastMessage =
    tx.messages && tx.messages.length > 0 ? tx.messages.pop().attributes.content : null;

  const contactNameLabel = isOrder ? (
    <FormattedMessage id="InboxPage.fromLabelGuide" />
  ) : (
    <FormattedMessage id="InboxPage.fromLabelCustomer" />
  );

  return (
    <Grid key={tx.id.uuid} item pc={12} mobile={12} sx={{ marginLeft: { mobile: "30px" } }}>
      <Card className={css.cardRoot}>
        <CardActionArea>
          <CardContent>
            <NamedLink
              className={css.cardLink}
              name={isOrder ? 'OrderDetailsPage' : 'SaleDetailsPage'}
              params={{ id: tx.id.uuid }}
            >
              <Grid container >
                <Grid item pc={9} mobile={8} className={css.cardLeftPane}>
                  <div className={css.cardLabelLine}>
                    <p className={css.cardLabel}>{contactNameLabel}</p>
                    <p className={css.customerName}>{otherUserDisplayName}</p>
                  </div>
                  {listing ?
                    <div className={css.cardLabelLine}>
                      <p className={css.cardLabel}><FormattedMessage id="InboxPage.experienceName" /></p>
                      <p className={css.listingTitle}>{listing.attributes.title}</p>
                    </div> : null
                  }
                  <div className={css.cardBookingTimeInfo}>
                    <BookingInfoMaybe intl={intl} isOrder={isOrder} tx={tx} unitType={unitType} />
                  </div>
                  {lastMessage ?
                    <div className={css.cardLabelLine}>
                      <p className={css.messageShortText}>
                        {lastMessage}
                      </p>
                    </div> : null
                  }
                </Grid>

                <Grid item pc={3} mobile={4} className={css.cardRightPane}>
                  <Grid container>
                    <Grid item pc={12} mobile={12}>
                      <div className={css.cardLabelLine}>
                        <p className={css.cardLabel}><FormattedMessage id="InboxPage.status" /></p>
                        <div className={css.chipWrapper}>
                          <StatusChip
                            label={stateData.state}
                            sx={stateData.sx}
                          />
                        </div>
                      </div>
                    </Grid>
                    <Grid item pc={12} mobile={12}>
                      <div className={css.lastContact}>
                        <p className={css.cardLabel}><FormattedMessage id="InboxPage.lastcontact" /></p>
                        <p className={css.lastContactDate}>{lastTransitionedAt.long}</p>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </NamedLink>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

InboxItem.propTypes = {
  unitType: propTypes.bookingUnitType.isRequired,
  type: oneOf(['order', 'sale']).isRequired,
  tx: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export const InboxPageComponent = props => {
  const {
    unitType,
    currentUser,
    fetchInProgress,
    fetchOrdersOrSalesError,
    intl,
    pagination,
    params,
    providerNotificationCount,
    scrollingDisabled,
    transactions,
  } = props;
  const ensuredCurrentUser = ensureCurrentUser(currentUser);

  const title = intl.formatMessage({ id: 'InboxPage.salesTitle' });

  const toTxItem = tx => {
    //is curentUser a customer or a guide in this transaction
    const type = ensuredCurrentUser?.id.uuid === tx.customer.id.uuid ? 'order' : 'sale';
    const stateData = txState(intl, tx, type);

    return stateData ? (
      <InboxItem unitType={unitType} type={type} tx={tx} intl={intl} stateData={stateData} />
    ) : null;
  };

  const error = fetchOrdersOrSalesError ? (
    <p className={css.error}>
      <FormattedMessage id="InboxPage.fetchFailed" />
    </p>
  ) : null;

  const noResults =
    !fetchInProgress && transactions.length === 0 && !fetchOrdersOrSalesError ? (
      <li key="noResults" className={css.noResults}>
        <FormattedMessage id={'InboxPage.noOrdersFound'} />
      </li>
    ) : null;

  const hasOrderOrSaleTransactions = (tx, user) => {
    return (
      user.id &&
      tx &&
      tx.length > 0 &&
      (tx[0].customer.id.uuid === user.id.uuid || tx[0].provider.id.uuid === user.id.uuid)
    );
  };
  const hasTransactions =
    !fetchInProgress && hasOrderOrSaleTransactions(transactions, ensuredCurrentUser);
  const pagingLinks =
    hasTransactions && pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="InboxPage"
        pagePathParams={params}
        pagination={pagination}
      />
    ) : null;

  const providerNotificationBadge =
    providerNotificationCount > 0 ? <NotificationBadge count={providerNotificationCount} /> : null;

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            className={css.topbar}
            desktopClassName={css.desktopTopbar}
            currentPage="InboxPage"
          />
        </LayoutWrapperTopbar>
        <LayoutWrapperSideNav className={css.navigation}></LayoutWrapperSideNav>
        <LayoutWrapperMain className={css.cards}>
          {error}
          <Grid container pc={12} mobile={12} spacing={2} mt={{ pc: 7, mobile: 0 }}>
            {!fetchInProgress ? (
              transactions.map(toTxItem)
            ) : (
              <li className={css.listItemsLoading}>
                <IconSpinner />
              </li>
            )}
            {noResults}
          </Grid>
          {pagingLinks}
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

InboxPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  currentUserHasOrders: null,
  fetchOrdersOrSalesError: null,
  pagination: null,
  providerNotificationCount: 0,
  sendVerificationEmailError: null,
};

InboxPageComponent.propTypes = {
  unitType: propTypes.bookingUnitType,
  currentUser: propTypes.currentUser,
  fetchInProgress: bool.isRequired,
  fetchOrdersOrSalesError: propTypes.error,
  pagination: propTypes.pagination,
  providerNotificationCount: number,
  scrollingDisabled: bool.isRequired,
  transactions: arrayOf(propTypes.transaction).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { fetchInProgress, fetchOrdersOrSalesError, pagination, transactionRefs } = state.InboxPage;
  const { currentUser, currentUserNotificationCount: providerNotificationCount } = state.user;
  return {
    currentUser,
    fetchInProgress,
    fetchOrdersOrSalesError,
    pagination,
    providerNotificationCount,
    scrollingDisabled: isScrollingDisabled(state),
    transactions: getMarketplaceEntities(state, transactionRefs),
  };
};

const InboxPage = compose(connect(mapStateToProps), injectIntl)(InboxPageComponent);

export default InboxPage;
