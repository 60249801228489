import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import config from '../../config';
import LogoImage from './logo_inverse.svg';
import LogoImageDark from './logo.svg';
import css from './Logo.module.css';

const logoDimension = 80;
const logoDimensionMobile = 40;

const Logo = props => {
  const { className, format, lightText, ...rest } = props;
  const mobileClasses = classNames(css.logoMobile, className);

  if (format === 'desktop') {
    return (
      <img
        className={className}
        src={lightText ? LogoImage : LogoImageDark}
        alt={config.siteTitle}
        {...rest}
        height={logoDimension}
        width={logoDimension}
      />
    );
  } else {
    return (
      <img
        src={lightText ? LogoImage : LogoImageDark}
        className={mobileClasses}
        {...rest}
        height={logoDimensionMobile}
        width={logoDimensionMobile}
      />
    );
  }
};

const { oneOf, string } = PropTypes;

Logo.defaultProps = {
  className: null,
  format: 'desktop',
};

Logo.propTypes = {
  className: string,
  format: oneOf(['desktop', 'mobile']),
};

export default Logo;
