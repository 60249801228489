import config from '../../config';

export const parseSubscription = currentUser => {
  const startDate = currentUser?.sub ? currentUser.sub['current_period_start'] * 1000 : 0;
  const endDate = currentUser?.sub ? currentUser.sub['current_period_end'] * 1000 : 0;
  const subStatus = currentUser?.sub ? currentUser.sub['status'] : '';

  const subscriptionActive = currentUser?.sub?.status === "active";

  return { startDate, endDate, subStatus, subscriptionActive }
}

export const bookingRequestURL = (currentUser, locale) => {
  const baseUrl =
    locale === 'en' ? config.custom.inquireURL.kammuiPlusEN : config.custom.inquireURL.kammuiPlusJA;
  return `${baseUrl}#name=${encodeURIComponent(
    currentUser.attributes.profile.displayName
  )}&email=${encodeURIComponent(currentUser.attributes.email)}`;
};
