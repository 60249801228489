import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { maxLength, required, composeValidators } from '../../util/validators';
import { Form, Button, FieldTextInput } from '../../components';

const TITLE_MAX_LENGTH = 100;

const EditListingLanguageFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
      } = formRenderProps;

      const titleMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.title' });

      const titlePlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.titlePlaceholder',
      });

      const descriptionMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.description',
      });

      const descriptionPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionPlaceholder',
      });



      const headlineMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.headline',
      });
      const headlinePlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.headlinePlaceholder',
      });

      const itinerary = intl.formatMessage({
        id: 'EditListingFeaturesForm.itinerary',
      });

      const itineraryPlaceHolder = intl.formatMessage({
        id: 'EditListingFeaturesForm.itineraryPlaceHolder',
      });

      const requirements = intl.formatMessage({
        id: 'EditListingFeaturesForm.requirements',
      });

      const requirementsPlaceHolder = intl.formatMessage({
        id: 'EditListingFeaturesForm.requirementsPlaceHolder',
      });

      const equipment = intl.formatMessage({
        id: 'EditListingFeaturesForm.equipment',
      });

      const equipmentPlaceHolder = intl.formatMessage({
        id: 'EditListingFeaturesForm.equipmentPlaceHolder',
      });

      const facilities = intl.formatMessage({
        id: 'EditListingFeaturesForm.facilities',
      });

      const facilitiesPlaceHolder = intl.formatMessage({
        id: 'EditListingFeaturesForm.facilitiesPlaceHolder',
      });
      //location description
      const locationDescriptionMessage = intl.formatMessage({
        id: 'EditListingLocationForm.locationDescription',
      });
      const locationDescriptionPlaceholderMessage = intl.formatMessage({
        id: 'EditListingLocationForm.locationDescriptionPlaceholder',
      });

      //nearest station
      const nearestStationMessage = intl.formatMessage({
        id: 'EditListingLocationForm.nearestStation',
      });
      const nearestStationPlaceholderMessage = intl.formatMessage({
        id: 'EditListingLocationForm.nearestStationPlaceholder',
      });

      //meetingPoint
      const meetingPointMessage = intl.formatMessage({
        id: 'EditListingLocationForm.meetingPoint',
      });
      const meetingPointPlaceholderMessage = intl.formatMessage({
        id: 'EditListingLocationForm.meetingPointPlaceholder',
      });


      //access info
      const accessInfoMessage = intl.formatMessage({
        id: 'EditListingLocationForm.accessInfo',
      });
      const accessInfoPlaceholderMessage = intl.formatMessage({
        id: 'EditListingLocationForm.accessInfoPlaceholder',
      });

      //distance by car
      const distanceByCarMessage = intl.formatMessage({
        id: 'EditListingLocationForm.distanceByCar',
      });
      const distanceByCarPlaceholderMessage = intl.formatMessage({
        id: 'EditListingLocationForm.distanceByCarPlaceholder',
      });


      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};

      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      return (
        <Form onSubmit={handleSubmit}>
          <FieldTextInput
            id="titleEN"
            name="titleEN"
            type="textarea"
            label={titleMessage}
            placeholder={titlePlaceholderMessage}
            maxLength={TITLE_MAX_LENGTH}
            autoFocus
          />

          <FieldTextInput
            id="headlineEN"
            name="headlineEN"
            type="textarea"
            label={headlineMessage}
            placeholder={headlinePlaceholderMessage}
          />

          <FieldTextInput
            id="descriptionEN"
            name="descriptionEN"
            type="textarea"
            label={descriptionMessage}
            placeholder={descriptionPlaceholderMessage}
          />

          <FieldTextInput
            type="text"
            name="locationDescriptionEN"
            id='locationDescriptionEN'
            label={locationDescriptionMessage}
            placeholder={locationDescriptionPlaceholderMessage}
          />

          <FieldTextInput
            type="textarea"
            id={`itineraryEN`}
            name="itineraryEN"
            label={itinerary}
            placeholder={itineraryPlaceHolder}
          />

          <FieldTextInput
            type="textarea"
            id={`requirementsEN`}
            name="requirementsEN"
            label={requirements}
            placeholder={requirementsPlaceHolder}
          />

          <FieldTextInput
            type="textarea"
            id={`equipmentEN`}
            name="equipmentEN"
            label={equipment}
            placeholder={equipmentPlaceHolder}
          />

          <FieldTextInput
            type="textarea"
            id={`facilitiesEN`}
            name="facilitiesEN"
            label={facilities}
            placeholder={facilitiesPlaceHolder}
          />

          <FieldTextInput
            type="textarea"
            name="accessInfoEN"
            id="accessInfoEN"
            label={accessInfoMessage}
            placeholder={accessInfoPlaceholderMessage}
          />

          <FieldTextInput
            type="text"
            name="nearestStationEN"
            id="nearestStationEN"
            label={nearestStationMessage}
            placeholder={nearestStationPlaceholderMessage}
          />

          <FieldTextInput
            type="text"
            name="distanceByCarEN"
            id="distanceByCarEN"
            label={distanceByCarMessage}
            placeholder={distanceByCarPlaceholderMessage}
          />

          <FieldTextInput
            type="text"
            name="meetingPointEN"
            id="meetingPointEN"
            label={meetingPointMessage}
            placeholder={meetingPointPlaceholderMessage}
          />


          <Button
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingLanguageFormComponent.defaultProps = { fetchErrors: null };

EditListingLanguageFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  categories: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingLanguageFormComponent);
