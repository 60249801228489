import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { Button, Form, FieldCurrencyInput, FieldSelect } from '../../components';

import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { Field } from 'react-final-form';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

import css from './EditListingPricingForm.module.css';

const { Money } = sdkTypes;

export const EditListingPricingFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values,
        form,
        publicData,
        form: {
          mutators: { push },
        },
      } = formRenderProps;

      const unitType = config.bookingUnitType;
      const isNightly = unitType === LINE_ITEM_NIGHT;
      const isDaily = unitType === LINE_ITEM_DAY;

      const translationKey = isNightly
        ? 'EditListingPricingForm.pricePerNight'
        : isDaily
        ? 'EditListingPricingForm.pricePerDay'
        : 'EditListingPricingForm.pricePerUnit';

      const pricePerUnitMessage = intl.formatMessage({
        id: translationKey,
      });

      const pricePlaceholderMessage = intl.formatMessage({
        id: 'EditListingPricingForm.priceInputPlaceholder',
      });

      const priceRequired = validators.required(
        intl.formatMessage({
          id: 'EditListingPricingForm.priceRequired',
        })
      );
      const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
      const minPriceRequired = validators.moneySubUnitAmountAtLeast(
        intl.formatMessage(
          {
            id: 'EditListingPricingForm.priceTooLow',
          },
          {
            minPrice: formatMoney(intl, minPrice),
          }
        ),
        config.listingMinimumPriceSubUnits
      );
      const priceValidators = config.listingMinimumPriceSubUnits
        ? validators.composeValidators(priceRequired, minPriceRequired)
        : priceRequired;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const { updateListingError, showListingsError } = fetchErrors || {};

      const isAnyVariablePriceIncomplete = values.variablePricingOptions?.find(o => {
        return (o?.numberOfPersons && !o?.price) || (!o?.numberOfPersons && o?.price);
      });


      const savedDynamicPrices = publicData && publicData.variablePricingOptions;
      const hasDynamicPrices =
        savedDynamicPrices && Array.isArray(savedDynamicPrices) && savedDynamicPrices.length > 0;
      

      return (
        <Form onSubmit={handleSubmit} className={classes}>
          {updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.updateFailed" />
            </p>
          ) : null}
          {showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.showListingFailed" />
            </p>
          ) : null}

          <FieldCurrencyInput
            id="price"
            name="price"
            className={css.priceInput}
            autoFocus
            label={pricePerUnitMessage}
            placeholder={pricePlaceholderMessage}
            currencyConfig={config.currencyConfig}
            validate={priceValidators}
          />

          {hasDynamicPrices && (
             <div className={css.dynamicPricingWrapper}>
               {savedDynamicPrices.map(d => {
                 const numberOfPersons = d.numberOfPersons;
                 const price = '¥' + d.price;
                 return (
                   <div className={css.dynamicPriceItem}>
                     <p>
                       <FormattedMessage
                         id="EditListingPricingForm.pricePerPerson"
                         values={{ numberOfPersons }}
                       />
                     </p>
                     <p>{price}</p>
                   </div>
                 );
               })}
             </div>
           )}

          <div className={css.dynamicFieldsLabel}>
            <FormattedMessage id="EditListingPricingForm.variablePricingLabel" />
            <AddIcon
              className={css.addButton}
              onClick={() => push('variablePricingOptions', undefined)}
            />
          </div>

          <div className={css.dynamicFieldsWrapper}>
            <FieldArray name="variablePricingOptions">
              {({ fields }) =>
                fields.map((name, index) => (
                  <div className={css.dynamicFieldWrapper} key={name}>
                    <div className={css.closeIconWrapper}>
                      <CloseIcon
                        onClick={() => {
                          if (fields.length > 1) {
                            fields.remove(index);
                          }
                        }}
                        style={
                          fields.length > 1
                            ? { color: '#827D8D', cursor: 'pointer' }
                            : { visibility: 'hidden' }
                        }
                      />
                    </div>
                    <label className={css.dynamicItemLabel}>
                      <FormattedMessage id="EditListingPricingForm.numberOfPersonsLabel" />
                    </label>
                    <Field
                      name={`${name}.numberOfPersons`}
                      className={css.dynamicField}
                      component="input"
                      type="number"
                      min={1}
                      placeholder={intl.formatMessage({
                        id: 'EditListingPricingForm.numberOfPersonsPlaceholder',
                      })}
                    />
                    <FieldCurrencyInput
                      id={`${name}.price`}
                      name={`${name}.price`}
                      className={css.priceInput}
                      label={pricePerUnitMessage}
                      placeholder={pricePlaceholderMessage}
                      currencyConfig={config.currencyConfig}
                      // validate={priceValidators}
                    />
                  </div>
                ))
              }
            </FieldArray>
          </div>

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled || isAnyVariablePriceIncomplete}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingPricingFormComponent.defaultProps = { fetchErrors: null };

EditListingPricingFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPricingFormComponent);
